
// Menu services rest api calling

import { multipart_headers } from '../Helper/helper';
import { routes } from '../config/api_routes'

export const menuService = {
    
    // getMenu
    getMenuList: (formData) => {    
        return window.axios.post(routes.getMenuListApi,formData)
    },

    //getMenuItem
    getMenuItemList: (formData) => {
    	return window.axios.post(routes.getMenuItemByIdApi,formData)
    },

    // getMenuDineIn
    getMenuListDineIn: (formData) => {    
        return window.axios.post(routes.getMenuListDineInApi,formData)
    },

    //getMenuItemDineIn
    getMenuDineInItemList: (formData) => {
    	return window.axios.post(routes.getMenuItemDineInByIdApi,formData)
    },

    //addDineInMenu
    addDineInMenu: (formData) => {
    	return window.axios.post(routes.addDineInMenuApi,formData)
    },

    //getDineMenuById
    getMenuById: (formData) => window.axios.post(routes.getDineinMenuByIdApi, formData),

    //updateDineInMenu
    updateDineInMenu: (formData) => window.axios.post(routes.updateDineInMenuApi,formData),

    //DeleteDineMenu
    DeleteDineMenu: (formData) => window.axios.post(routes.deleteDineMenuApi,formData),

    //deleteDineRestaurantAllMenuApi
    deleteDineRestaurantAllMenu: (formData) => window.axios.post(routes.deleteDineRestaurantAllMenuApi,formData),

    deleteRestaurantAllMenu: (formData) => window.axios.post(routes.deleteRestaurantAllMenuApi,formData),

    //AddMenuItem
    AddMenuItem: (formData) => window.axios.post(routes.addDineInMenuItem,formData),

    //UpdateItemAvaibility
    UpdateItemAvaibility: (formData) => window.axios.post(routes.updateDineInItemAvailability,formData),

    //GetDineInMenuItem
    getDineInMenuItem: (formData) => window.axios.post(routes.getDineInMenuItem,formData),

    //updateDineInMenuItem
    updateDineInMenuItem: (formData) => window.axios.post(routes.updateDineInMenuItem,formData),

    //addMenuItemDineImage
    addMenuItemDineImage: (formData) => window.axios.post(routes.addDineInItemImageApi,formData),

    //getDineItemImage
    getMenuItemDineImage: (formData) => window.axios.post(routes.getDineInItemImage,formData),

    //getDineInItemCustomization
    getItemCustmization: (formData) => window.axios.post(routes.getDineInItemCustomzation,formData),

    //addDineInItemCustomization
    addCustmization: (formData) => window.axios.post(routes.addDineInItemCustomization,formData),

    //getDineTemplate
    getTemplate: (formData) => window.axios.post(routes.getDineInTemplate,formData),

    //saveTemplate
    saveTemplate: (formData) => window.axios.post(routes.addDineInTemplate,formData),

    //getCustmizationType
    getCustmizationType: (formData) => window.axios.post(routes.getDineItemCustomization,formData),
   
    //customizationEdit
    customizationEdit: (formData) => window.axios.post(routes.updateDineInCustomization,formData),

    //addMenuSelection
    addMenuSelection: (formData) => window.axios.post(routes.addDineInMenuSelection,formData),

    //deleteitemCustmization
    deleteitemCustmization: (formData) => window.axios.post(routes.deleteDineInItemCustomization,formData),
    
    //addTemplate
    addTemplate: (formData) => window.axios.post(routes.addDineInTemplates,formData),

    //deleteCustmizationType
    deleteCustmizationType: (formData) => window.axios.post(routes.deleteDineInCustomization,formData),

    //QrCode
    getDineInQrCode: (formData) => window.axios.post(routes.getDineInQrCode,formData),

    //Search
    searchDineInMenuItem: (formData) => window.axios.post(routes.searchMenuItemDine,formData),

    //GetDineInBanner
    getBanner: (formData) => {    
        return window.axios.post(routes.getDineInBanner,formData)
    },  

    // banner add
    addBanner: (formData) => {
        return window.axios.post(routes.addDineInBanner,formData,{headers: multipart_headers() });
    },

     // get restaurant

     getRestaurant: (query) => {
        return window.axios.post(routes.getRestaurantDineIn,query)
    },

    // get bannerbyID
     getBannerById: (formData) => {
        return window.axios.post(routes.getDineInBannerbyId,formData)
   },

   // update bannerByID

   updateBannerById: (formData) => window.axios.post(routes.updateDineInBanner,formData,{headers: multipart_headers() }),

   // delete banner

   deletebanner: (formData) => {
       return window.axios.post(routes.deleteDineInBanner,formData)
   },

   //GetBanner
   getMenuBanner: (params) => window.axios.get(routes.getMenuDineBanner,{params}),

   //ImportMenu
   importMenuItem: (formData) => window.axios.post(routes.importDineInMenu,formData), 

   //GetCustomization
   getSubCustomization: (formData) => window.axios.post(routes.getDineInSubCustomization,formData),

   //addSubMenuSelection
   addSubMenuSelection: (formData) => window.axios.post(routes.addDineInItemSubCustomization,formData),

   //minSelection
   minSelection: (formData) => window.axios.post(routes.minDineInSelectionApi, formData),

   //maxSelection
   maxSelection: (formData) => window.axios.post(routes.maxDineInSelectionApi, formData),

   //DineInMenu
   deleteMenuWithItem: (formData) => window.axios.post(routes.deleteMenuDineInItem, formData),

   //updateMenuOrder
   updateMenuOrder: (data) => window.axios.post(routes.updateMenuDineInOrderApi, data),

   //updateItemOrder
   updateItemOrder: (data) => window.axios.post(routes.updateItemDineInOrderApi, data),

   //AboutUs
   getAboutUs: () => window.axios.get(routes.aboutUsPage),

   //FeedbackApi
   addFeedbackApi: (formData) => window.axios.post(routes.addFeedbackApi,formData),

   //FeedbackList
   getFeedbackListApi: (formData) => window.axios.post(routes.getFeedbackApi,formData),

   //DeleteDineInMenuItemImage
   deleteDineInMenuUrl: (formData) => window.axios.post(routes.deleteDineInMenuUrl,formData),
   
   
   //Common Category
   getCommonCategory: () => window.axios.get(routes.getCommonCategory),

   addUpdateCommonCategory: (formData) => window.axios.post(routes.addUpdateCommonCategory,formData),
   arrangeCategory: (formData) => window.axios.post(routes.arrangeCategory,formData),

}