
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import PageHeader from "../../components/page-header";
import { bannerAdsService, bannerService } from "../../services";
import { error_message, debounce, success_message } from "../../Helper/helper";
import draggable from "vuedraggable";

export default {
  components: { pagination, bannerService, PageHeader, draggable },
  data() {
    const { status, orderBy, sortBy } = this.$route.query;
    return {
      sortBy: sortBy || "id",
      sortDesc: orderBy && orderBy != "" ? true : orderBy,
      banners: [],
      paginations: {},
      limit: 1,
      search: "",
      sorted_banners:[],
      status: status == undefined ? 1 : status,
      config: config,
      loading: true,
      searchData: debounce(() => {
        if (!this.init) {
          const { status, orderBy, sortBy } = this.$data;
          this.$router.replace({
            name: "order-status-banner-list",
            query: { status, orderBy, sortBy },
          });
        }
        this.getBannerAds();
        this.init = false;
      }, 500),

      fields: [
        { key: "icon_image", label: "#", sortable: false },
        {
          key: "title",
          label: this.trans.get("__JSON__.Title"),
          sortable: true,
        },
        { key: "restaurants", label: "Vendor Name", sortable: false },
        // { key: "sort", label: "Sort", sortable: true},
        // {
        //   key: "status",
        //   label: this.trans.get("__JSON__.Status"),
        //   sortable: false,
        // },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getBannerAds();
  },
  methods: {
    async sortBanner(d) {
      // this.banners
      try {
        bannerAdsService.arrangeBannerAds({
          banners:this.banners
        }).then((response) => {
          if (response.data.code === 200) {
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      } catch (error) {
      }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getBannerAds();
    },
    deleteById(id) {
      if (confirm("Are you sure you want to delete?")) {
        bannerAdsService.deleteBannerAds({ banner_id: id }).then((response) => {
          success_message(response.data.message);
          this.getBannerAds();
        });
      }
    },

    getBannerAds(page) {
      var filters = {
        search: this.search,
        // status: this.status,
        // sortBy: this.sortBy,
        // orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      };
      this.loading = true;
      try {
        bannerAdsService.getBannerAds(filters).then((response) => {
          if (response.data.code === 200) {
            this.loading = false;
            this.banners = response.data.result;
            // this.banners = response.data.result.data;
          } else {
            this.loading = false;
            // error_message(response.data.message);
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
              >
                <i class="bx bx-purchase-tag"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">
              {{ trans.get("__JSON__.Order Status Banners") }}
            </h4>
          </div>
          <div class="page-title-right">
            <router-link
              v-if="$auth.hasPermission(config.permissions.order_banner_write)"
              :to="{ name: 'order-status-banner-add' }"
              class="btn btn-success btn-rounded mr-2 add-btn"
            >
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.get("__JSON__.Add Banner") }}
            </router-link>
            <router-link
              v-if="$auth.hasPermission(config.permissions.order_banner_write)"
              :to="{ name: 'order-status-banner-add' }"
              class="btn btn-success btn-rounded mr-2 m-add-btn"
            >
              <i class="mdi mdi-plus"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex justify-content-end">
              <!-- <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-2 offset-sm-8">
                <div class="date-range-list">
                  <label>{{ trans.get("__JSON__.Search") }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="
                            trans.get('__JSON__.Search Placeholder')
                          "
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list banner-table table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Vendor Name</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="text-align: center" v-if="loading">
                      <td colspan="4">
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>{{
                            trans.get("__JSON__.Loading...")
                          }}</strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                    <draggable  group="banners" :list="banners" @end="sortBanner" v-model="sorted_banners" tag="tbody">
                      
                    <tr v-for="(data, index) in banners" v-if="banners && banners.length > 0" :key="index">
                      <th scope="row">
                        <div v-if="data.icon_image">
                          <img
                            alt
                            title="product-img"
                            class="avatar-md rounded-circle-banner"
                            v-lazy="
                              data.media_path + '/original/' + data.icon_image
                            "
                          />
                        </div>
                        <div v-else>
                          <img
                            class="avatar-md rounded-circle-banner"
                            :src="config.basepath + 'no-img.png'"
                            alt
                          />
                        </div>
                      </th>
                      <td>
                        <h5 class="font-size-14">
                          <div v-if="data.title > 15">
                            {{ data.name.substr(0, 15) + "..." }}
                          </div>
                          <div v-else>{{ data.title }}</div>
                        </h5>
                      </td>
                      <td>
                        <template
                          v-for="(restaurant, index) in data.restaurants"
                        >
                          <span :key="restaurant.id">{{
                            restaurant.name
                          }}</span>
                          <span
                            :key="restaurant.id"
                            v-if="index + 1 != data.restaurants.length"
                            >,
                          </span>
                        </template>
                      </td>
                      <td>
                        <div class="vendor-btn">
                          <router-link
                            v-if="
                              $auth.hasPermission(
                                config.permissions.order_banner_write
                              )
                            "
                            :to="{
                              name: 'order-status-banner-edit',
                              params: { id: base64(data.id) },
                            }"
                            class="btn btn-success btn-sm btn-rounded"
                            >{{ trans.get("__JSON__.Edit") }}</router-link
                          >
                          <button
                            v-if="
                              $auth.hasPermission(
                                config.permissions.order_banner_write
                              )
                            "
                            type="submit"
                            class="btn btn-danger btn-sm btn-rounded ml-1"
                            @click="deleteById(base64(data.id))"
                          >
                            {{ trans.get("__JSON__.Delete") }}
                          </button>
                        </div>
                      </td>
                    </tr>

                  </draggable>
                  <tbody>
                  <tr v-if="(!banners || banners.length <= 0) && !loading" style="text-align: center">
                    <td colspan="4">No Data Found !</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getBannerAds"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.banner-table .table thead th:nth-child(1),
.banner-table .table thead th:nth-child(3),
.banner-table .table thead th:nth-child(5) {
  pointer-events: none;
}
</style>