<script>
import { get_currency, get_decimal } from '../../../Helper/helper';
export default {
  // props:['order','loading'],
  props:{
    'order':{
      type:Object
    },
    'loading':{
      type:Boolean
    },
    thirdPartyOrder:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return {
      currency: get_currency(),
      decimal: get_decimal(),
    }
  },
  methods:{
    getSelections(items,price){
      return items.length > 0 ? items.reduce((accu, item) => {
        return accu.concat(item.selection_name +' x '+ parseFloat(item.selection_price || price || 0).toFixed(this.decimal));
      },[]).join('<br>') : '';
    },
    getSelectionPrices(items){
      return parseFloat(items.length > 0 ? items.reduce(function(accu, item){
        return accu + parseFloat(item.selection_price);
      },0): 0);
    },
    mrpPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_mrp || 0)))
    },
    totalPrice(item){
      return parseFloat((parseInt(item.quantity) * this.getSelectionPrices(item.menu_item_selections)) + (parseInt(item.quantity) * parseFloat(item.item_price || 0)))
    },
    getOrderItem(){
      return this.order.delivery_pickup_types != 'Dine In' ? this.order.order_item : this.order.order_item_dine;
    },
    getItemTotal(){
      let itemTotal = 0;
      this.getOrderItem().forEach(item => {
        //Customization item
        if (parseFloat(item.item_mrp || 0) == 0 && parseFloat(item.item_price || 0) == 0) {
          if(parseFloat((parseFloat(item.item_mrp || 0) * item.quantity).toFixed(2)) > parseFloat((item.amount || 0).toFixed(2)) || (item.item_mrp == 0 && item.item_price == 0)) {
			
          // if ((parseFloat(item.item_mrp || 0).toFixed(2) * item.quantity).toFixed(2) > parseFloat(item.amount || 0).toFixed(2)) {
            itemTotal += ((this.getSelectionPrices(item.menu_item_selections)) * (parseFloat(item.quantity || 0)));
          } else {
            itemTotal += parseFloat(item.amount);
          }
        }
        //Normal item
        else {
          if(parseFloat((parseFloat(item.item_mrp || 0) * item.quantity).toFixed(2)) > parseFloat((item.amount || 0).toFixed(2))) {
            // if ((parseFloat(item.item_mrp || 0).toFixed(2) * item.quantity).toFixed(2) > parseFloat(item.amount || 0).toFixed(2)) {
              itemTotal += (parseFloat(item.item_mrp || 0).toFixed(2) * item.quantity);
            } else {
              itemTotal += parseFloat(item.amount || 0);
            }
        }
        // itemTotal += (parseFloat(item.item_mrp || 0) * item.quantity);
      });
	  
      return itemTotal
    },
    getItemDiscountTotal() {
      let itemDiscountTotal = 0;
      this.getOrderItem().forEach(item => {
        itemDiscountTotal += parseFloat(item.amount || 0);
      });
      return ((this.getItemTotal()??0) - (itemDiscountTotal??0))
    }
  }
}
</script>

<template>

   <!-- View Detail Main Start -->
  <div class="row mt-3">
    <div class="col-12">
      <div class="text-center text-danger my-2" v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
      </div>
    </div>
    <div class="table-responsive item-order-main" v-if="!loading">
      <table class="table table-centered table-bordered item-table-main order-item-table">
          <thead>
            <tr>
              <th scope="col" class="item-name-main">{{trans.get('__JSON__.Item Name')}}</th>
              <th scope="col">{{trans.get('__JSON__.Quantity')}}</th>
              <th scope="col">{{trans.get('__JSON__.Unit Price')}}</th>
              <!-- <th scope="col">{{trans.get('__JSON__.Total Price')}}</th> -->
              <!-- <th scope="col">{{trans.get('__JSON__.Original Price')}}</th> -->
              <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;{{trans.get('__JSON__.Total')}} &nbsp;&nbsp;&nbsp;&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getOrderItem()" :key="item.id">
              <td>
                <span style="font-weight: 500;" class="font-size-13">
                  {{ 
                    thirdPartyOrder
                    ? item.item_name
                    : item.menu_item && item.menu_item.item_name
                  }}
                </span>
                <span class="badge badge-pill badge-soft-primary font-size-12" v-if="!thirdPartyOrder && item.coupon && (item.coupon.is_flat_discount == 6 || (item.amount == 0 && (item.coupon.is_flat_discount == 5 || item.coupon.is_flat_discount == 7)))">
                    {{ item.coupon.coupon_code ? item.coupon.coupon_code : ''  }}
                </span>
                  <p class="font-size-12" v-if="!thirdPartyOrder" v-html="getSelections(item.menu_item_selections, item.item_mrp)"></p>

                  <!-- {{ 
                    !thirdPartyOrder
                    ? getSelections(item.menu_item_selections,item.item_mrp)
                    : ''
                  }} -->
                <!-- </p> -->
              </td>
              <td align="center">
                <p>{{item.quantity}}</p>
              </td>
              <td align="center">
                <!-- <p><span v-html="currency"></span> {{((parseFloat(item.amount/item.quantity || 0))).toFixed(decimal)}} </p> -->
                <!-- <p><span v-html="currency"></span> {{((parseFloat(item.item_mrp || 0))).toFixed(decimal)}} </p> -->
                
                <!-- <p><span v-html="currency"></span> {{((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(decimal)}} </p> -->

                <p>
                  <!-- <span v-html="currency"></span>  -->
                  <template v-if="thirdPartyOrder">
                    <span v-html="currency"></span>  {{ item.item_price }}
                  </template>
                  <template v-else>
                    <!-- Customization item -->
                    <template v-if="parseFloat(item.item_mrp || 0) == 0 && parseFloat(item.item_price || 0) == 0">
                      <template v-if="getSelectionPrices(item.menu_item_selections).toFixed(2) != (parseFloat(item.amount || 0).toFixed(2) / parseFloat(item.quantity || 0)).toFixed(2)">
                        <p><span v-html="currency"></span>  <del>{{ ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(2) }}</del></p>
                        <p><span v-html="currency"></span>  {{ (parseFloat(item.amount || 0).toFixed(2) / parseFloat(item.quantity || 0)).toFixed(2) }}</p>
                      </template>
                      <template v-else>
                        <span v-html="currency"></span>  {{ ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_price || 0))).toFixed(2) }}
                      </template>
                    </template>
                    <!-- Normal item -->
                    <template v-else>
                      <template v-if="((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(2) != ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_price || 0))).toFixed(2)">
                        <p><span v-html="currency"></span>  <del>{{ ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(2) }}</del></p>
                        <p><span v-html="currency"></span>  {{ ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_price || 0))).toFixed(2) }}</p>
                      </template>
                      <template v-else>
                        <span v-html="currency"></span>  {{ ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_price || 0))).toFixed(2) }}
                      </template>
                    </template>
                  </template>
                  <!-- {{ 
                    thirdPartyOrder
                    ? item.item_price 
                    :  ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_price || 0))).toFixed(2)
                  }} -->
                  <!-- old logic -->
                  <!-- {{  
                    thirdPartyOrder
                    ? item.item_price 
                    :  ((getSelectionPrices(item.menu_item_selections)) + (parseFloat(item.item_mrp || 0))).toFixed(2)
                  }} -->
                </p>
              </td>
              <!-- <td align="center">
        
                <p>
                  <span v-html="currency"></span> 
                  {{ 
                    thirdPartyOrder
                    ? item.amount
                    : mrpPrice(item).toFixed(2)
                  }}
                </p>
              </td> -->
              
              <td align="center">
                <!-- <p><span v-html="currency"></span> {{totalPrice(item).toFixed(decimal)}} </p> -->
                <template v-if="thirdPartyOrder">
                  <p><span v-html="currency"></span> {{parseFloat(item.amount || 0).toFixed(2)}} </p>
                </template>
                <template v-else>
                  <!-- Customization item -->
                  <template v-if="parseFloat(item.item_mrp || 0) == 0 && parseFloat(item.item_price || 0) == 0">
                    <!-- <template v-if="(parseFloat(item.item_mrp || 0).toFixed(2) * item.quantity).toFixed(2) > parseFloat(item.amount || 0).toFixed(2)"> -->
                      <template v-if="parseFloat((getSelectionPrices(item.menu_item_selections) * item.quantity).toFixed(2)) > parseFloat((item.amount || 0).toFixed(2))">
                      <p><span v-html="currency"></span> <del>{{ ((getSelectionPrices(item.menu_item_selections)) * (parseFloat(item.quantity || 0))).toFixed(2) }}</del></p>
                      <p><span v-html="currency"></span> {{parseFloat(item.amount || 0).toFixed(2)}}</p>
                    </template>
                    <template v-else>
                      <span v-html="currency"></span> {{parseFloat(item.amount || 0).toFixed(2)}}
                    </template>
                  </template>
                  <!-- Normal item -->
				<template v-else>
                    <template v-if="parseFloat((parseFloat(item.item_mrp || 0) * item.quantity).toFixed(2)) > parseFloat((item.amount || 0).toFixed(2))">
                    <!-- <template v-if="(parseFloat(item.item_mrp || 0).toFixed(2) * item.quantity).toFixed(2) > parseFloat(item.amount || 0).toFixed(2)"> -->
                      <p><span v-html="currency"></span> <del>{{parseFloat((parseFloat(item.item_mrp || 0) * item.quantity).toFixed(2))}}</del></p>
                      <p><span v-html="currency"></span> {{parseFloat(item.amount || 0).toFixed(2)}}</p>
                    </template>
                    <template v-else>
                      <span v-html="currency"></span> {{parseFloat(item.amount || 0).toFixed(2)}}
                    </template>
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Item Total')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{getItemTotal().toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Item Discount')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{getItemDiscountTotal().toFixed(2)}}</td>
            </tr>
            
            <template v-for="(coupon, index) in order.reddemed_coupons">
              <tr v-if="coupon.coupon.is_flat_discount != 5 && coupon.coupon.is_flat_discount != 6 && coupon.coupon.is_flat_discount != 7">
                <td colspan="3" align="right">
                  <span class="text-right text-danger">{{trans.get('__JSON__.Promo Discount')}}</span>
                  <span class="text-right text-danger"> {{coupon.coupon?' ('+coupon.coupon.coupon_code+')':''}}</span>
                </td>
                <td align="center" class="text-danger">
                  <span class="text-danger" v-html="currency"></span>
                  <span class="text-danger">{{ coupon.promocode_discount_price ? parseFloat(coupon.promocode_discount_price || 0).toFixed(2) : parseFloat((coupon.original_price - coupon.discount_price) || 0).toFixed(2)}}</span>
                </td>
              </tr>
            </template>

            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.sub_total || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Packaging Charges')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.total_packaging_charge || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.CGST')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.CGST || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.SGST')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.SGST || 0).toFixed(2)}}</td>
            </tr>
            <tr v-if="order.service_fee > 0">
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{$auth.setting.tax_name_primary_lang}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.service_fee || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Delivery Fees')}}</h6></td>
              <td align="center"><span v-html="currency"></span> {{parseFloat(order.delivery_fee || 0).toFixed(2)}}</td>
            </tr>
            <tr>
              <td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
              <td align="center"> <span v-html="currency"></span> {{parseFloat(order.total_amount || 0).toFixed(2)}}</td>
            </tr>
          </tbody>
        </table>
    </div>
    <!-- View Detail Main End -->

  </div>
</template>
