<script>
import { settingService } from "../../../services";
import { required, numeric } from "vuelidate/lib/validators";
import { error_message, success_message } from "../../../Helper/helper";
export default {
  data() {
    return {
      isValidate: false,
      customer_category_type: [],
    };
  },
  validations: {
    customer_category_type: {
      $each: {
        details: {
          days: { required, numeric },
          order_count: { numeric },
          order_value: { numeric },
        },
      },
    },
  },
  mounted() {
    this.getCustomerCategories();
  },
  methods: {
    saveCustomerCategories() {
      this.isValidate = false;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.customer_category_type.forEach((element) => {
        if (element.category != "2") {
          if (
            element.details.order_count == "" ||
            element.details.order_count == null
          ) {
            this.isValidate = true;
          }
        }
      });

      if (this.isValidate) {
        return;
      }
      const fd = new FormData();
      fd.append(
        "customer_category_type",
        JSON.stringify(this.customer_category_type)
      );

      settingService
        .saveCustomerCategories(fd)
        .then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);

            this.customer_category_type = response.data.result.data;
          } else {
            error_message(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCustomerCategories() {
      settingService
        .getCustomerCategories()
        .then((response) => {
          if (response.data.code === 200) {
            this.customer_category_type = response.data.result.data;
          } else {
            error_message(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="saveCustomerCategories">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body setting-main">
            <div
              class="section-main"
              v-for="(v, index) in customer_category_type"
              :key="index"
            >
              <div class="row" v-if="v.category && v.category >= 0">
                <div class="col-md-4">
                  <div class="setting-heading">
                    <h4>
                      {{ v.category_name }}
                    </h4>
                    <input
                      v-model="v.category_name"
                      type="hidden"
                      class="form-control"
                    />
                    <input v-model="v.id" type="hidden" class="form-control" />
                    <input
                      v-model="v.category"
                      type="hidden"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="row mb-2">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-4">
                          <h6>{{ trans.get("__JSON__.Days") }}</h6>
                          <input
                            v-model="v.details.days"
                            type="number"
                            class="form-control"
                          />
                          <span
                            v-if="
                              !$v.customer_category_type.$each[index].details
                                .days.$pending &&
                              !$v.customer_category_type.$each[index].details
                                .days.$model
                            "
                            class="text-danger"
                            >Days is required and must be a number.</span
                          >
                        </div>
                        <div
                          class="col-md-4"
                          v-if="v.details && 'order_count' in v.details"
                        >
                          <h6 v-if="v.category != '3' && v.category != '1'">
                            {{ trans.get("__JSON__.Orders") }}
                          </h6>
                          <h6 v-else>{{ trans.get("__JSON__.Orders(%)") }}</h6>
                          <input
                            v-model="v.details.order_count"
                            type="number"
                            class="form-control"
                          />
                          <span
                            v-if="
                              !$v.customer_category_type.$each[index].details
                                .order_count.$pending &&
                              !$v.customer_category_type.$each[index].details
                                .order_count.$model
                            "
                            class="text-danger"
                            >Order count is required and must be a
                            number..</span
                          >
                        </div>
                        <div
                          class="col-md-4"
                          v-if="v.details && 'order_value' in v.details"
                        >
                          <h6>
                            {{ trans.get("__JSON__.Order Value") }} (Optional)
                          </h6>
                          <b-form-input
                            type="number"
                            class="form-control"
                            v-model="v.details.order_value"
                          >
                          </b-form-input>
                        </div>
                        <div
                          class="col-md-12 mt-3 d-flex"
                          v-if="v.category && v.category == '0'"
                        >
                          <p>
                            Customers who have placed
                            <b>{{ v.details.order_count }}</b> order within the
                            past <b>{{ v.details.days }}</b> days
                            <span v-if="v.details && v.details.order_value > 0"
                              >, with an average order value of
                              <b>{{ v.details.order_value }}</b></span
                            >, fall into this category.
                          </p>
                        </div>

                        <div class="col-md-12 mt-3" v-if="v.category">
                          <p v-if="v.category == '1'">
                            Customers who have made more that <b>{{ v.details.order_count }}</b>% order using any promotional offers within the past <b>{{ v.details.days }}</b> days are classified under this category. 
                          </p>
                          <p v-if="v.category == '2'">
                            Customers who have not placed any orders in the past
                            <b>{{ v.details.days }}</b> days are categorized
                            here.
                          </p>
                          <!-- <p v-if="v.category == '3'">
                            Customers whose order cancellation ratio is
                            <b>{{ v.details.order_count }}%</b> in the past
                            <b>{{ v.details.days }}</b> days are categorized
                            here.
                          </p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row btn-update">
              <div class="col-sm-6">
                <button type="submit" class="btn btn-primary mr-1 mt-3">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>
