// add-update-banner-ads

import { routes } from '../config/api_routes'

export const bannerAdsService = {

    addUpdateBannerAds: (formData) => {    
        return window.axios.post(routes.addUpdateBannerAds,formData)
    },   
    getBannerAds: (fromData) => {    
        return window.axios.post(routes.getBannerAds,fromData)
    },   
    arrangeBannerAds: (fromData) => {    
        return window.axios.post(routes.arrangeBannerAds,fromData)
    },   
    deleteBannerAds: (formData) => {    
        return window.axios.post(routes.deleteBannerAds,formData)
    },   
    getBannerById: (formData) => {    
        return window.axios.post(routes.getBannerById,formData)
    },   
    copyBannerAds: (formData) => {    
        return window.axios.post(routes.copyBannerAds,formData)
    },   
}