<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import LightViewTree from "../../components/custom_modules/light-vue-tree";
import "../../components/custom_modules/light-vue-tree/lib/style/index.css";
import Layout from "../../layouts/main";
import { promoCodeService, bannerService, restaurantService } from "../../services";
import { error_message, success_message } from "../../Helper/helper";
import config from "../../config";

import DropzonePopup from "../../components/widgets/dropzone-popup";

import { required } from "vuelidate/lib/validators";

export default {
  name: "Range",
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    PageHeader,
    DatePicker,
    promoCodeService,
    bannerService,
    DropzonePopup,
    LightViewTree,
    Layout,
  },
  data() {
    return {
      treeData: [],
      config: config,
      resturant: [],
      search: "",
      search1: "",
      isLoading: false,
      search_items_array: [],
      isLoading1: false,
      selected_items_data: [],
      search_items: "",
      selected_catelog: [],
      selectedRestaurant: [],
      restaurants: [],
      customer_category_options: [],
      typeform: {
        selectedRestaurant: "",
      },
    };
  },
  validations: {
    typeform: {
      selectedRestaurant: {
        required,
      },
    },
  },
  async mounted() {
    this.getCategoryData();
  },
  methods: {
    clearAll() {
      this.selectedRestaurant = [];
    },
    searchItem() {
      // if(this.search_items.length > 3){
      this.getCategoryData();
      // }
    },
    dropNode({ parentNode, targetNode, callback }) {
      callback(targetNode);
    },
    checkedItem({ node, vNode }) {},
    clickNode(node) {},
    clickCheckbox({ node, selectedData }) {
      this.selected_catelog = selectedData;
    },
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    getCategoryData() {
      var obj = {
        selectedRestaurant: this.restaurants,
        search: this.search_items,
      };
      promoCodeService
        .getCategoryTree(obj)
        .then((response) => {
          // console.log(response.data,'response');
          this.selected_catelog.length = 0;
          if (response.data.code == 200 && response?.data?.result?.categories) {
            let array = [];
            response.data.result.items.forEach((el) => {
              array.push({
                id: el.item_ids,
                name: el.name,
                children: [],
                key: "item",
                parent: el.menu_ids,
                checked: false,
              });
            });
            let menus = [];
            var ispush = false;
            response.data.result.menus.forEach((e, index) => {
              let temp = e.menu_ids.split(",");
              menus[index] = {
                id: e.menu_ids,
                name: e.name,
                children: [],
                key: "menu",
                parent: e.category_ids,
                checked: false,
              };
              array.forEach((i) => {
                ispush = true;
                temp.forEach((t) => {
                  let tdm = i.parent.split(",");
                  if (tdm.includes(t) && ispush) {
                    menus[index].children.push(i);
                    ispush = false;
                  }
                });
              });
            });
            let categories = [];
            var is_psuh = false;
            var uncategorizedArray = [];
            var ctgry = {
              id: 0,
              name: "Uncategorized",
              children: [],
              key: "category",
              parent: "Self",
              checked: false,
            };
            response.data.result.categories.forEach((c, index) => {
              let temp = c.category_ids.split(",");
              categories[index] = {
                id: c.category_ids,
                name: c.name,
                children: [],
                key: "category",
                parent: "Self",
                checked: false,
              };
              menus.forEach((i, idx) => {
                is_psuh = true;
                let isValid = false;
                let tdm = i.parent.split(",");
                temp.forEach((t) => {
                  if (tdm.includes(t) && is_psuh) {
                    categories[index].children.push(i);
                    is_psuh = false;
                    isValid = true;
                    return;
                  }
                });
                if (tdm.includes("0")) {
                  if (!uncategorizedArray[idx]) {
                    uncategorizedArray[idx] = i;
                    ctgry.children.push(i);
                  }
                }
              });
            });
            categories.push(ctgry);
            this.treeData = categories;
          }
          if (response?.data?.result.length == 0) {
            this.treeData.length = 0;
          }
        })
        .catch((err) => {
          this.treeData = [];
          console.log(err);
        });
    },
    addTopPicksItems() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        var array = [];

        this.selected_catelog.forEach((e) => {
            if (e.key == 'item') {
                let c = e.id.split(",");
                array = [...array, ...c]; 
            }
        });
        fd.append("selected_catelog", JSON.stringify(array ?? []));
        this.typeform.selectedRestaurant
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("selectedRestaurant[" + index + "]", item);
        });
        restaurantService.addTopPicksItems(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            location.reload();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    deleteTopPicksItems()
    {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }
      console.log(this.typeform.selectedRestaurant);
      

      const fd = new FormData();
      this.typeform.selectedRestaurant
        .map((item) => item.restaurant_id)
        .map((item, index) => {
          fd.append("selectedRestaurant[" + index + "]", item);
      });

      restaurantService.deleteTopPicksItems(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
    });
    }
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
          >
            <div class="page-title-left avatar-main-icon">
              <div class="avatar-xs mr-3">
                <span
                  class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                >
                  <i class="bx bx-purchase-tag"></i>
                </span>
              </div>
              <div class="report-tag">
                <h4 class="mb-0 font-size-18">
                  {{ trans.get("__JSON__.Top Picks") }}
                </h4>
                <p>{{ trans.trans("__JSON__.Top Picks") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form action="#">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">
                  {{ trans.get("__JSON__.Restaurant") }}
                </h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="search-box mr-2 d-inline-block w-100">
                      <div class="position-relative">
                        <multiselect
                          v-model="$v.typeform.selectedRestaurant.$model"
                          id="ajax"
                          v-bind:disabled="typeform.checkbox"
                          label="name"
                          track-by="name"
                          :placeholder="
                            trans.trans('__JSON__.Vendor Search', {
                              vendor: $auth.setting.restaurant_name,
                            })
                          "
                          open-direction="bottom"
                          :options="resturant"
                          :multiple="true"
                          :searchable="true"
                          :internal-search="true"
                          :clear-on-select="false"
                          :close-on-select="false"
                          :options-limit="30"
                          :limit="100"
                          :max-height="600"
                          :show-no-results="false"
                          :hide-selected="true"
                          @search-change="asyncFind"
                          :class="{
                            'is-invalid': $v.typeform.selectedRestaurant.$error,
                          }"
                        >
                          <template slot="tag" slot-scope="{ option, remove }">
                            <span class="custom__tag">
                              <span>{{ option.name }}</span>
                              <span
                                class="custom__remove"
                                @click="remove(option)"
                                >❌</span
                              >
                            </span>
                          </template>
                          <template slot="clear" slot-scope="props">
                            <div
                              class="multiselect__clear"
                              v-if="selectedRestaurant.length"
                              @mousedown.prevent.stop="clearAll(props.search)"
                            ></div>
                          </template>
                          <template slot="noOptions">
                            <span>{{ trans.get("__JSON__.Search...") }}.</span>
                          </template>
                          <template slot="noResult">
                            <span
                              >{{
                                trans.get(
                                  "__JSON__.Oops! No elements found. Consider changing the search query"
                                )
                              }}.</span
                            >
                          </template>
                        </multiselect>
                        <div
                          v-if="$v.typeform.selectedRestaurant.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.typeform.selectedRestaurant.required"
                            >{{
                              trans.get("__JSON__.Vendor Select Error", {
                                vendor: $auth.setting.restaurant_name,
                              })
                            }}.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <b-button
                      variant="danger"
                      v-if="
                        $auth.hasPermission(
                          config.permissions.top_picks_write
                        )
                      "
                      @click="deleteTopPicksItems"
                      :title="trans.get('__JSON__.Click to delete all top picks items for the selected restaurants')"
                      >{{ trans.get("__JSON__.Delete All Top Picks") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">{{ trans.get("__JSON__.Catelog") }}</h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div class="col-sm-2 ml-4 pt-3">
                    <div class="form-group">
                      <input
                        id="search_items"
                        name="search_items"
                        v-model="search_items"
                        type="text"
                        @input="searchItem"
                        :placeholder="trans.trans('__JSON__.Search Item')"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <LightViewTree
                      ref="tree1"
                      showCheckbox
                      :treeData="treeData"
                      @on-drop="dropNode"
                      @on-checked-item="checkedItem"
                      @on-selected-change="clickNode"
                      @on-checked-change="clickCheckbox"
                    />
                  </div>
                </div>
                <div class="btn-update">
                  <div class="col-sm-6">
                    <b-button
                      variant="primary"
                      v-if="
                        $auth.hasPermission(
                          config.permissions.top_picks_write
                        )
                      "
                      @click="addTopPicksItems()"
                      class="float-left mr-1 mt-3"
                      >{{ trans.get("__JSON__.Update") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Customer Section End -->
          </form>
        </div>
      </div>
      <!-- end row -->
    </div>
  </Layout>
</template>
<style>
</style>