const url = window.location.protocol+"//"+window.location.hostname;
const baseUrl = url+"/"+window.folderName;
// const image_baseUrl =  baseUrl+'public/';
const image_baseUrl =  process.env.MIX_STORAGE_PATH;
const app_env = process.env.MIX_APP_ENV;
const local_file_path =  baseUrl + (app_env != 'local' ? 'public/' : '');
export default {
  app_env: app_env,
  socket_url: process.env.MIX_SOCKET_URL,
  orderWebDefaultUrl: '.chowman.net',
  appname: window.appname,//'sundrop',
  merchantname: window.merchantname,
  applogo: image_baseUrl + 'media/foodapp/original/'+window.applogo,
  appurl: baseUrl,
  api_url: baseUrl + 'api/',
  // media_path: baseUrl + 'public/',
  media_path: local_file_path,
  app_api_url: baseUrl+'master/api/v4/',
  base_folder: "/"+window.folderName,//'/sundrop/', // put base folder path here
  google_key: window.google_map_key,

  basepath: local_file_path + 'media/images/',
  bannerpath: image_baseUrl + 'media/Banner/thumb/',
  dineinbannerpath: image_baseUrl + 'media/Dine_In_Banner/thumb/',

  restauranticon: image_baseUrl + 'media/Restaurant_Icon_image/original/',
  foodicon: image_baseUrl + 'media/foodapp/original/',
  brandicon: baseUrl + 'images/brands/',
  category: image_baseUrl + 'media/Category/original/',
  coupon: image_baseUrl + 'media/Coupon/',
  driver: image_baseUrl + 'media/Driver/Profile/original/',
  cuisineicon: image_baseUrl + 'media/Cuisine/original/',
  driver_document: image_baseUrl + 'media/Driver/Document/original/',
  restaurantmenu: image_baseUrl + 'media/Restaurant_Menu_image/original/',
  date_format: 'DD-MM-YYYY',
  no_image: local_file_path + 'media/images/no-img.png',
  discounticon: image_baseUrl + 'media/Discount/',
  restaurantmenuitemicon: image_baseUrl + 'media/Restaurant_Menu_Item_Image/',
  homepageimage: local_file_path + 'media/HomePageLayout/',
  categorypageimage: local_file_path + 'media/CategoryPageLayout/',
  vendorlistingimage: local_file_path + 'media/VendorListingLayout/',
  vendordetailimage: local_file_path + 'media/VendorDetailLayout/',
  loginimage: image_baseUrl + 'media/login_page/original',

  audiofile: local_file_path + 'notification.mp3',
  permissions: {
    dashboard_read: "Dashboard Read",
    order_read: "Order Read",
    order_write: "Order Write",
    zomato_order_read: "Zomato Order Read",
    zomato_order_write: "Zomato Order Write",
    booking_read: "Booking Read",
    booking_write: "Booking Write",
    call_center_read: "Call Center Read",
    call_center_write: "Call Center Write",
    dashboard_driver_read: "Dashboard Driver Read",
    eagle_view_read: "Eagle view Read",
    merchant_read: "Merchant Read",
    merchant_write: "Merchant Write",
    driver_read: "Driver Read",
    driver_write: "Driver Write",
    customer_read: "Customer Read",
    customer_write: "Customer Write",
    location_read: "Location Read",
    location_write: "Location Write",
    delivery_zone_read: "Delivery Zone Read",
    delivery_zone_write: "Delivery Zone Write",
    promo_code_read: "Promo Code Read",
    promo_code_write: "Promo Code Write",
    banner_read: "Banner Read",
    banner_write: "Banner Write",
    vendor_finance_read: "Vendor Finance Read",
    vendor_finance_write: "Vendor Finance Write",
    driver_finance_read: "Driver Finance Read",
    driver_finance_write: "Driver Finance Write",
    reports_read: "Reports Read",
    reports_write: "Reports Write",
    category_read: "Category Read",
    category_write: "Category Write",
    cuisine_read: "Cuisine Read",
    cuisine_write: "Cuisine Write",
    content_pages_read: "Content Pages Read",
    content_pages_write: "Content Pages Write",
    faq_read: "FAQ Read",
    faq_write: "FAQ Write",
    configuration_read: "Configuration Read",
    configuration_write: "Configuration Write",
    layout_read: "Layout Read",
    layout_write: "Layout Write",
    user_access_read: "User & Access Read",
    user_access_write: "User & Access Write",
    terminology_read: "Terminology Read",
    terminology_write: "Terminology Write",
    logs_read: "Logs Read",
    logs_write: "Logs Write",
    web_layout_read: "Web Layout Read",
    web_layout_write: "Web Layout Write",
    call_center_read: "Call Center Read",
    call_center_write: "Call Center Write",
    tag_read : "Tag Read",
    tag_write : "Tag Write",
    chowman_pay_read : "Chowman Pay Read",
    chowman_pay_write : "Chowman Pay Write",
    gift_voucher_read : "Gift Voucher Read",
    gift_voucher_write : "Gift Voucher Write",
    gift_voucher_purchased_read   : "Gift Voucher Purchased Read",
    gift_voucher_purchased_write  : "Gift Voucher Purchased Write",
    email_template_read: "Email Template Read",
    email_template_write: "Email Template Write",
    order_banner_read:"Order Banner Read",
    order_banner_write:"Order Banner Write",
    top_picks_write:"Top Picks Write",
    top_picks_read:"Top Picks Read",
    category_image_read: "Category Image Read",
    category_image_write: "Category Image Write",
    category_image_export: "Category Image Export",

    //subadmin export permission

    order_export: "Order Export", //Export --
    zomato_order_export: "Zomato Order Export", //Export --
    booking_export: "Booking Export", //Export --
    call_center_export: "Call Center Export", //Export --
    merchant_export: "Merchant Export", //Export
    driver_export: "Driver Export", //Export --
    customer_export: "Customer Export", //Export --
    delivery_zone_export: "Delivery Zone Export", //Export --
    vendor_finance_export: "Vendor Finance Export", //Export --
    driver_finance_export: "Driver Finance Export", //Export --
    reports_export: "Reports Export", //Export --
    category_export: "Category Export", //Export --
    chowman_pay_export: "Chowman Pay  Export", //Export --
  },
  default_time_options: [
    { value: '0', text: 'Today' },
    { value: '1', text: 'Yesterday' },
    { value: '6', text: 'Last 7 Days' },
    { value: '29', text: 'Last 30 Days' },
    { value: '365', text: 'This year' },
    { value: '101', text: 'Last month' },
  ],
  default_notification_sounds: [
    { value: 'no_sound', text: 'Mute' },
    { value: 'public/sounds/notification.mp3', text: 'Sound 1' },
    { value: 'public/sounds/notification-2.mp3', text: 'Sound 2' },
    { value: 'public/sounds/notification-3.mp3', text: 'Sound 3' },
  ],

  removed_pages: {
    billing: true
  }
}