<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
// import EditBanner from "./edit-banner";
import { bannerService } from "../../services";
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { bannerAdsService, restaurantService } from "../../services";
import { required } from "vuelidate/lib/validators";
import DropzoneSingle from "../../components/widgets/dropzone-single";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { success_message } from "../../Helper/helper";
import { requiredIf } from "vuelidate/lib/validators";
import config from "../../config";
import { routes } from "../../config/api_routes";

/**
 * Add-product component
 */
export default {
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Multiselect,
    DropzoneSingle,
    DropzonePopup,
  },
  data() {
    return {
      banner: [],
      config: config,
      editable: false,
      isLoading: false,
      typeform: {
        restaurant_id: "",
        image: "",
        title: "",
        action: "",
        type: 0,
        description: "",
        icon_image: "",
        restaurants:"",
        video_id:"",
        action_button:""

      },
      restaurants: [],
      resturant:[],
      copy_form: {
        selectedRestaurants: [],
      },
      banner_image: "",
      icon_image: "",
      active_restaurants: [],
      disable: false,
      banner_media_path: "",
      image_delete_url: routes.bannerAdsImageDeleteUrl,
      image: "",
    };
  },
  validations: {
    typeform: {
      image: {
        required: requiredIf(function (nestedModel) {
          return (!(!!this.banner_image || !!this.typeform.image) &&(this.typeform.video_id == "" || this.typeform.video_id == null));
        }),
      },
      video_id: {
        // required,
        required: requiredIf(function () {
          if (this.typeform.banner_image == "" || this.typeform.image == null) {
            return true;
          }
          return false;
        }),
      },
      icon_image: {
        required: requiredIf(function (nestedModel) {
          return !(!!this.icon_image || !!this.typeform.icon_image);
        }),
      },
      type: {
        required,
      },
      title: {
        required,
      },
      restaurants: {
        required,
      },
      action1: {
        required: requiredIf(function () {
          if (this.typeform.type == "1") {
            return true;
          }
          return false;
        }),
      },
      action2: {
        required: requiredIf(function () {
          if (this.typeform.type == "2") {
            return true;
          }
          return false;
        }),
      },
      description: {
        required,
      },
    },
    operation: {
      required,
    },
  },
  mounted() {
    this.getBannerById();
  },
  methods: {
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },

    onImagedeleted(data) {
      this.banner_image = "";
    },
    onImagedeletedIcon(data) {
      this.icon_image = "";
    },
    getActiveRestaurants() {
      restaurantService.getActiveRestaurants().then((response) => {
        this.active_restaurants = response.data.result;
      });
    },
    clearAll() {
      this.restaurants = [];
    },
    submitForm() {
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }

      var action = "";
      if (this.typeform.type == "1") {
        action = this.typeform.action1;
      } else if (this.typeform.type == "2") {
        action = this.typeform.action2;
      }

      const fd = new FormData();
      fd.append("title", this.typeform.title);
      fd.append("action", action);
      fd.append("type", this.typeform.type);
      fd.append("image", this.typeform.image);
      fd.append("icon_image", this.typeform.icon_image);
      fd.append("description", this.typeform.description);
      fd.append("banner_id", this.$route.params.id);
      fd.append("video_id", this.typeform.video_id);
      fd.append("action_button", this.typeform.action_button);

      this.typeform.restaurants
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("selectedRestaurant[" + index + "]", item);
          });


      fd.append("operation", "Update");

      bannerAdsService.addUpdateBannerAds(fd).then((response) => {
        if (response.data.code === 200) {
          this.editable = true;
          // this.getBannerAds();
          this.$router.replace({ name: "order-status-banner-list" });
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    },

    getBannerById() {
      bannerAdsService
        .getBannerById({ banner_id: this.$route.params.id })
        .then((response) => {
          // this.banner = response.data.result;
          this.typeform.title = response.data.result.title;
          this.typeform.description = response.data.result.description;
          this.typeform.action = response.data.result.action;
          this.typeform.video_id = response.data.result.video_id;
          this.typeform.action_button = response.data.result.action_button;

          this.typeform.type = response.data.result.type;
          if (this.typeform.type == "1") {
            this.typeform.action1 = response.data.result.action;
          } else if (this.typeform.type == "2") {
            this.typeform.action2 = response.data.result.action;
          }

          this.restaurants = response?.data?.result?.restaurants;
          this.typeform.restaurants = response?.data?.result?.restaurants;
          this.banner_image = response?.data?.result?.image;
          this.icon_image = response?.data?.result?.icon_image;
          
          this.banner_media_path = response?.data?.result?.media_path;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <div class="report-tag">
            <h4 class="mb-0 font-size-18">{{ typeform.title }}</h4>
            <p>
              <router-link :to="{ name: 'order-status-banner-list' }">{{
                trans.get("__JSON__.Banners")
              }}</router-link>
              > {{ typeform.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form @submit.prevent="submitForm()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{ trans.get("__JSON__.Banner Info") }}
              </h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="required">{{
                      trans.get("__JSON__.Title")
                    }}</label>
                    <input
                      id="title"
                      v-model="$v.typeform.title.$model"
                      type="text"
                      name="title"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.title.$error }"
                      :placeholder="trans.get('__JSON__.Enter Banner Title')"
                    />
                    <div
                      v-if="$v.typeform.title.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.title.required">{{
                        trans.get("__JSON__.Please Enter Banner Title")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.Description") }}</label>
                    <input
                      id="description"
                      v-model="typeform.description"
                      type="text"
                      name="description"
                      class="form-control"
                      :placeholder="trans.get('__JSON__.Enter Description')"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{ trans.get("__JSON__.Select Type") }}</label>
                    <select
                      class="custom-select"
                      v-model="$v.typeform.type.$model"
                      id="type"
                      name="type"
                      :class="{ 'is-invalid': $v.typeform.type.$error }"
                    >
                      <option value="0" selected>
                        {{ trans.get("__JSON__.None") }}
                      </option>
                      <option value="1" selected>
                        {{ trans.get("__JSON__.URL") }}
                      </option>
                      <option value="2" selected>
                        {{ trans.get("__JSON__.Call") }}
                      </option>
                    </select>
                    <div
                      v-if="$v.typeform.type.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.type.required">{{
                        trans.get("__JSON__.Please Select Type")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-sm-6"
                  v-if="typeform.type == '1' && typeform.type != '0'"
                >
                  <div class="form-group">
                    <label class="required">{{
                      trans.get("__JSON__.URL")
                    }}</label>
                    <input
                      id="action1"
                      v-model="$v.typeform.action1.$model"
                      type="url"
                      name="action1"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.action1.$error }"
                      :placeholder="trans.get('__JSON__.Enter URL')"
                    />
                    <div
                      v-if="$v.typeform.action1.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.action1.required">{{
                        trans.get("__JSON__.Please Enter URL")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-sm-6"
                  v-else-if="typeform.type == '2' && typeform.type != '0'"
                >
                  <div class="form-group">
                    <label class="required">{{
                      trans.get("__JSON__.Phone")
                    }}</label>
                    <input
                      id="action2"
                      v-model="$v.typeform.action2.$model"
                      type="number"
                      name="action2"
                      class="form-control"
                      :class="{ 'is-invalid': $v.typeform.action2.$error }"
                      :placeholder="trans.get('__JSON__.Enter Phone Number')"
                    />
                    <div
                      v-if="$v.typeform.action2.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.action2.required">{{
                        trans.get("__JSON__.Please Enter Phone Number")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
            <div class="form-group">
              <label class="required">{{ trans.get("__JSON__.YouTube Video ID") }}</label>
              <input
                id="action1"
                v-model="$v.typeform.video_id.$model"
                type="text"
                name="video_id"
                class="form-control"
                :class="{ 'is-invalid': $v.typeform.video_id.$error }"
                :placeholder="trans.get('__JSON__.Enter YouTube Video ID')"
              />
              <div v-if="$v.typeform.video_id.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.video_id.required">{{
                  trans.get("__JSON__.Please Enter YouTube Video ID")
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label class="required">{{ trans.get("__JSON__.Action Button") }}</label>
              <input
                id="action_button"
                v-model="typeform.action_button"
                type="text"
                name="action_button"
                class="form-control"
                :placeholder="trans.get('__JSON__.Enter Action Button Text')"
              />
            </div>
          </div>

                <div class="col-sm-6">
                  <b-form-group
                    id="input-group-1"
                    :label="
                      trans.trans('__JSON__.Vendors', {
                        vendor: $auth.setting.restaurant_name,
                      })
                    "
                    label-for="banner_name"
                    for="ajax"
                  >
                    <multiselect
                      v-model="$v.typeform.restaurants.$model"
                      id="ajax"
                      label="name"
                      track-by="name"
                      :placeholder="
                        trans.trans(
                          '__JSON__.Banner Vendor Search Placeholder',
                          { vendor: $auth.setting.restaurant_name }
                        )
                      "
                      open-direction="bottom"
                      :options="resturant"
                      :multiple="true"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :options-limit="5"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                      :class="{ 'is-invalid': $v.typeform.restaurants.$error }"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.name }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="restaurants.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div>
                      </template>
                      <span slot="noResult"
                        >Oops! No elements found. Consider changing the search
                        query.</span
                      >
                    </multiselect>
                    <div
                      v-if="$v.typeform.restaurants.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeform.restaurants.required"
                        >{{
                          trans.trans("__JSON__.Vendor Select Error", {
                            vendor: $auth.setting.restaurant_name,
                          })
                        }}.</span
                      >
                    </div>
                  </b-form-group>
                </div>

                <div class="col-sm-12">
                  <div class="row p-0">
                    <div class="col-2">
                      <h4 class="card-title">
                        {{ trans.trans("__JSON__.Banner Image") }}
                      </h4>
                      <p class="card-title-desc"></p>
                      <div class="row">
                        <div class="col-sm-12 imgUp">
                          <dropzone-popup
                            v-model="typeform.image"
                            id="banner_image"
                            acceptedFiles=".jpeg,.jpg,.png"
                            :editable-images="banner_image"
                            :media-path="banner_media_path + '/original'"
                            :delete-path="image_delete_url"
                            :custom-params="{ type: 'banner_image' }"
                            @image-deleted="this.onImagedeleted"
                          />
                          <div
                            v-if="$v.typeform.image.$error"
                            class="invalid-feedback cuisine-img"
                          >
                            <span v-if="!$v.typeform.image.required">{{
                              trans.get("__JSON__.Please Select Banner Image")
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <button
                            type="submit"
                            v-if="
                              $auth.hasPermission(
                                config.permissions.merchant_write
                              ) && !$auth.partner_login
                            "
                            class="btn btn-primary mr-1 mt-3"
                            :disabled="disable"
                          >
                            {{ trans.trans("__JSON__.Submit") }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <h4 class="card-title">
                        {{ trans.trans("__JSON__.Icon Image") }}
                      </h4>
                      <p class="card-title-desc"></p>
                      <div class="row">
                        <div class="col-sm-12 imgUp">
                          <dropzone-popup
                            v-model="typeform.icon_image"
                            id="icon_image"
                            acceptedFiles=".jpeg,.jpg,.png"
                            :editable-images="icon_image"
                            :media-path="banner_media_path + '/original'"
                            :delete-path="image_delete_url"
                            :custom-params="{ type: 'icon_image' }"
                            @image-deleted="this.onImagedeletedIcon"
                          />
                          <div
                            v-if="$v.typeform.icon_image.$error"
                            class="invalid-feedback cuisine-img"
                          >
                            <span v-if="!$v.typeform.icon_image.required">{{
                              trans.get(
                                "__JSON__.Please Select Banner Icon Image"
                              )
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Banner Image End -->
        </b-form>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
