<script>
import pagination from "laravel-vue-pagination";

import config from "../../../config";
import Multiselect from "vue-multiselect";
import { success_message, error_message } from "../../../Helper/helper";
import DropzonePopup from "../../../components/widgets/dropzone-popup";
import {
  required,requiredIf
} from "vuelidate/lib/validators";
import { apiMessageTextService,bannerService } from "../../../services";
import { routes } from "../../../config/api_routes";
/**
 * Starter component
 */
export default {
  components: { pagination,Multiselect,bannerService,DropzonePopup },
  data() {
    return {
      config: config,
      is_image:false,
      media_path:'',
      image : "",
      image_delete_url:routes.deleteImagePromotionalText,
      typeform: {
        title : "",
        description : "",
        image:""
      },
      
    };
  },
  validations: {
    typeform: {
      title:{
        required
      },
      description:{
        required
      },
      image: {
        required: requiredIf(function (nestedModel) {
          return (!(!!this.image || !!this.typeform.image) &&(this.typeform.video_id == "" || this.typeform.video_id == null));
        }),
      },
    }
  },
  mounted() {
    this.getOrderStatusPromotionalText();
  },
  methods: {
    onImagedeleted() {
        this.image = "";
        this.is_image = false;
        // this.getOrderStatusPromotionalText();
    },
     updatePromotionalText() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("title", this.typeform.title);
        fd.append("description", this.typeform.description);
        fd.append("image", this.typeform.image);

        apiMessageTextService.addOrderStatusPromotionalText(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
            this.getOrderStatusPromotionalText();
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    getOrderStatusPromotionalText(){
      apiMessageTextService.getOrderStatusPromotionalText().then((response) => {
        if (response.data.code === 200) {
          this.typeform.title = response?.data?.result?.title ??  ''; 
          this.typeform.description = response?.data?.result?.description ??  ''; 
          this.typeform.image = "";
            let img = response?.data?.result?.image ?? '';
            if(img != '' && img != null){
              this.is_image = true
              this.media_path = response?.data?.result?.media_path
              this.image = img;
            }
            
          } else {
            error_message(response.data.message);
          }
        });
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body settings-main">
          <b-form @submit.prevent=" updatePromotionalText" class="row">
              <div class="col-md-6">
                <label>{{ trans.trans('__JSON__.Title') }}</label>
                <input
                  v-model="$v.typeform.title.$model"
                  id="title"
                  name="title"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Title')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.title.$error }"
                />
                <div
                      v-if="$v.typeform.title.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span
                        v-if="!$v.typeform.title.required"
                      >{{ trans.get('__JSON__.Please enter title') }}.</span>
                    </div>
              </div>
              <div class="col-md-6">
                <label>{{ trans.trans('__JSON__.Description') }}</label>
                <textarea
                  v-model="$v.typeform.description.$model"
                  id="description"
                  name="description"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Description')"
                  class="form-control"
                  :class="{ 'is-invalid': $v.typeform.description.$error }"
                ></textarea>
                <div
                      v-if="$v.typeform.description.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span
                        v-if="!$v.typeform.description.required"
                      >{{ trans.get('__JSON__.Please enter description') }}.</span>
                    </div>
              </div>

              <div class="col-sm-12">
            <div class="row p-0">
              <div class="col-2">
                <h4 class="card-title">
                  {{ trans.trans("__JSON__.Image") }}
                </h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div class="col-sm-12 imgUp">
                    <dropzone-popup
                        v-model="typeform.image"
                        id="banner_image"
                        acceptedFiles=".jpeg,.jpg,.png"
                        :editable-images="image"
                        :media-path="media_path + '/original'"
                        :delete-path="image_delete_url"
                        @image-deleted="this.onImagedeleted"
                        :custom-params="{ type: 'Order Status Promotional Image' }"
                    />
                    <div
                      v-if="$v.typeform.image.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.typeform.image.required">{{
                        trans.get("__JSON__.Please select image")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
            <div class="btn-update">
              <div class="col-sm-6">
                <b-button
                  variant="primary"
                  v-if="$auth.hasPermission(config.permissions.terminology_write)"
                  @click=" updatePromotionalText"
                  class="float-left mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <!-- Add Api Text Modal Start -->
    <!-- Add Add Api Text Modal End -->
  </div>
</template>