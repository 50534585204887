<script>
import config from "../../config";
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import { driverService, deliveryZoneService } from "../../services";
import { base64, debounce } from "../../Helper/helper";

export default {
  components: { Layout, PageHeader, pagination, moment },
  data() {
    const { status, delivery_zone, orderBy, sortBy } = this.$route.query;
    return {
      sortBy: sortBy || "createddate",
      sortDesc: orderBy && orderBy != "" ? true : orderBy,
      drivers: null,
      driverData: [],
      driverCount: "",
      paginations: null,
      limit: 2,
      search: "",
      status: status == undefined ? "0" : status,
      config: config,
      moment: moment,
      delivery_zones: [],
      delivery_zone: delivery_zone || "0",
      loading: true,
      init: true,
      searchData: debounce(() => {
        if (!this.init) {
          const { status, delivery_zone, orderBy, sortBy } = this.$data;
          this.$router.replace({
            name: "driver-list",
            query: { status, delivery_zone, orderBy, sortBy },
          });
        }
        this.getDriver();
        // this.driverExportCsv();
        this.init = false;
      }, 500),
      fields: [
        { key: "profileimage", label: "#", sortable: false },
        {
          key: "firstname",
          label: this.trans.get("__JSON__.Name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.trans.get("__JSON__.Email"),
          sortable: true,
        },
        {
          key: "contactno",
          label: this.trans.get("__JSON__.Phone Number"),
          sortable: true,
        },
        {
          key: "restaurant",
          label: this.$auth.getRestaurantName(),
          sortable: true,
        },
        {
          key: "last_name",
          label: this.trans.get("__JSON__.Delivery Zone"),
          sortable: false,
        },
        {
          key: "order_count",
          label: this.trans.get("__JSON__.Orders"),
          sortable: true,
        },
        {
          key: "rating",
          label: this.trans.get("__JSON__.Ratings"),
          sortable: true,
        },
        {
          key: "is_block",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getDeliveryZone();
  },
  methods: {
    onRowClicked(item) {
      this.$router.push({
        name: "driver-details",
        params: { driver_id: this.base64(item.driverid) },
      });
    },
    downloadsCSV: function () {
      let driverData =
        "\ufeff" +
        "First Name ("+this.$auth.langauges[0].name+"),Last Name ("+this.$auth.langauges[0].name+"),First Name ("+this.$auth.langauges[1].name+"),Last Name ("+this.$auth.langauges[1].name+"),Email,Phone Number,Alternate Phone Number,Delivery Zone,Full Address,Vehicle Type,Vehicle Number,Licence Number,Order,Availbility Status\n";
      this.driverData.forEach((el) => {
        var line =
          el["firstname"] +
          "," +
          el["lastname"] +
          "," +
          (el["firstname_other_lang"] ? el["firstname_other_lang"] : "-").replaceAll("null","-") + 
          "," +
          (el["lastname_other_lang"] ? el["lastname_other_lang"] : "-").replaceAll("null","-") +
          "," +
          el["email"] +
          "," +
          el["contactno"] +
          "," +
          (el["alternate_contactno"] ? el["alternate_contactno"] : "-").replaceAll("null","-") +
          "," +
          (el["zone"] ? el["zone"] : "-").replaceAll("null","-") +
          "," +
          (el["address"] ? el["address"] : "-").replaceAll(",", " ") +
          "," +
          el["vehicle_type"] +
          "," +
          (el["vehicle_number"] ? el["vehicle_number"] : "-").replaceAll("null","-") +
          "," +
          (el["licence_number"] ? el["licence_number"] : "-").replaceAll("null","-") +
          "," +
          el["order_count"] +
          "," +
          (el["is_block"] == 0 ? "Active" : el["is_block"] == 1 ? "Inactive" : "Waiting For Approval") +
          "\n";
        driverData += line;
      });

      var blob = new Blob([driverData], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.$auth.setting.driver_name + "_" + date + ".csv";
      link.click();
    },
    getDriver(page) {
      this.loading = true;
      if (this.sortBy == "firstname") {
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        delivery_zone: this.delivery_zone,
        page: page && page > 0 ? page : 1,
      };
      driverService.getDriver(filters).then((response) => {
        this.loading = false;
        this.paginations = response.data.result;
        this.drivers = response.data.result.data;
        this.driverCount = response.data.result.total;
      });
    },
    driverExportCsv() {
      driverService
        .driverExportCsv({
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
        })
        .then((response) => {
          this.driverData = response.data.result.data;
          this.downloadsCSV()
        });
    },
    getDeliveryZone() {
      driverService.getFrenchise()
        .then(response => {
            this.delivery_zones =  response.data.result;
            this.searchData()
        });
    },
   /*  getVendorState() {
      this.city = "0";
      this.state = "0";
      deliveryZoneService
        .getVendorState({
          country_id: this.country,
        })
        .then((response) => {
          this.states = response.data.result;
          this.getVendorCity();
        });
    },
    getVendorCity() {
      this.city = "0";
      deliveryZoneService
        .getVendorCity({
          state_id: this.state ? this.state : "0",
          country_id: this.country,
        })
        .then((response) => {
          this.cities = response.data.result;
          this.searchData();
        });
    }, */
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getDriver();
      // this.driverExportCsv();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user"></i>
              </span>
            </div>
            <h4
              class="mb-0 font-size-18"
            >{{ trans.trans('__JSON__.Driver',{ driver: $auth.setting.driver_name }) }}({{driverCount}})</h4>
          </div>
          <div class="page-title-right d-flex">
            <router-link
              :to="{name: 'driver-add'}"
              class="btn btn-success btn-rounded mr-2 add-btn"
              v-if="$auth.hasPermission(config.permissions.driver_write)"
            >
              <i class="mdi mdi-plus mr-1"></i>
              {{ trans.trans('__JSON__.Add Delivery Boy',{ driver: $auth.setting.driver_name }) }}
            </router-link>
            <button
              @click="driverExportCsv"
              class="btn btn-primary btn-rounded export-btn"
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
            <div class="d-flex m-vendor-btn">
              <router-link
                :to="{name: 'driver-add'}"
                class="btn btn-success btn-rounded mr-2 m-add-btn"
                v-if="$auth.hasPermission(config.permissions.driver_write)"
              >
                <i class="mdi mdi-plus"></i>
              </router-link>
              <button @click="downloadsCSV" 
              v-if="$auth.hasSubAdminPermission(config.permissions.driver_export)"
              class="btn btn-primary btn-rounded m-export-btn">
                <i class="bx bx-archive-in"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div v-if="delivery_zones.length >1" class="col-sm-2 offset-sm-4">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Delivery Zone') }} :</label>
                    <select class="custom-select" v-model="delivery_zone" @change="searchData">
                      <option value="0" selected>{{ trans.get('__JSON__.All') }}</option>
                      <option
                        v-for="con in delivery_zones"
                        :value="con.user_id"
                        :key="con.user_id"
                      >{{con.last_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-else class="col-sm-2"></div>
              <!-- <div v-if="countries.length >=1 && states.length >1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.State') }} :</label>
                    <select class="custom-select" v-model="state" @change="getVendorCity">
                      <option value="0" selected>{{ trans.get('__JSON__.All') }}</option>
                      <option v-for="sat in states" :value="sat.id" :key="sat.id">{{sat.state_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="states.length >=1 && cities.length >1" class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.City') }} :</label>
                    <select class="custom-select" v-model="city" @change="searchData">
                      <option value="0" selected>{{ trans.get('__JSON__.All') }}</option>
                      <option
                        v-for="cit in cities"
                        :value="cit.cityid"
                        :key="cit.cityid"
                      >{{cit.cityname}}</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.SortBy') }} :</label>
                    <select class="custom-select" v-model="sortBy" @change="searchData()">
                      <!-- <option value="status">{{ trans.get('__JSON__.Default') }}</option> -->
                      <option value="createddate">{{ trans.get('__JSON__.Date Added') }}</option>
                      <option value="order_count">{{ trans.get('__JSON__.Most Orders') }}</option>
                      <option value="firstname">{{ trans.get('__JSON__.Alphabetically') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value>{{ trans.get('__JSON__.All') }}</option>
                      <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                      <option value="2">{{ trans.get('__JSON__.Waiting For Approval') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="this.trans.get('__JSON__.Search')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list driver-table table-responsive">
                <b-table
                  :items="drivers && drivers || []"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  :sort-desc.sync="sortDesc"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  @row-clicked="onRowClicked"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>
                  <template v-slot:cell(profileimage)="data">
                    <div v-if="data.item.profileimage">
                      <img
                        alt
                        title="product-img"
                        class="vendor-img avatar-xs"
                        :src="config.driver+data.item.profileimage"
                        @error="$event.target.src=config.basepath+'no-img.png'"
                      />
                    </div>
                    <div v-else>
                      <img class="vendor-img avatar-xs" :src="config.basepath+'no-img.png'" alt />
                    </div>
                  </template>

                  <template v-slot:cell(firstname)="data">
                    <h5 class="font-size-14">{{data.item.firstname}} {{data.item.lastname}}</h5>
                  </template>

                  <template
                    v-slot:cell(last_name)="data"
                  >{{data.item.franchise?data.item.franchise.last_name:'-'}}</template>

                  <template v-slot:cell(restaurant)="data">
                    <span v-b-popover.html.hover.bottom="data.item.restaurants.map(restaurant => restaurant.restaurant_name).join('<br>')"
                    class="font-size-14">
                      {{ data.item.restaurants ? data.item.restaurants.length  : 0 }}
                    </span>
                  </template>
                  <template v-slot:cell(rating)="data">
                  {{(data.value.length > 0?(data.value.reduce((a,c)=>c.avg_rating+a,0)/data.value.length):0).toFixed(1)}}</template>

                  <template v-slot:cell(is_block)="data">
                    <div
                      v-if="data.item.is_block == '0'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-if="data.item.is_block == '1'"
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                    <div
                      v-if="data.item.is_block == '2'"
                      class="badge badge-pill badge-soft-warning font-size-12"
                    >{{ trans.get('__JSON__.Waiting For Approval') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link
                      :to="{name: 'driver-details',params: { driver_id: base64(data.item.driverid) }}"
                      class="btn btn-primary btn-sm btn-rounded"
                    >{{ trans.get('__JSON__.View') }}</router-link>
                    <!-- <router-link :to="{name: 'driver-details',params: { driver_id:data.item.driverid}}" class="btn btn-primary btn-sm btn-rounded">{{ trans.get('__JSON__.View') }}</router-link> -->
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Driver Found') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0" v-if="paginations">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getDriver"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>