
// ApiMessageText services rest api calling

import { routes } from '../config/api_routes'

export const apiMessageTextService = {
    // get ApiMessageText 

    getApiMessageTextListing: (formData) => {    
        return window.axios.post(routes.getApiTextApi,formData)
    },

    // add ApiMessageText

    addApiMessageText: (formData) => {
        return window.axios.post(routes.addApiTextApi,formData)
    },

    // update ApiMessageText
    updateApiMessageText: (formData) => {
        return window.axios.post(routes.updateApiTextApi,formData)
    },
    updateWeatherMessage: (formData) => {
        return window.axios.post(routes.updateWeatherMessageApi,formData)
    },
    updateDineInMessage: (formData) => {
        return window.axios.post(routes.updateDineInMessageApi,formData)
    },
    addOrderStatusPromotionalText: (formData) => {
        return window.axios.post(routes.addOrderStatusPromotionalText,formData)
    },
    getOrderStatusPromotionalText: () => {
        return window.axios.get(routes.getOrderStatusPromotionalText)
    },
    clearWeatherMessage: (formData) => {
        return window.axios.post(routes.clearWeatherMessageApi,formData)
    }
}