<template>
  <b-form @submit.prevent="submitForm()">
    <!-- Banner Image Start -->
    <!-- <div class="">
      <div class="">
        <div class="d-flex justify-content-end" v-if="editable && !$auth.partner_login">
          <b-button variant="primary" v-b-modal.banner-ads>Copy Banner Ads</b-button>
        </div> -->
        <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ trans.get('__JSON__.Banner Info') }}</h4>
        <p class="card-title-desc"></p>
        <div class="row">

          <div class="col-sm-6">
            <div class="form-group">
              <label class="required">{{ trans.get("__JSON__.Title") }}</label>
              <input
                id="title"
                v-model="$v.typeform.title.$model"
                type="text"
                name="title"
                class="form-control"
                :class="{ 'is-invalid': $v.typeform.title.$error }"
                :placeholder="trans.get('__JSON__.Enter Banner Title')"
              />
              <div v-if="$v.typeform.title.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.title.required">{{
                  trans.get("__JSON__.Please Enter Banner Title")
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>{{ trans.get("__JSON__.Description") }}</label>
              <input
                id="description"
                v-model="typeform.description"
                type="text"
                name="description"
                class="form-control"
                :placeholder="trans.get('__JSON__.Enter Description')"
              />
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>{{ trans.get("__JSON__.Select Type") }}</label>
              <select
                class="custom-select"
                v-model="$v.typeform.type.$model"
                id="type"
                name="type"
                :class="{ 'is-invalid': $v.typeform.type.$error }"
              >
                <option value="0" selected>
                  {{ trans.get("__JSON__.None") }}
                </option>
                <option value="1" selected>
                  {{ trans.get("__JSON__.URL") }}
                </option>
                <option value="2" selected>
                  {{ trans.get("__JSON__.Call") }}
                </option>
              </select>
              <div v-if="$v.typeform.type.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.type.required">{{
                  trans.get("__JSON__.Please Select Type")
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6"  v-if="typeform.type == '1' && typeform.type != '0'">
            <div class="form-group">
              <label class="required">{{ trans.get("__JSON__.URL") }}</label>
              <input
                id="action1"
                v-model="$v.typeform.action1.$model"
                type="url"
                name="action1"
                class="form-control"
                :class="{ 'is-invalid': $v.typeform.action1.$error }"
                :placeholder="trans.get('__JSON__.Enter URL')"
              />
              <div v-if="$v.typeform.action1.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.action1.required">{{
                  trans.get("__JSON__.Please Enter URL")
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6" v-else-if="typeform.type == '2' && typeform.type != '0'">
            <div class="form-group">
              <label class="required">{{ trans.get("__JSON__.Phone") }}</label>
              <input
                id="action2"
                v-model="$v.typeform.action2.$model"
                type="number"
                name="action2"
                class="form-control"
                :class="{ 'is-invalid': $v.typeform.action2.$error }"
                :placeholder="trans.get('__JSON__.Enter Phone Number')"
              />
              <div v-if="$v.typeform.action2.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.action2.required">{{
                  trans.get("__JSON__.Please Enter Phone Number")
                }}</span>
              </div>
            </div>
          </div>





          
          <div class="col-sm-12">
            <div class="p-0">
              <h4 class="card-title">
                {{
                  trans.trans("__JSON__.Banner Image")
                  }}
              </h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-12 imgUp">

                  <!-- <dropzone-popup
                  v-if="!editable"
                  multiple
                  v-model="typeform.image"
                  type="file"
                  id="banner_image"
                  name="banner_image"
                  acceptedFiles=".jpeg,.jpg,.png"
                  :class="{ 'is-invalid': $v.typeform.image.$error }"
                  /> -->
                  <dropzone-popup
                  multiple
                  v-model="typeform.image"
                  id="banner_image"
                  acceptedFiles=".jpeg,.jpg,.png"
                  :editable-images="banner_image.map(o => o.image)"
                  :media-path="banner_media_path + '/original'"
                  :delete-path="image_delete_url"
                  :custom-params="{ driverid: $route.params.driver_id }"
                  @image-deleted="this.onIconImagedeleted"
                  :class="{ 'is-invalid': $v.typeform.image.$error }"
                  />
                  <div
                    v-if="$v.typeform.image.$error"
                    class="invalid-feedback cuisine-img"
                  >
                    <span v-if="!$v.typeform.image.required">{{
                      trans.get("__JSON__.Please Select Banner Image")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button
                    type="submit"
                    v-if="
                      $auth.hasPermission(config.permissions.merchant_write) &&
                      !$auth.partner_login
                    "
                    class="btn btn-primary mr-1 mt-3"
                    :disabled="disable"
                  >
                    {{
                      trans.trans("__JSON__.Submit")
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="banner-ads" title="Copy Banner Ads" lazy @show="getActiveRestaurants" @ok="copyBannerAds" @hidden="copy_form.selectedRestaurants=[];" >
        <div class="form-group">
          <label class="typo__label">Select Restaurants</label>
          <multiselect v-model="copy_form.selectedRestaurants" deselect-label="Can't remove this value" track-by="restaurant_id" label="name" 
            placeholder="Select any" :multiple="true" :options="active_restaurants" :searchable="true" :allow-empty="true">
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
                <span>{{ option.name }}</span>
                <span class="custom__remove" @click="remove(option)">❌</span>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div class="multiselect__clear" v-if="copy_form.selectedRestaurants.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </multiselect>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <div class="d-flex justify-content-between w-100">
              <div>
                <b-button variant="success" @click="copyBannerAds(true) || hide()">
                  Copy to All
                </b-button>
              </div>
              <div>
                <b-button variant="secondary" @click="cancel()">
                  Cancel
                </b-button>
                <b-button variant="primary" @click="ok()" :disabled="!copy_form.selectedRestaurants.length">
                  Copy
                </b-button>
              </div>
            </div>
          </template>
      </b-modal>
    </div>
    <!-- Banner Image End -->
  </b-form>
</template>


<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import { bannerAdsService, restaurantService } from "../../services";
import { required } from "vuelidate/lib/validators";
import DropzoneSingle from "../../components/widgets/dropzone-single";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import { success_message } from '../../Helper/helper';
import {requiredIf} from "vuelidate/lib/validators";
import config from "../../config";
import { routes } from "../../config/api_routes";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect,
    DropzoneSingle,
    DropzonePopup,
  },
  data() {
    return {
    config:config,
    editable:false,
    typeform: {
        restaurant_id: "",
        image: "",
        title: "",
        action: "",
        type: 0,
        description: "",
    },
    copy_form:{ 
        selectedRestaurants:[],
      },
    banner_image:[],
    active_restaurants:[],
    disable:false,
    banner_media_path:"",
    image_delete_url: routes.bannerAdsImageDeleteUrl,
    image:""
    };
  },
  validations: {
    typeform: {
      image: {
        required: requiredIf(function (nestedModel) {
          return !(!!this.banner_image || !!this.typeform.image);
        }),
      },
      type: {
        required,
      },
      title: {
        required,
      },
      action1: {
      required: requiredIf(function () {
        if (this.typeform.type == "1") {
          return true;
        } 
        return false;
      }),
      },
      action2: {
      required: requiredIf(function () {
        if (this.typeform.type == "2") {
          return true;
        } 
        return false;
      }),
      },
      description: {
        required,
      },
    },
    operation:{
      required
    }
  },
  mounted(){
    this.getBannerAds();
  },
  methods:{
    onIconImagedeleted(value) {
      this.banner_image = this.banner_image.filter(doc => doc.image !== value);
    },
    copyBannerAds(all = true){
      bannerAdsService.copyBannerAds({
        restaurant_id: this.restaurant_id,
        ...this.copy_form,
        // from_type: this.type,
        restaurant_id:this.$route.params.restaurant_id,
        all: all === true?1:0,
        restaurant_ids : this.copy_form.selectedRestaurants.map(r => r.restaurant_id)
      }).then((response) => {
        this.copy_form.selectedRestaurants=[];
        success_message(response.data.message)
      })
    },
    getActiveRestaurants(){
      restaurantService.getActiveRestaurants()
        .then(response => {
          this.active_restaurants = response.data.result;
        })
    },
    submitForm(){
      this.$v.typeform.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      }

      var action = '';
      if(this.typeform.type == '1'){
        action = this.typeform.action1;
      }else if(this.typeform.type == '2'){
        action = this.typeform.action2;
      }


        const fd = new FormData();
        fd.append("title", this.typeform.title);
        fd.append("action",action);
        fd.append("type", this.typeform.type);
        fd.append("image", this.typeform.image);
        fd.append("description", this.typeform.description);
        fd.append("restaurant_id", this.$route.params.restaurant_id);
        fd.append("operation",this.editable ? 'Update' : 'Create');
        
        bannerAdsService.addUpdateBannerAds(fd).then((response) => {
          if (response.data.code === 200) {

            this.editable = true;
            this.getBannerAds();
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });

    },
    getBannerAds(){  
        bannerAdsService.getBannerAds({restaurant_id:this.$route.params.restaurant_id}).then((response) => {
          if (response.data.code === 200) {

            this.typeform.type = response.data.result.type;
            this.typeform.description = response.data.result.description;
            this.typeform.title = response.data.result.title;
            this.typeform.image = '';
            this.editable = true

            if(this.typeform.type == '1'){
              this.typeform.action1 = response.data.result.action;
            }else if(this.typeform.type == '2'){
              this.typeform.action2 = response.data.result.action;
            }

            this.banner_image = response.data.result.banner_images;
            this.banner_media_path = response.data.result.media_path
          } else {
            // error_message(response.data.message);
          }
        });
    }
  }
};
</script>