<script>
import { get_currency, get_decimal } from "../../../Helper/helper";
import { orderService } from "../../../services";
import OrderShow from "./order-show";
import OrderCancel from "./order-cancel";
import OrderAction from "./order-action";
import OrderAssign from "./order-assign";
import OrderDunzo from "./order-dunzo";
import OrderCustomerNotes from "./order-customer-notes.vue";
import { integer } from "vuelidate/lib/validators";
import moment from 'moment';
export default {
  components: {
    OrderShow,
    OrderAction,
    OrderAssign,
    OrderCancel,
    OrderCustomerNotes,
    OrderDunzo,
  },
  props: {
    orders: {
      default: {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    "extra-columns": {
      default: function () {
        return [];
      },
      type: Array,
    },
    showDeliveryTime: {
      default: false,
      type: Boolean,
    },
    delay_order_btn: {
      default: false,
      type: Boolean,
    },
    not_response_btn: {
      default: false,
      type: Boolean,
    },
    cancel_order: {
      default: false,
      type: Boolean,
    },
    thirdPartyOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        order_status: this.order_status,
      },
      meta: [
        { charset: "utf-8" },
        { equiv: "Content-Type", content: "text/html" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      decimal: get_decimal(),
      currency: get_currency(),
      fields: [
        { key: "order_id", label: this.trans.get("__JSON__.Order ID"), sortable: false },
        {
          key: "vendor_name",
          label: this.trans.get("__JSON__.Vendor's Name", {
            vendor: this.$auth.getRestaurantName(),
          }),
          sortable: false,
        },
        {
          key: "customer_name",
          label: this.trans.get("__JSON__.Customer Name"),
          sortable: false,
        },
        { key: "created_at", label: this.trans.get("__JSON__.Time"), sortable: false },
        {
          key: "delivery_time",
          label: this.trans.get("__JSON__.Delivery Time"),
          sortable: false,
        },
        {
          key: "total_amount",
          label: this.trans.get("__JSON__.Amount"),
          sortable: false,
        },
        // { key: "delivery_pickup_types", label: this.trans.get("__JSON__.Type"), sortable: false },
        {
          key: "payment_method",
          label: this.trans.get("__JSON__.Payment"),
          sortable: false,
        },
        ...(this.extraColumns || []),
        { key: "action", label: this.trans.get("__JSON__.Action"), sortable: false },
      ],
      showModal: false,
      showCancel: false,
      showNote: false,
      showDunzo: false,
      showPidge: false,
      order: null,
      viewIndex: 0,
      orderId: null,
      venodr_order_id: null,
    };
  },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      //if(this.$auth.partner_login)
      //  return this.fields.filter(field => !field.requiresAdmin);

      // If the user IS an admin, return all fields.
      //else
      return this.fields;
    },
  },
  mounted() {
    this.$data.fields[1].label = this.$auth.setting && this.$auth.setting.restaurant_name;
  },
  methods: {
    viewDetails(id) {
      this.showModal = true;
      this.orderId = id;
      // orderService.getOrder(id)
      //   .then(res => {
      //     this.order = res.data.result;
      //     this.orderLoading = false;
      //   })
    },
    viewPageDetails(item, index, event) {
      this.viewIndex = index;
      if (item.vendor_order_id != null) {
        this.vendor_order_id = item.vendor_order_id;
      } else {
        this.vendor_order_id = null;
      }
      this.viewDetails(item.base_id);
      this.$refs.selectableTable.clearSelected();
    },
    actionClicked(attr) {
      this.orderId = atob(attr.id);
      attr.type === "cancel" ? (this.showCancel = true) : false;
      attr.type === "note" ? (this.showNote = true) : false;
      attr.type === "dunzo" ? (this.showDunzo = true) : false;
      attr.type === "pidge" ? (this.showPidge = true) : false;
    },
    delayOrderColor(orders) {
		if(orders) 
		{
      const etaDiff = orders.eta ? moment().diff(moment(orders.eta)) : 0;
      const deliveryDiff = orders.delivered_date ? moment(orders.delivered_date).diff(moment(orders.eta)) : 0;
      const etaDiffInHour = orders.eta ? moment().diff(moment(orders.eta), 'hours') : 0;
      const deliveryDiffInHour = orders.delivered_date ? moment(orders.delivered_date).diff(moment(orders.eta), 'hours') : 0;
			if(orders.delivery_pickup_types == 'Delivery') 
      {
        if(orders.order_status)
        {
          if(
              ['InKitchen', 'ReadyToServe', 'OnTheWay', 'Arrived'].includes(orders.order_status) && (orders.eta != null && etaDiff > 0) ||
              (orders.order_status === 'Delivered' && deliveryDiff > 0)
          )
          {
            if (orders.order_status !== 'Delivered' && etaDiffInHour >= 2) 
            {
                return 'delayTwoHours';
            }
            else if(deliveryDiffInHour >= 2)
            {
              return 'delayTwoHours';
            }
            return 'delayOrderColor';
          }
        }
      }
		}
	}
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12 m-order-main">
      <div class="table-responsive mh-400">
        <b-table
          :items="(orders && orders.data) || []"
          :fields="computedFields"
          ref="selectableTable"
          show-empty
          :busy="loading"
          class="table-centered table-nowrap table-hover table-main-border order-menu"
          selectable
          select-mode="single"
          @row-clicked="viewPageDetails"
          :tbody-tr-class="delayOrderColor"
        >
          <template #empty>
            <p class="text-center">{{ trans.get("__JSON__.No Order Found") }}</p>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
            </div>
          </template>

          <template #cell(order_id)="data">
            <div>
              <span class="font-weight-bold">
                {{
                  thirdPartyOrder
                    ? data.item.customer_order_id != null &&
                      data.item.customer_order_id != ""
                      ? "#" + data.item.customer_order_id
                      : data.item.vendor_order_id
                    : data.item.order_id
                }}
              </span>
              <span
                class="badge badge-pill badge-soft-primary font-size-12"
                v-if="data.item.delivery_pickup_types == 'Delivery'"
              >
                {{ trans.get("__JSON__." + data.item.delivery_pickup_types) }}
              </span>
              <span
                class="badge badge-pill badge-soft-danger font-size-12"
                v-if="data.item.delivery_pickup_types == 'Takeaway'"
              >
                {{ trans.get("__JSON__." + data.item.delivery_pickup_types) }}
              </span>
              <span
                class="badge badge-pill badge-soft-danger font-size-12"
                v-if="data.item.delivery_pickup_types == 'Pickup'"
              >
                {{ trans.get("__JSON__." + data.item.delivery_pickup_types) }}
              </span>
              <span
                class="badge badge-pill badge-soft-pink font-size-12"
                v-if="data.item.delivery_pickup_types == 'Dine In'"
              >
                {{ trans.get("__JSON__." + data.item.delivery_pickup_types) }}
              </span>
            </div>
            <b-badge
              v-if="data.item.access_name"
              pill
              class="font-size-12 badge-soft-secondary mt-1"
              ><i class="bx bx-phone-call"></i> {{ data.item.access_name }}</b-badge
            >
          </template>
          <template #cell(vendor_name)="data">
            <!-- {{ data.item.restaurant && data.item.restaurant.name || data.item.id}} -->
            <!-- <span class="font-weight-bold">{{ data.value }}</span> -->
            <span class="font-weight-bold">
              <template v-if="thirdPartyOrder && data.item.fa_order_address && data.item.fa_order_address.restaurant_name">
                {{ data.item.fa_order_address.restaurant_name }}
                <!-- <small>{{ data.item.device_type }}</small> -->
              </template>
              <template v-else>
                {{ data.value }}
                <small class="d-block font-weight-bold">{{ data.item.device_type }}
                  <span v-if="data.item.device_type != 'CALL-CENTER' &&
                        data.item.device_type != 'Web'">{{ data.item.app_version_code }}</span>
                </small>
               
              </template>
              <!-- {{ thirdPartyOrder ? (data.item.fa_order_address.restaurant_name) : data.value }} -->
            </span>

          </template>
          <template #cell(customer_name)="data">
            <!-- {{ data.item.user && (data.item.user.user_name +" "+ data.item.user.last_name) || data.item.id}} -->
            <template v-if="thirdPartyOrder && data.item.fa_order_address && data.item.fa_order_address.customer_name">
              {{ data.item.fa_order_address.customer_name}}<br />
              <small>{{
                thirdPartyOrder
                  ? data.item.fa_order_address.customer_contact_number
                  : data.item.customer_phone
              }}</small>
            </template>
            <template v-else>
              {{ data.value }}<br />
              <small>{{data.item.customer_phone}}</small>
            </template>
          </template>
          <template #cell(created_at)="data">
            {{ moment(data.value, "YYYY-MM-DD HH:mm:ss").fromNow() }}
            <span v-b-tooltip.hover.v-light :title="data.value" class="font-size-15"
              ><i class="bx bx-info-circle"></i
            ></span>
            <br v-if="data.item.order_status == 'ReadyToServe'" />
            <small v-if="data.item.order_status == 'ReadyToServe'">
              READY since {{ moment(data.item.readytoserve_date).fromNow() }}
            </small>
          </template>
          <template #cell(delivery_time)="data">
            <div v-if="data.item.eta == ''">
              <span>--</span>
            </div>
            <div v-else>
              <div
                class="align-items-center"
                v-if="data.item.eta && data.item.eta !== ''"
              >
                <span>
                  {{ moment(data.item.eta).format("MMM DD[,] YYYY hh:mm A") }}
                </span>
                <span
                  v-if="
                    data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                    !thirdPartyOrder
                  "
                  class="mdi mdi-calendar text-primary font-size-18 pl-1"
                ></span>
                <template
                  v-if="
                    data.item.order_status == 'ReadyToServe' ||
                    data.item.order_status == 'OnTheWay' ||
                    data.item.order_status == 'Arrived'
                  "
                >
                  <br v-if="moment().diff(moment(data.item.eta)) > 0" />
                  <small v-if="moment().diff(moment(data.item.eta)) > 0">
                    DELAYED by
                    {{
                      moment
                        .duration(moment().diff(moment(data.item.eta)))
                        .asMinutes()
                        .toFixed()
                    }}
                    minutes
                  </small>
                </template>
                <template v-if="data.item.order_status == 'Delivered'">
                  <br
                    v-if="
                      moment(data.item.delivered_date).diff(moment(data.item.eta)) > 0
                    "
                  />
                  <small
                    v-if="
                      moment(data.item.delivered_date).diff(moment(data.item.eta)) > 0
                    "
                  >
                    DELAYED by
                    {{
                      moment
                        .duration(
                          moment(data.item.delivered_date).diff(moment(data.item.eta))
                        )
                        .asMinutes()
                        .toFixed()
                    }}
                    minutes
                  </small>
                </template>
              </div>
              <div v-else>
                <span v-if="data.item.future_delivery_date != '0000-00-00 00:00:00'">
                  {{
                    moment(data.item.future_delivery_date).format(
                      "MMM DD[,] YYYY hh:mm A"
                    )
                  }}
                </span>
                <span v-else>
                  {{
                    moment(data.item.created_at)
                      .add(data.value, "minutes")
                      .format("HH:mm:ss")
                  }}
                </span>
                <template
                  v-if="
                    data.item.order_status == 'ReadyToServe' ||
                    data.item.order_status == 'OnTheWay' ||
                    data.item.order_status == 'Arrived'
                  "
                >
                  <br
                    v-if="
                      data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                      moment().diff(moment(data.item.future_delivery_date)) > 0
                    "
                  />
                  <br
                    v-else-if="
                      moment().diff(
                        moment(data.item.created_at).add(data.value, 'minutes')
                      ) > 0
                    "
                  />
                  <small
                    v-if="
                      data.item.future_delivery_date != '0000-00-00 00:00:00' &&
                      moment().diff(moment(data.item.future_delivery_date)) > 0
                    "
                  >
                    DELAYED by
                    {{
                      moment
                        .duration(moment().diff(moment(data.item.future_delivery_date)))
                        .asMinutes()
                    }}
                    minutes
                  </small>
                  <small
                    v-else-if="
                      moment().diff(
                        moment(data.item.created_at).add(data.value, 'minutes')
                      ) > 0
                    "
                  >
                    DELAYED by
                    {{
                      moment
                        .duration(
                          moment().diff(
                            moment(data.item.created_at).add(data.value, "minutes")
                          )
                        )
                        .asMinutes()
                        .toFixed(0)
                    }}
                    minutes
                  </small>
                </template>
              </div>
            </div>
            <!-- {{ moment(data.item.created_at).add(data.value,'minutes').format('LT') }} <span v-b-tooltip.hover.v-light :title="moment(data.item.created_at).add(data.value,'minutes').format('YYYY-MM-DD HH:mm:ss')" class="font-size-15"><i class="bx bx-info-circle"></i></span> -->
          </template>
          <template #cell(total_amount)="data">
            <span v-html="currency"></span>
            {{ parseFloat(data.value || 0).toFixed(2) }}
            <!-- {{(data.value || 0).toFixed(2) }} -->
          </template>
          <!-- <template #cell(delivery_pickup_types)="data">
        <span class="badge badge-pill badge-soft-primary font-size-12" v-if="data.value == 'Delivery'">
          {{trans.get('__JSON__.'+data.value)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="data.value == 'Takeaway'">
          {{trans.get('__JSON__.'+data.value)}}
        </span>
        <span class="badge badge-pill badge-soft-danger font-size-12" v-if="data.value == 'Pickup'">
          {{trans.get('__JSON__.'+data.value)}}
        </span>
      </template> -->
          <template #cell(payment_method)="data">
            <span
              class="badge badge-pill font-size-12"
              :class="{
                'badge-soft-success': data.value == 'Cash On Delivery',
                'badge-soft-warning': data.value.toLowerCase() == 'online',
                'badge-soft-info': data.value == 'Wallet',
              }"
            >
              {{ data.value == "Cash On Delivery" ? "COD" : data.value }}
            </span>
          </template>
          <template
            v-for="(col, index) in extraColumns"
            v-slot:[`cell(${col.key})`]="data"
          >
            <span v-if="col.key.indexOf('date') !== -1" :key="index">
              <span>{{ moment(data.value).fromNow() }}</span>
              <span v-b-tooltip.hover.v-light :title="data.value" class="font-size-15">
                <i class="bx bx-info-circle"></i>
              </span>
            </span>
            <div v-else-if="col.key == 'assign'" :key="index">
              <!-- (data.item.driver.length == 0 || (!['2','5','6','7','9'].includes(data.item.driver[0].order_status))) -->
              <span
                v-if="data.item.driver.length > 0"
                class="badge badge-pill font-size-12 badge-soft-success"
                >Assigned</span
              >
              <span
                v-else-if="data.item.dunzo_driver == '1'"
                class="badge badge-pill font-size-12 badge-soft-success"
                >DUNZO DRIVER</span
              >
              <span
                v-else-if="data.item.pidge_driver == '1'"
                class="badge badge-pill font-size-12 badge-soft-success"
                >PIDGE DRIVER</span
              >
              <OrderAssign :order="data.item" />
              <!-- <span v-else>-</span> -->
            </div>
            <span v-else-if="col.key == 'driver'" :key="index">
              {{
                data.item.dunzo_driver == "0" && data.item.pidge_driver == "0"
                  ? data.value
                  : data.item.dunzo_task && data.item.dunzo_task.runner
                  ? data.item.dunzo_task.runner.name
                  : data.item.pidge_task && data.item.pidge_task.runner
                  ? data.item.pidge_task.runner.name
                  : ""
              }}
              <span
                v-if="data.item.dunzo_driver == '1' && data.item.pidge_driver == '0'"
                class="badge badge-pill font-size-12 badge-soft-success"
                >DUNZO</span
              >
              <span
                v-if="data.item.pidge_driver == '1' && data.item.dunzo_driver == '0'"
                class="badge badge-pill font-size-12 badge-soft-success"
                >PIDGE</span
              >
              <small
                v-if="
                  data.item.dunzo_driver == '0' &&
                  data.item.pidge_driver == '0' &&
                  ['Arrived', 'OnTheWay', 'Delivered'].includes(data.item.order_status)
                "
                ><br />
                {{
                  (data.item.driver &&
                    data.item.driver.length > 0 &&
                    data.item.driver[0].contactno) ||
                  ""
                }}
              </small>
            </span>
          </template>
          <template #cell(action)="data">
            <OrderAction
              :order="data.item"
              :delay_order_btn="delay_order_btn"
              :not_response_btn="not_response_btn"
              @actionChanged="$emit('refreshData')"
              @actionClicked="actionClicked"
              :thirdPartyOrder="thirdPartyOrder"
            />
            <!-- <b-button variant="primary" class="btn-sm btn-rounded" @click="viewDetails(data.item.base_id)">{{trans.get('__JSON__.View')}}</b-button> -->
          </template>
        </b-table>
      </div>
    </div>

    <OrderShow
      v-if="showModal && vendor_order_id != null"
      :order-id="orderId"
      @hidden="showModal = false"
      :thirdPartyOrder="(thirdPartyOrder = true)"
    />
    <OrderShow
      v-else-if="showModal && vendor_order_id == null"
      :order-id="orderId"
      @hidden="showModal = false"
      :thirdPartyOrder="(thirdPartyOrder = false)"
    />
    <OrderCancel
      v-if="showCancel"
      :order-id="orderId"
      @hidden="showCancel = false"
      :cancel_order="cancel_order"
      :thirdPartyOrder="thirdPartyOrder"
      @actionChanged="$emit('refreshData')"
    />
    <OrderDunzo v-if="showDunzo" :order-id="orderId" @hidden="showDunzo = false" />
    <order-customer-notes
      v-if="showNote"
      :order-id="orderId"
      @hidden="showNote = false"
    />
    <!-- View Detail Main End -->
  </div>
</template>
<style>
.delayOrderColor, .delayOrderColor > td, .delayOrderColor:hover, .delayOrderColor > td:hover {
  background-color: #ffefef !important;
}

.delayTwoHours, .delayTwoHours > td, .delayTwoHours:hover, .delayTwoHours > td:hover {
  background-color: #f7d5d5 !important;
}
tr.delayOrderColor.table-active:hover td {
    background-image: none !important;
}
tr.delayTwoHours.table-active:hover td {
    background-image: none !important;
}
</style>
