<script>
import Layout from "../../layouts/main";
import categoryImageList from "./category-image-list.vue";

export default {
    components: { Layout, categoryImageList },
    data() {
        return {};
    }
};

</script>

<template>
    <Layout>
        <category-image-list />
    </Layout>
</template>
