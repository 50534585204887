import config from '../config'

export default {
    config: config,
}
const permissions = config.permissions;
const base = config.base_folder
export const menuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        badge: {
            'variant'   :  'danger',
            "text"      :   ['order','booking','zomatoOrderCount']
        },
        subItems: [
            {
                id: 2,
                label: "General",
                link: base+"dashboard",
                parentId: 1,
                required_permission: permissions.dashboard_read,
                permissions: [permissions.dashboard_read]
            },
            {
                id: 3,
                label: "Ordering",
                link: base+"orders/list",
                parentId: 1,
                required_permission: permissions.order_read,
                permissions: [permissions.order_read, permissions.order_write, permissions.order_export],
                badge: {
                    'variant'   :  'danger',
                    "text"      :   ['order']
                }
            },
            {
                id: 41,
                label: "Third Party Order",
                link: base+"thirdpartyorder/list",
                parentId: 1,
                required_permission: permissions.zomato_order_read,
                permissions: [permissions.zomato_order_read, permissions.zomato_order_write, permissions.zomato_order_export],
                badge: {
                    'variant'   :  'danger',
                    "text"      :   ['zomatoOrderCount']
                }
            },
            {
                id: 5,
                label: "Driver",
                link: base+"driver/dashboard",
                parentId: 1,
                required_permission: permissions.dashboard_driver_read,
                permissions: [permissions.dashboard_driver_read]
            },
            {
                id: 4,
                label: "Eagle View",
                link: base+"eagle-view/list",
                parentId: 1,
                required_permission: permissions.eagle_view_read,
                permissions: [permissions.eagle_view_read]
            },
            {
                id: 34,
                label: "Booking",
                link: base+"bookings/list",
                parentId: 1,
                required_permission: permissions.booking_read,
                permissions: [permissions.booking_read, permissions.booking_write, permissions.booking_export],
                badge: {
                    'variant'   :  'danger',
                    "text"      :  ['booking']
                }
            },
            {
                id: 37,
                label: "Chowman Pay",
                link: base+"chowman-pay/list",
                parentId: 1,
                required_permission: permissions.chowman_pay_read,
                permissions: [permissions.chowman_pay_read, permissions.chowman_pay_write, permissions.chowman_pay_export],
                // badge: {
                //     'variant'   :  'danger',
                //     "text"      :  ['chowman']
                // }
            }
        ]
    },
    {
        id: 6,
        label: "Management",
        icon: "bx-user",
        subItems: [
            {
                id: 7,
                label: "Vendors",
                link: base+"vendors",
                parentId: 6,
                required_permission: permissions.merchant_read,
                permissions: [permissions.merchant_read, permissions.merchant_write, permissions.merchant_export]
            },
            {
                id: 8,
                label: "Driver",
                link: base+"driver",
                parentId: 6,
                required_permission: permissions.driver_read,
                permissions: [permissions.driver_read, permissions.driver_write, permissions.driver_export]
            },
            {
                id: 9,
                label: "Customer",
                link: base+"customer",
                parentId: 6,
                required_permission: permissions.customer_read,
                permissions: [permissions.customer_read, permissions.customer_write, permissions.customer_export]
            }
        ]
    },
    {
        id: 14,
        label: "Locations",
        icon: "bx-map",
        subItems: [
            {
                id: 15,
                label: "Location",
                link: base+"location",
                parentId: 14,
                required_permission: permissions.location_read,
                permissions: [permissions.location_read,permissions.location_write]
            },
            {
                id: 16,
                label: "Delivery Zones",
                link: base+"delivery_zone",
                parentId: 14,
                required_permission: permissions.delivery_zone_read,
                permissions: [permissions.delivery_zone_read, permissions.delivery_zone_write, permissions.delivery_zone_export]
            }
        ]
    },
    {
        id: 29,
        label: "Promotions",
        icon: "bx-purchase-tag",
        subItems: [
            {
                id: 30,
                label: "Promocode",
                link: base+"coupon",
                parentId: 29,
                required_permission: permissions.promo_code_read,
                permissions: [permissions.promo_code_read,permissions.promo_code_write]
            },
            {
                id: 35,
                label: "Special Promocode",
                link: base+"coupon/special",
                parentId: 29,
                required_permission: permissions.promo_code_read,
                permissions: [permissions.promo_code_read,permissions.promo_code_write]
            },
            {
                id: 31,
                label: "Banners",
                link: base+"banner",
                parentId: 29,
                required_permission: permissions.banner_read,
                permissions: [permissions.banner_read,permissions.banner_write]
            },
            {
                id: 47,
                label: "Order Status Banner",
                link: base+"order-status-banner",
                parentId: 29,
                required_permission: permissions.order_banner_read,
                permissions: [permissions.order_banner_read,permissions.order_banner_write]
            },
            {
                id: 38,
                label: "Gift Vouchers",
                link: base+"gift-vouchers/list",
                parentId: 29,
                required_permission: permissions.gift_voucher_read,
                permissions: [permissions.gift_voucher_read,permissions.gift_voucher_write]
            },
            {
                id: 39,
                label: "Purchased Gift Vouchers",
                link: base+"gift-vouchers-purchased/list",
                parentId: 29,
                required_permission: permissions.gift_voucher_purchased_read,
                permissions: [permissions.gift_voucher_purchased_read,permissions.gift_voucher_purchased_write]
            },
            {
                id: 48,
                label: "Top Picks",
                link: base+"top-picks",
                parentId: 29,
                required_permission: permissions.top_picks_read,
                permissions: [permissions.top_picks_read,permissions.top_picks_write]
            },
        ]
    },
    /* {
        id: 31,
        label: "Wallet",
        icon: "bx-purchase-tag",
        subItems: [
            {
                id: 32,
                label: "Promocode",
                link: base+"coupon",
                parentId: 31,
                required_permission: permissions.promo_code_read,
                permissions: [permissions.promo_code_read,permissions.promo_code_write]
            }
        ]
    }, */
    {
        id: 10,
        label: "Financial Reports",
        icon: "bx-rupee",
        subItems: [
            {
                id: 11,
                label: "Vendors",
                link: base+"finance/vendor",
                parentId: 10,
                required_permission: permissions.vendor_finance_read,
                permissions: [permissions.vendor_finance_read, permissions.vendor_finance_write, permissions.vendor_finance_export]
            },
            {
                id: 12,
                label: "Drivers",
                link: base+"finance/driver",
                parentId: 10,
                required_permission: permissions.driver_finance_read,
                permissions: [permissions.driver_finance_read, permissions.driver_finance_write, permissions.driver_finance_export]
            },
            {
                id: 13,
                label: "Reports",
                link: base+"reports",
                parentId: 10,
                required_permission: permissions.reports_read,
                permissions: [permissions.reports_read, permissions.reports_write, permissions.reports_export]
            }
        ]
    },   
    {
        id: 17,
        label: "Contents",
        icon: "bx-home-circle",
        subItems: [
            {
                id: 18,
                label: "Business Category",
                link: base+"category",
                parentId: 17,
                required_permission: permissions.category_read,
                permissions: [permissions.category_read, permissions.category_write, permissions.category_export]

            },
            {
                id: 49,
                label: "Category Image",
                link: base+"category-image",
                parentId: 17,
                required_permission: permissions.category_image_read,
                permissions: [permissions.category_image_read, permissions.category_image_write, permissions.category_image_export]
            },
            {
                id: 19,
                label: "Cuisines",
                link: base+"cuisines",
                parentId: 17,
                required_permission: permissions.cuisine_read,
                permissions: [permissions.cuisine_read,permissions.cuisine_write]
            },
            {
                id: 36,
                label: "Item Tag",
                link: base+"tag",
                parentId: 17,
                required_permission: permissions.tag_read,
                permissions: [permissions.tag_read,permissions.tag_write]
            },
            {
                id: 20,
                label: "Pages",
                link: base+"content-page",
                parentId: 17,
                required_permission: permissions.content_pages_read,
                permissions: [permissions.content_pages_read,permissions.content_pages_write]
            },
            {
                id: 21,
                label: "FAQ",
                link: base+"faq",
                parentId: 17,
                required_permission: permissions.faq_read,
                permissions: [permissions.faq_read,permissions.faq_write]
            }
        ]
    },
    {
        id: 24,
        label: "Settings",
        icon: "bx-laptop",
        subItems: [
            {
                id: 25,
                label: "Configurations",
                link: base+"settings/configurations",
                parentId: 24,
                required_permission: permissions.configuration_read,
                permissions: [permissions.configuration_read,permissions.configuration_write]
            },
            {
                id: 26,
                label: "App Layout Settings",
                link: base+"settings/app-layout-setting",
                parentId: 24,
                required_permission: permissions.layout_read,
                permissions: [permissions.layout_read,permissions.layout_write]
            },
            {
                id: 27,
                label: "User & Access",
                link: base+"settings/user-access",
                parentId: 24,
                required_permission: permissions.user_access_read,
                permissions: [permissions.user_access_read,permissions.user_access_write]

            },
            {
                id: 42,
                label: "Web Configurations",
                link: base+"settings/web-layout-setting",
                parentId: 24,
                required_permission: permissions.web_layout_read,
                permissions: [permissions.web_layout_read,permissions.web_layout_write]
            },
            {
                id: 28,
                label: "Terminology",
                link: base+"settings/terminology",
                parentId: 24,
                required_permission: permissions.terminology_read,
                permissions: [permissions.terminology_read,permissions.terminology_write]
            },
           
        ]
    },
    {
        id: 22,
        label: "Others",
        icon: "bx-file",
        badge: {
            'variant'   :  'danger',
            "text"      :   ['chat']
        },
        subItems: [
            {
                id: 23,
                label: "Logs",
                link: base+"system/list",
                parentId: 22,
                required_permission: permissions.logs_read,
                permissions: [permissions.logs_read,permissions.logs_write]
            },
            {
                id: 29,
                label: "Chat",
                link: base+"chat/chat",
                parentId: 22,
                required_permission: permissions.configuration_read,
                permissions: [permissions.configuration_read,permissions.configuration_write],
                badge: {
                    'variant'   :  'danger',
                    "text"      :   ['chat']
                },
            },
            {
                id: 33,
                label: "Call Center",
                link: base+"call-center/dashboard",
                parentId: 22,
                required_permission: permissions.call_center_read,
                permissions: [permissions.call_center_read, permissions.call_center_write]
            },
            {
                id: 40,
                label: "Email Template",
                link: base+"mails",
                parentId: 22,
                required_permission: permissions.email_template_read,
                permissions: [permissions.email_template_read, permissions.email_template_write]
            },
        ]
    }
];
