<script>
import pagination from "laravel-vue-pagination";
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header.vue";
import { walletService } from '../../../services';
import { get_currency, get_decimal, success_message } from '../../../Helper/helper';
export default {
  components: { pagination, Layout, PageHeader },
  props:['customer_id','wallet_status'],
  data() {
    return {
      history: null,
      loading:false,
      mywallet: null,
      fields: [
        { label: "#", key: "recharge_id", sortable: false },
        { label: "Type", key: "type", sortable: false },
        { label: "Amount", key: "recharge_amount", sortable: false },
        { label: "Transaction", key: "transaction_id", sortable: false },
        { label: "Used for", key: "user_order_id", sortable: false },
        { label: "Time", key: "created_at", sortable: false },
      ],
      currency: get_currency(),
      decimal: get_decimal(),
      type: '',
      status: this.wallet_status
    };
  },
  mounted(){
      this.getHistory();
      this.getBalance();
  },
  methods: {
    getHistory(page = 1) {
        console.log(page)
      this.loading = true;
      walletService.getHistory({customer_id: this.customer_id, page: page,type:this.type }).then((response) => {
        this.loading = false;
        this.history = response.data.result;
      });
    },
    getBalance() {
      walletService.getBalance(this.customer_id).then((response) => {
        this.mywallet = response.data.result;
      });
    },
    changeWalletStatus(){
      walletService.changeWalletStatus({
        customer: this.customer_id,
        status: this.status
      }).then((response) => {
        if(response.data.result.updated){
          success_message(response.data.message)
        }
      })
    }
  },
};
</script>
<template>
  <div>
    <PageHeader :title="trans.get('__JSON__.Wallet History')" >
      <template #right>
        <div class="vendor-status">          
            <label class="switch">
                <input
                  type="checkbox"
                  id="togBtn"
                  name="status"
                  v-model="status"
                  class="switch-on"
                  true-value="active"
                  false-value="inactive"
                  @change="changeWalletStatus"
                />
                <div class="slider round">
                  <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                  <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                </div>
            </label>
        </div>
      </template>
    </PageHeader>
    <div class="row">
        <div class="col-3">
          <h3> <span v-html="currency"></span> {{parseFloat(mywallet && mywallet.wallet_balance || 0).toFixed(decimal)}}</h3>        
        </div>
        <div class="col-3 offset-6 mb-3">
            <select class="custom-select" v-model="type" @change="getHistory()">
                <option value="">{{trans.get('__JSON__.All')}}</option>
                <option value="recharge">{{trans.get('__JSON__.Recharge')}}</option>
                <option value="refund">{{trans.get('__JSON__.Refund')}}</option>
                <option value="spent">{{trans.get('__JSON__.Spent')}}</option>
            </select>
        </div>
        <div class="col-12 table-responsive">
                <!-- :sort-by.sync="sortBy" -->
                <!-- :sort-desc.sync="sortDesc" -->
                <!-- :no-local-sorting="true" -->
                <!-- @sort-changed="sortingChanged" -->
            <b-table
                :items="history && history.data || []"
                :fields="fields"
                :busy="loading"
                show-empty
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                </template>
                <template v-slot:cell(recharge_id)="data">                    
                    <span>{{ data.index + 1 + ((history.current_page-1) * history.per_page)  }}</span>
                </template>
                <template v-slot:cell(type)="data">
                    <span class="badge badge-pill font-size-12"
                    :class="{'badge-soft-success':(['recharge','cashback','referral'].includes(data.value)),'badge-soft-danger':(data.value == 'spent'),'badge-soft-info':(data.value == 'refund')}"
                    >{{data.value.toUpperCase()}}</span>
                </template>
                <template v-slot:cell(recharge_amount)="data">
                    <span v-html="currency"></span> {{parseFloat(data.value)}}
                </template>
                <template v-slot:cell(user_order_id)="data" >
                  {{ data.item.chowman_pay_id ? ('Pay#'+data.item.chowman_pay_id) : (data.value? ('Order#'+data.value) : (data.item.gift_purchase_id ? ('Gift#'+data.item.gift_purchase_id) : '-')) }}
                    <!-- {{data.value?('#'+data.value):'-'}} -->
                </template>
                <template v-slot:cell(created_at)="data">
                    {{moment(data.value).format('LLL')}}
                </template>
            </b-table>  
        </div>
        <div class="row col-sm-5 offset-sm-7" v-if="history">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <pagination
                    :data="history"
                    :limit="1"
                    @pagination-change-page="getHistory"
                    ></pagination>
                </ul>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
</style>