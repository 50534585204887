<script>
	import { FormWizard, TabContent } from "vue-form-wizard";

	import Layout from "../../layouts/main";
	import PageHeader from "../../components/page-header";
	import VueGoogleAutocomplete from 'vue-google-autocomplete';
	import { callCenterDashboardService, customizationService, promoCodeService } from "../../services";
	import DatePicker from "vue2-datepicker";
	import "vue2-datepicker/index.css";
	import MenuList from "./menu";
	import OrderSummary from "./order-summary"
	import { vendorService } from "../../services";
	import draggable from "vuedraggable";
	import pagination from "laravel-vue-pagination";
	import config from "../../config";
	import { required,numeric, minLength, } from "vuelidate/lib/validators";
	import { error_message,success_message,get_currency, get_decimal } from "../../Helper/helper";
	import Swal from "sweetalert2";
	import CustomerTab from './customer-tab.vue';
	import moment from 'moment';
	import { debounce } from './../../Helper/helper';
	/**
	 	* Form wizard component
	**/
	export default {
		components: { 
			CustomerTab,
			Layout, 
			PageHeader,
			VueFormWizard: FormWizard, 
			TabContent, VueGoogleAutocomplete, 
			MenuList, 
			OrderSummary, 
			draggable,
			pagination ,
			DatePicker
		},
		data() {
			return {
				minSelectionVal: [],
				center: { lat: 0.00, lng: 0.00 },
				marker: null,
				typeform:{
					address: '',
					latitude: '',
					longitude: '',
					address_clarification: 'Home',
					house_name: '',
					flat_no: '',
					landmark: '',
					city: '',
					state: '',
					zip: ''
				},
				searchShow:false,
				limit:2,
				mobile_number: '',
				name: '',
				restaurants: [],
				restaurants_id: "",
				category_count:'',
				category_id: '',
				category : [],
				menu_id: 1,
				menu: [],
				search: '',
				loading: true,
				selection: '',
				item_count: '',
				restaurant_menu_id: '',
				currency: get_currency(),
				decimal: 2,
				timer: '',
				config: config,
				addNewAddress: false,
				checksNumber: '',
				emails: '',
				user_id: '',
				user: null,
				customerAddress: [],
				addreses: '',
				menuModal: false,
				items: null,
				cart: [],
				selectedCustomisation: [],
				isPlaceOrder: false,
				custItemTotal:0,
				selected_item: null,
				special_instruction: '',
				service_fee: 0,
				// future_delivery_date: '0000-00-00 00:00:00',
				future_delivery_date : '',
				scheduled: false,
				restaurant: null,
				moment:moment,
				schedule_date: null,
				searchData:debounce(()=>{
				this.listitembyid(1);
				},500),
				promocode: '',
				promocodeDetail:null,
				promo_loading:false,
				promoApplied: false,
				address: null,
				cart_data: {},
				calculation:{},
				freebie_item: {},
				isVerified: false,
				isFreebieApplied: false,
				itemCouponApplied: false,
				cart_show: [],
				this_cart_verify: {},
			};
		},
		validations: {
			mobile_number: {
				required,
				numeric
			},
			typeform: {
				address: {
					required,
				},
				house_name: {
					required,
				},
				flat_no: {
					required,
					numeric,
				},
			},
		},
		created (){
			// this.getMenuCategories();
			// this.timer = setInterval(this.getMenuCategories, 20000);
			// this.getmenu();
			// this.timer = setInterval(this.getmenu, 20000);
		},
		mounted(){
			// 
		},
		methods:{
			disabledTime(date){
				console.log(date)
				let cdate = this.moment(date);  
				let start_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_start_time.split(' ')[1]);
				let end_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_end_time.split(' ')[1]);
				if(!cdate.isBetween(this.moment().startOf('day'),this.moment().add(3,'days').startOf('day'), undefined, '[]')){
					return true;
				}
				if(cdate.format('YYYY-MM-DD') == this.moment().format('YYYY-MM-DD')){
					return !cdate.isBetween(this.moment(),end_time, undefined, '[]');
				}
				return !cdate.isBetween(start_time,end_time, undefined, '[]');
			},
			disabledDate(date){
				let cdate = this.moment(date);
				// let start_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_start_time.split(' ')[1]);
				// let end_time = this.moment( cdate.format('YYYY-MM-DD')+' '+ this.$auth.setting.system_end_time.split(' ')[1]);
				if(!cdate.isBetween(this.moment().startOf('day'),this.moment().add(3,'days').startOf('day'), undefined, '[]')){
					return true;
				}
				return false;
			},
			cartQty(item){		
				if(this.cart) {
					return this.cart.reduce((ac,ct) => (item.restaurant_menu_item_id == ct.id?(ac + ct.qty ):ac),0)          
				}
				return 0;
			},
			total(){
				let total = 0;
				for (let item in this.cart) {
					total = total + this.cart[item].qty * this.cart[item].item_total
				}
				return total;
			},
			getSgst(){
				return parseFloat(this.$auth.setting.sgst? ((parseFloat(this.total() 
					- (this.promoApplied && this.promocodeDetail.discount || 0)
				) + parseFloat(this.getPackagingCharge())) * this.$auth.setting.sgst / 100) : 0).toFixed(this.decimal)
			},
			getCgst(){
				return parseFloat(this.$auth.setting.cgst? ((parseFloat(this.total() - (this.promoApplied && this.promocodeDetail.discount || 0)) + parseFloat(this.getPackagingCharge())) * this.$auth.setting.cgst / 100) : 0).toFixed(this.decimal)
			},
			getIgst(){
				return parseFloat(this.$auth.setting.igst? ((parseFloat(this.total() - (this.promoApplied && this.promocodeDetail.discount || 0)) + parseFloat(this.getPackagingCharge())) * this.$auth.setting.igst / 100) : 0).toFixed(this.decimal)
			},
			getPackagingCharge(){
				return (this.cart.reduce((a,ca) => (( ca.multiple_selection_new && ca.multiple_selection_new.length > 0 ? 0 : ca.packaging_charge * ca.qty) + ((ca.multiple_selection_new && ca.multiple_selection_new.reduce((a, m)=> (m.type == 'addon' ? ca.packaging_charge : m.packaging_charge) + a ,0) || 0) * ca.qty) + a), 0)).toFixed(this.decimal);
			},
			originalPrice(){
				let total = this.total()
				return parseFloat(parseFloat(total) + (parseFloat(this.getSgst()) + parseFloat(this.getCgst())) + parseFloat(this.getPackagingCharge()) ).toFixed(this.decimal); 
			},
			totalAmount(){
				let originalPrice = this.originalPrice();
				return originalPrice;
			},  
			discountAmount(){
				if(!this.promoApplied){
					return 0;
				}
				let originalPrice = this.originalPrice();
				return (originalPrice - this.promocodeDetail.discount);
			},
			getWalletAmount(){
				return 0;
			},
			removeItem(id){
				this.cart.splice(id,1);
				if(this.promoApplied){
					this.removePromocode()
				}

				this.VerifyCart();
			},
			// addCart(item,id) {
			//   if(this.promoApplied){
			//     this.removePromocode()
			//   }
			//   let c = this.cart.find(ct => ct.item_id == item.restaurant_menu_item_id);
			//   if(!c){
			//     item.item_total = item.price;
			//     const { restaurant_menu_item_id,item_name, packaging_charge, price, mrp, item_total } = item;
			//     let cartItem = { item_id: restaurant_menu_item_id, packaging_charge, amount: price, qty:1, item_name, mrp,item_total };
			//     this.cart.push({...cartItem});
			//   }else{
			//     c.qty = c.qty + 1;
			//     c.total = c.qty * c.item_total;
			//     let cart = this.cart.filter(ct => ct.item_id != item.restaurant_menu_item_id);
			//     c.amount = c.qty * c.item_total;
			//     c.packaging_charge = item.packaging_charge;
			//     this.cart = [ 
			//       ...cart,{...c}
			//     ];
			//   }
			// },
			addCart(item,isfreebie = null) {
				if(this.promoApplied){
					this.removePromocode()
				}
				console.log("Add Cart",this.cart, item, isfreebie);
				
				let c = this.cart.find(ct => ct.id == item.restaurant_menu_item_id);
				if(!c){
					const { restaurant_menu_item_id,item_name, packaging_charge, price, mrp, item_total, qty } = item;
					let cartItem = { 
						id: restaurant_menu_item_id, 
						cart_id: this.generateUUID(), 
						qty: 1, 
						promocode_id: item.item_coupon && item.item_coupon.coupon ? item.item_coupon.coupon.id :"",
						customisation: null,
						item_name
					};
					this.cart.push({...cartItem});
					
				}else{
					
					
					c.qty = c.qty + 1;
					let cart = this.cart.filter(ct => ct.id != item.restaurant_menu_item_id);
					this.cart = [ 
					...cart,{...c}
					];

					console.log("Show in cart else:" , c);
				}

				if(isfreebie)
				{
					this.VerifyCart(isfreebie);
				}
			},
			lessCart(id) {
				if(this.promoApplied){
					this.removePromocode()
				}
				let c = this.cart.findIndex(ct => ct.id == id);
				if(c >= 0){
					if(this.cart[c].qty > 1) {
						this.cart[c].qty--;
					}else{
						this.cart.splice(c,1);
					}
				}
			},
			deliveryFee(){
				return 10;
			},
			serviceFee(){
				return this.service_fee;
			},
			mrpPrice(item){
				return parseFloat((parseInt(item.qty) * this.getSelectionPrices(item.multiple_selection_new)) + (parseInt(item.qty) * parseFloat(item.mrp || 0)))
			},
			totalPrice(item){
				// return parseFloat((parseInt(item.qty) * this.getSelectionPrices(item.multiple_selection_new)) + (parseInt(item.qty) * parseFloat(item.item_total || 0)))
				return (parseInt(item.qty) * parseFloat(item.item_total || 0));
			},
			getSelections(item){
				console.log(item);
				
				return item && item.length > 0 ? item.reduce((accu, item) => {
					return accu.concat(item.name +' x '+ parseFloat(item.price).toFixed(this.decimal));
				},[]).join(', '): '';
			},
			getSelectionPrices(items){
				return parseFloat(items && items.data.length > 0 ? items.data.reduce(function(accu, item){
					return accu + parseFloat(item.price);
				},0): 0);
			},
			locateAddress(lat, lng) {
				const geocoder = new window.google.maps.Geocoder();
				geocoder.geocode({ location: { lat, lng } }, (results, status) => {
					if (status === "OK") {
					this.typeform.address = results[0].formatted_address;
					// this.address = results[0].formatted_address;
					var value = this.typeform.address.split(", ");
					var value1 = this.typeform.address.split(" ");
					var count = value.length;
					var count1 = value1.length;
					this.typeform.state = value1[count1 - 3];
					this.typeform.city = value[count - 3];
					this.typeform.zip = value1[count1 - 2];
					this.typeform.latitude = this.marker.position.lat;
					this.typeform.longitude = this.marker.position.lng;
					}
				});
			},
			userCreate(){
				callCenterDashboardService.userCreate({
					user_details: this.user,
					address: this.address
				}).then(response => {
					if(response.data.result){
						this.user_id = response.data.result.user_id 
						if(this.address && this.address){
							this.address.id = response.data.result.address_id || ''
						}
						this.placeOrderApi()
					}
				})
			},
			createOrder(){
				if (this.user_id) { 
					this.placeOrderApi();
				} else {
					this.userCreate();
				}
			},
			// placeOrderApi()
			// {
			// 	this.isPlaceOrder = true;
			// 	if(!this.cart || this.cart.length == 0){
			// 		alert('Please add item in cart');
			// 		return false;
			// 	}
			// 	let cart = JSON.parse(JSON.stringify(this.cart));
			// 	cart.forEach((c,index) => {
			// 		cart[index].multiple_selection_new = JSON.stringify(cart[index].multiple_selection_new);
			// 		cart[index].quantity = JSON.stringify(cart[index].qty);
			// 	})
			// 	const fd = new FormData();
			// 	fd.append('restaurant_service_fee',0);
			// 	fd.append('original_price', this.originalPrice());
			// 	fd.append('restaurant_id', this.restaurants_id);
			// 	fd.append('device_type', 'CALL-CENTER');
			// 	fd.append('is_langauge', 'en');
			// 	fd.append('contact_less_delivery', '0');
			// 	fd.append('delivery_fee', '0');
			// 	fd.append('delivery_pickup_types', this.user.delivery_type);
			// 	fd.append('delivery_type', '0');
			// 	fd.append('SGST', this.getSgst());
			// 	fd.append('sgst_percentage', this.$auth.setting.sgst);
			// 	fd.append('CGST', this.getCgst());
			// 	fd.append('cgst_percentage', this.$auth.setting.cgst);
			// 	fd.append('IGST', this.getIgst());
			// 	fd.append('igst_percentage', this.$auth.setting.igst);
			// 	fd.append('payment_method', 'Cash On Delivery');
			// 	fd.append('braveges_amount','0');
			// 	fd.append('address_id',(this.user.delivery_type == 'Delivery'?this.address.id:''));
			// 	fd.append('user_id',this.user_id);
			// 	fd.append('driver_tip','0');
			// 	fd.append('wallet_amount',this.getWalletAmount());
			// 	fd.append('total_amount',parseFloat(this.promoApplied ? this.discountAmount() : this.totalAmount() || 0));
			// 	fd.append('vendor_id',this.$auth.getVendorId());
			// 	fd.append('user_access_id',this.$auth.user.vendor_id);
			// 	fd.append('service_fee',"0");
			// 	fd.append('sub_total',this.total());
			// 	fd.append('notes',this.special_instruction?this.special_instruction:'');
			// 	fd.append('suggestion','');
			// 	fd.append('total_packaging_charge',this.getPackagingCharge());
			// 	fd.append('future_delivery_date',this.future_delivery_date);
			// 	fd.append('restaurant_item_details',JSON.stringify(cart));
			// 	if(this.promoApplied){
			// 		fd.append('coupon_id',this.promocodeDetail.coupon_id);
			// 		fd.append('discount_price',this.discountAmount());
			// 		fd.append('cashback',this.promocodeDetail.cashback);
			// 	}
			// 	callCenterDashboardService.placeOrderApi(fd).then((response)=>{
			// 		this.isPlaceOrder = false;
			// 		if(response.data.code == 200){
			// 			Swal.fire(response.data.msg, "You order no # "+ response.data.Result +"", "success")
			// 				.then((result) => {
			// 					this.$router.push({name:'orders-list'})
			// 				});
			// 			this.restaurants_id = '';
			// 			this.mobile_number = '';
			// 			this.name = '';
			// 			this.emails = '';
			// 			this.customerAddress = '';
			// 			this.cart = [];
			// 			// Swal.fire(response.data.message,"success");
			// 			// success_message(response.data.message);
			// 		}else{
			// 			Swal.fire("Error", response.data.msg, "error");
			// 		}
			// 	})
			// },
			
			addressChanged(address){
				this.address = address;
			},
			getRestaurant(restaurants){
				this.restaurants = restaurants;
			},
			changeItem(restaurant_id) {
				this.restaurants_id = restaurant_id;
				this.selected = "id:, target.value: " + this.restaurants_id;
				
				let services_fee = this.$auth.setting.restaurant_service_tax;
				this.restaurant =  this.restaurants.find(i => i.restaurant_id == this.restaurants_id);
				if(services_fee == '0'){
					//  let options = event.target.options
					//  if (options.selectedIndex > -1) {
					//  let services_fee = options[options.selectedIndex].getAttribute('service_fee');
					this.service_fee = this.restaurant && this.restaurant.service_fee || 0;
					//  }
				}
				this.getmenu()
				this.cart=[]
				this.cart_data=[]
			},
			changeMenu(id){
				this.menu_id = id;
				this.restaurant_menu_id = id;
				this.listitembyid(1);
			},
			listitembyid(page=1) {
				this.loading = true;
				vendorService.getItemByMenuID({ 
					menu_id: this.menu_id,
					search: this.search,
					page: page && page > 0 ? page : 1,
					restaurant_id: btoa(this.restaurants_id),
					category_id : this.category_id
				}).then((response) => {
					this.loading = false;
					this.item_count = response.data.result.get_menus_item.data.length;
					this.items = response.data.result.get_menus_item;
					let currentthis = this;
					this.items.data.map(function(x) {
						console.log(x);
						
						
						if(x.item_coupon && x.item_coupon.coupon && x.item_coupon.coupon.is_flat_discount == 7)
						{
							currentthis.freebie_item = x;
							console.log(currentthis.freebie_item);
						}
						
						x.qty = 0;
						return x;
					});
					this.menu = response.data.result.get_restaurant_menu;
					if(response.data.result.get_restaurant_menu.length > 0 && !response.data.result.get_restaurant_menu.some(m => m.id == this.menu_id && m.item_count > 0)){
						let menudata = this.menu.find(m => m.item_count > 0);
						this.menu_id = menudata.id;
						this.restaurant_menu_id = menudata.id;
						this.listitembyid(1);
					}
				});
			},
			getmenu() {
				vendorService
				.getRestaurantMenu({ restaurant_id: btoa(this.restaurants_id), category_id : this.category_id })
				.then((response) => {
					this.menu = response.data.result;
					if( response.data.result.length > 0){
						this.menu_id = this.menu[0].id;
						this.restaurant_menu_id = this.menu[0].id;
						this.listitembyid();
					}else{
						this.menu_id = '';
						this.listitembyid();
					}
				});
			},
			show(item, isfreebie) {
				// // this.custModal == true;
				// var show = document.getElementById(id);
				// // show.style.display = "block";
				// this.$bvModal.show(show);
				console.log('@show', item)
				if(item.customization.length == 0){
					this.addCart(item, isfreebie);
				}else{
					
					this.selected_item = {
						...item,
						qty: 1
					};
					this.selected_item.customization.forEach((item,index) => {
						this.selected_item.customization[index].selectedCustomisation = this.selected_item.customization[index].is_selection > 1?[]: null
					});
					
					setTimeout(()=>{
						this.$bvModal.show('popup_item');
					},300)
				}
			},
			hide(event, id, item, isfreebie = false) {
				// if(!item.customization.every(itc => itc.selectedCustomisation !== null 
				// 	&& (itc.is_selection > 1 
				// 	? itc.selectedCustomisation.length >= itc.is_min_selection 
				// 		&& itc.selectedCustomisation.length <= parseInt(itc.is_selection) 
				// 	: [itc.selectedCustomisation].length == itc.is_selection)
				// 	)
				// ){
				// 	alert('Please select customization as per min max selection');
				// 	return false;
				// }

				if (!item.customization.every(itc => 
					itc.is_min_selection === 0 || // Allow skipping validation if no minimum is required
					(itc.selectedCustomisation !== null && 
						(itc.is_selection > 1 
							? itc.selectedCustomisation.length >= itc.is_min_selection && 
							itc.selectedCustomisation.length <= parseInt(itc.is_selection)
							: [itc.selectedCustomisation].length == itc.is_selection)
					)
				)) {
					alert('Please select customization as per min max selection');
					return false;
				}

				this.minSelectionVal = [];
				var cart_id = item.restaurant_menu_item_id;
				const { restaurant_menu_item_id, item_name, packaging_charge, price,mrp,item_total,qty } = item;

				var cartItem = { 
					id: restaurant_menu_item_id, 
					cart_id: this.generateUUID(), 
					qty: qty, 
					promocode_id: item.item_coupon && item.item_coupon.coupon ? item.item_coupon.coupon.id :"",
					item_name
				};

				cartItem.customisation = []
				this.selected = []

				let temp = {};
				item.customization.map(cu => {
					if(Array.isArray(cu.selectedCustomisation))
					{
						// Handle case where `selectedCustomisation` is an array.
						const selectedItems = cu.selectedCustomisation.map(p => {
							return {
								id: p.menu_item_selection_id,
								name: p.selection_name
							};
						});

						if (selectedItems.length > 0) {
							temp = {
								id: cu.customize_type_id,
								selected: selectedItems
							};
							cartItem.customisation.push(temp);
						}
					}
					else
					{
						// Handle case where `selectedCustomisation` is an object.
						if (cu.selectedCustomisation) {
							temp = {
								id: cu.customize_type_id,
								selected: [{
									id: cu.selectedCustomisation.menu_item_selection_id,
									name: cu.selectedCustomisation.selection_name // Include name if required.
								}]
							};
							cartItem.customisation.push(temp);
							// cart_id = cart_id + "." + cu.selectedCustomisation.menu_item_selection_id; // Concatenate cart_id.
						}
						// if(cu.selectedCustomisation){
						// 	temp = {
						// 		id: cu.customize_type_id,
						// 		selected: [{
						// 			id : cu.selectedCustomisation.menu_item_selection_id,
						// 			name : cu.selectedCustomisation.selection_name,
						// 		}]
						// 	}
						// 	cartItem.customisation.push(temp);
						// 	cart_id = cart_id+"."+cu.selectedCustomisation.menu_item_selection_id; 
						// }
					}
				});
				
				if (cartItem.customisation.length === 0) {
					cartItem.customisation = null;
				}

				let cIndex = this.cart.findIndex(c => c.cart_id == cart_id);
				if(cIndex > -1){
					this.cart[cIndex].qty++; 
				} else {
					// cartItem.qty = 1
					this.cart = [...this.cart,cartItem];
				}

				// this.cart_show = [...this.cart];
				if(this.isfreebie)
				{
					this.VerifyCart();
				}
				
				console.log(this.cart);
				
				this.$bvModal.hide(id);
				this.selected_item = null;
			},
			scheduledOrder(){
				console.log(this.scheduled);
				
				// if(this.scheduled){
					// this.future_delivery_date = '0000-00-00 00:00:00';
					this.future_delivery_date = '';
				// }
				// console.log(this.future_delivery_date);
				
			},
			getMenuCategories() {
				vendorService
					.getMenuCategories({ restaurant_id: this.restaurants_id })
					.then((response) => {
						this.category_count = response.data.result.length;
						this.category = response.data.result;
					});
			},
			beforeDestroy () {
				clearInterval(this.timer);
			},
			getUser(user){
				this.user_id = user.user_id;
				this.user = user;
			},
			formatDate(){
				this.future_delivery_date = this.schedule_date ? this.moment(this.schedule_date).format('YYYY-MM-DD HH:mm:ss') : ""
			},
			validateStep(name) {
				var refToValidate = this.$refs[name];
				return refToValidate.validate();
			},
			removePromocode(itemCouponApplied = false){
				this.promoApplied = false;
				this.promocode = '';
				this.promocodeDetail = null;
				
				if(itemCouponApplied)
				{
					this.cart.forEach(cartData => 
					{
						const matchedFromAll = this.items.data.find(item => item.restaurant_menu_item_id === cartData.id);
						if(matchedFromAll)
						{
							if(matchedFromAll.item_coupon?.coupon?.is_flat_discount != 7)
							{
								cartData.promocode_id = '';
							}
						}
					});
					
					console.log("Removed", this.cart, this.items.data);
				}

				this.VerifyCart(this.promocodeDetail);
				error_message('Coupon Removed.');
			},
			custQty(sign){
				let qty = this.selected_item.qty;
				if(sign == '+'){
					qty++;
				}else{
					if(this.selected_item.qty != 0){
					qty--;
					}
				}
				this.selected_item = {
					...this.selected_item,
					qty
				}

			},
			applyPromoCode(){
				if(this.promoApplied || this.itemCouponApplied){
					this.removePromocode(this.itemCouponApplied)
					return false;
				}
				let postData = {
					is_language : this.$auth.setting.primary_lang,
					vendor_id : this.$auth.getVendorId(),
					user_id : this.user_id,
					restaurant_id : this.restaurant.restaurant_id,
					coupon_code : this.promocode,
					original_price : this.calculation ? this.calculation.total : 0, //this.totalAmount(),
				}
				this.promoApplied = false;
				this.promo_loading = true;
				promoCodeService.applyPromoCode(postData)
					.then((response)=>{
					this.promo_loading = false;
					if(response.data.code == 200){
						const { id, coupon_code, is_flat_discount, flat_discount, discount_percentage,maximum_discount_amount,cashback,maximum_cashback_amount } = response.data.Result;
						this.promocodeDetail = { coupon_id: id, coupon_code, is_flat_discount,  discount_percentage, flat_discount,maximum_discount_amount,maximum_cashback_amount };
						
						let sub_total = this.calculation ? this.calculation.subtotal : 0;
						var discounted_amount = 0
						var cashback_amount = 0
						
						if(is_flat_discount == 0){
							discounted_amount = (sub_total * discount_percentage / 100);
							discounted_amount = maximum_discount_amount > discounted_amount ? discounted_amount : maximum_discount_amount;
						}
						else if(is_flat_discount == 1){
							discounted_amount = (flat_discount);
						}
						else if(is_flat_discount == 2){
							discounted_amount = (sub_total * cashback / 100);
							discounted_amount = maximum_discount_amount > discounted_amount ? discounted_amount : maximum_discount_amount;
						}
						else if(is_flat_discount == 3){
							// let discounted_amount_total = (sub_total * (discount_percentage+cashback) / 100);
							// let discounted_amount_temp = maximum_discount_amount > discounted_amount_total ? discounted_amount_total : maximum_discount_amount;

							// discounted_amount = discounted_amount_temp * discount_percentage / (discount_percentage+cashback)
							// cashback_amount = discounted_amount_temp * cashback / (discount_percentage+cashback)

							let discounted_amount_total = (sub_total * discount_percentage / 100);
							let cashback_amount_total = (sub_total * cashback / 100);
							discounted_amount = discounted_amount_total > maximum_discount_amount ? maximum_discount_amount : discounted_amount_total;
							cashback_amount = cashback_amount_total > maximum_cashback_amount ? maximum_cashback_amount : cashback_amount_total;
						}
						else if(is_flat_discount == 4){
							// let discounted_amount_temp = (flat_discount);
							// discounted_amount = discounted_amount_temp * cashback / 100;
							// cashback_amount = discounted_amount_temp - discounted_amount;

							// new logic
							discounted_amount = (flat_discount);
							let cashback_amount_total = (sub_total * cashback / 100);
							cashback_amount = cashback_amount_total > maximum_cashback_amount ? maximum_cashback_amount : cashback_amount_total;
						}
						this.promocodeDetail.discount = parseFloat(discounted_amount);
						this.promocodeDetail.cashback = parseFloat(cashback_amount);
						success_message('Coupon Applied');
						
						this.itemCouponApplied = false;
						
						this.promoApplied = true;
						if(this.promoApplied)
						{
							this.VerifyCart(this.promocodeDetail);
						}
						return false;
					}
					this.$refs.promocode.focus();
					error_message('Invalid coupon code');
				})

			},
			tabChanged(prevIndex,nextIndex){
				this.searchShow = false;
				if(nextIndex == 1){
					this.searchShow = true;
				}
				if(nextIndex == 2){
					setTimeout(() => this.$refs.promocode.focus(), 500);
				}
			},
			VerifyCart()
			{
				// if(!this.cart || this.cart.length == 0){
				// 	alert('Please add item in cart');
				// 	return false;
				// }
				console.log("Verify", this.cart);

				this.this_cart_verify = JSON.parse(JSON.stringify(this.cart)); // Deep copy

				this.this_cart_verify.forEach(item_cart_data => {
					delete item_cart_data.item_name; // Modify only the copy
				});

				let cartData = JSON.parse(JSON.stringify(this.this_cart_verify));
				this.isVerified = false;
				console.log("VerifyCart",cartData);
				
				var data = {
					vendor_id: this.$auth.getVendorId(),
					customer: { id: this.user_id },
					restaurant: { 
						id: this.restaurants_id 
					},
					address: { id: this.user.delivery_type == 'Delivery' ? this.address.id : '' },
					delivery_charge: this.user.delivery_type == 'Delivery' ? this.delivery_charge : "",
					promocode: { id: this.promocodeDetail ? this.promocodeDetail.coupon_id : "" },
					order_type: this.user.delivery_type == 'Delivery' ? 1 : 0,
					items: cartData,
				};
				
				callCenterDashboardService.VerifyOrderApi(data).then((response)=> 
				{
					if(response.data.code == 200)
					{
						this.verify_response = response.data.Result;
						this.cart_data = response.data.Result.items;
						this.calculation = response.data.Result.calculation;
						this.delivery_charge = response.data.Result.calculation.delivery_charge;
						this.promocode_data = response.data.Result.promocode;
						
						this.isFreebieApplied = false;
						
						this.itemCouponApplied = false;
						if(this.calculation.item_discount_excluding_freebie != '' && this.calculation.item_discount_excluding_freebie > 0)
						{
							this.itemCouponApplied = true;
						}

						this.cart_data = this.cart_data.filter(element => !(element.qty == 0 && element.total_price == 0));
						console.log("Done", this.cart_data);
						
						this.cart_data.forEach((updatedCart, index) => 
						{
							const matchingCart = this.cart.find(cartItem => cartItem.id === updatedCart.id);
							if (matchingCart) {
								updatedCart.item_name = matchingCart.item_name;

								if(matchingCart.customisation)
								{
									updatedCart.customisation = matchingCart.customisation;
								}
							}

							console.log(updatedCart);
							
							const matchingItem = this.items.data.find(item => item.restaurant_menu_item_id === updatedCart.id);
							if (matchingItem) 
							{
								if(matchingItem.item_coupon?.coupon?.is_flat_discount == 7)
								{
									this.isFreebieApplied = true;
								}
							}
						});

						this.isVerified = true;
					}
				});
			},
			placeOrderApi()
			{
				this.isPlaceOrder = true;
				if(!this.cart_data || this.cart_data.length == 0){
					alert('Please add item in cart');
					return false;
				}
				// this.cart_data_copy = this.cart_data;
				// console.log(this.cart_data_copy, this.cart_data);

				// this.cart_data_copy = JSON.parse(JSON.stringify(this.cart_data)); // Deep copy

				// this.cart_data_copy.forEach(item_cart_data => {
				// 	const matchingCart = this.cart.find(cartItem => cartItem.id === item_cart_data.id);
				// 	if (matchingCart) {
				// 		item_cart_data.customisation = matchingCart.customisation;
				// 	}
				// 	item_cart_data.total = item_cart_data.total_price;
					
				// });

				this.cart_data_copy = this.cart_data.map(item_cart_data => {
					const matchingCart = this.cart.find(cartItem => cartItem.id === item_cart_data.id);

					return {
						...item_cart_data,
						customisation: matchingCart ? matchingCart.customisation : item_cart_data.customisation,
						total: item_cart_data.total_price,
						item_name: undefined, // Effectively deletes `item_name`
						actual_price: undefined,
						total_price: undefined,
						unit_price: undefined
					};
				});
				
				var place_request = {
					vendor_id: this.$auth.getVendorId(),
					customer: { 
						id: this.user_id 
					},
					restaurant: { 
						id: this.restaurants_id 
					},
					address: { 
						id: this.user.delivery_type == 'Delivery' ? this.address.id : '' 
					},
					user_access_id: this.$auth.user.vendor_id,
					// delivery_charge: this.user.delivery_type == 'Delivery' ? this.calculation?.delivery_charge : "",
					order_type: this.user.delivery_type == 'Delivery' ? 1 : 0,
					order_time: this.future_delivery_date ? this.future_delivery_date : "",
					note: this.special_instruction ? this.special_instruction : "",
					delivery_instruction : [],
					calculation : this.calculation,
					promocode : this.promocode_data,
					items: this.cart_data_copy,
					payment: {
						cod_amount: this.calculation.total ? this.calculation.total : 0,
						wallet_amount: "0",
						onine_amount: "0",
						payment_type: "1"
					},
					device: {
						type: "CALL-CENTER",
					}
				};
				
				console.log("Request:", place_request);
				
				callCenterDashboardService.placeOrderApi(place_request).then((response) =>
				{
					this.isPlaceOrder = false;
					if(response.data.code == 200) 
					{
						Swal.fire(response.data.msg, "You order no # "+ response.data.Result.order_id +"", "success")
							.then((result) => {
								this.$router.push({name:'orders-list'})
						});
					}
					else
					{
						Swal.fire("Error", response.data.msg, "error");
					}
				})
			},
			// getCustomization(verifiedCustomizations, itemcustomization, item)
			// {
			// 	let temp = {};
			// 	var c_data = verifiedCustomizations.forEach(element => {
			// 		itemcustomization.forEach(stored => {
			// 			if(verifiedCustomizations.id == itemcustomization.customize_type_id)
			// 			{
			// 				if(element.selected)
			// 				{
			// 					element.selected.forEach(element1 => {
			// 						if(Array.isArray(stored.selectedCustomisation))
			// 						{
			// 							temp = stored.selectedCustomisation.map(p => 
			// 							{
			// 								return p.selection_name;
			// 							});
			// 							element1.name = temp;
			// 						}
			// 						else
			// 						{
			// 							if(stored.selectedCustomisation){
			// 								temp = stored.selectedCustomisation.selection_name;
			// 								element1.name = temp;
			// 							}
			// 						}									
			// 					});
			// 				}
			// 			}
			// 		});
			// 	});

			// 	return c_data;
			// },
			//Generate UUID Without any package
			generateUUID() 
			{ 
				var d = new Date().getTime();
				var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
				return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
					var r = Math.random() * 16;
					if(d > 0){
						r = (d + r)%16 | 0;
						d = Math.floor(d/16);
					} else {
						r = (d2 + r)%16 | 0;
						d2 = Math.floor(d2/16);
					}
					return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
				});
			}
		},
	};
</script>
<template>
	<Layout>
		<div>
			<div class="align-items-center d-flex justify-content-between " v-if="searchShow" style="
				z-index: 9999;
				position: fixed;
				width: calc(50% - 350px);
				top: 88px;
				left: 44%;
			">
				<div class="d-inline-block search-box"  style="width:100%">
					<div class="position-relative">
					<input type="text" @keyup="searchData()" class="form-control" placeholder="Search" id="search1" v-model="search" >
					<i class="bx bx-search-alt search-icon"></i>
					</div>
				</div>

			</div>

			<div class="row">
				<div class="col-12">
					<div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
						<div class="page-title-left">
							<div class="avatar-xs mr-3">
							<span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
								<i class="bx bx-phone-call"></i>
							</span>
							</div>
							<h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.Call Center Dashboard') }}</h4>
						</div>
					</div>
				</div>
			</div>

			<!-- end row -->
			<div class="row">
				<div class="col-xl-12">
					<div class="card">
						<div class="card-body">
							<vue-form-wizard shape="tab" color="#556ee6" ref="wizard" @on-change="tabChanged">
								<!-- Cutomer Detail Tab Start -->
								<tab-content icon="mdi mdi-account-circle" title="Customer Details" :before-change="()=>validateStep('customerTab')">
									<customer-tab ref="customerTab" @onGetUser="getUser" @onRestaurantSelect="changeItem" @onGetRestaurant="getRestaurant" @addressChanged="addressChanged"></customer-tab>
								</tab-content>
								<!-- Cutomer Detail Tab End -->

								<button slot="next" class="finish-order-btn" v-if="!searchShow">{{ trans.get('__JSON__.Next') }}</button>
								<button slot="next" class="finish-order-btn" v-if="searchShow" @click="VerifyCart">{{ trans.get('__JSON__.Next') }}</button>

								<!-- Menu Details Tab Start -->
								<tab-content icon="mdi mdi-food" title="Menu">
									<div class="row">
										<div class="col-12">
											<div class="drag-container">
												<div class="row drag-list menu-left-main">
													<div class="col-lg-3 col-sm-6 drag-column menu-box-main">
														<b-list-group>
															<b-list-group-item key="0">
																<p class="h5">Menu</p>
															</b-list-group-item>       
															<template v-for="menus in menu" >
																<b-list-group-item 
																	class="d-flex justify-content-between align-items-center"
																	:key="menus.id"
																	:active="menu_id === menus.id"
																	v-on:click="changeMenu(menus.id)"
																	v-if="(search !== '' && menus.item_count !== 0) || (search == '')">
																	{{menus.menu_name}} <b-badge variant="primary" pill>{{menus.item_count}}</b-badge>
																</b-list-group-item>
															</template>
														</b-list-group>
													</div>

													<div class="col-lg-5 col-sm-6 drag-column">
														<b-list-group>
															<b-list-group-item key="0" class="align-items-center d-flex ">
																<span class="font-size-15 font-weight-medium">Items</span>
															</b-list-group-item>
															<template v-if="items && items.total != 0 && !loading">
																<template v-for="(item) in items && items.data || []" >
																	<b-list-group-item :key="item.restaurant_menu_item_id" v-if="(!item.item_coupon || !item.item_coupon.coupon) || (item.item_coupon && item.item_coupon.coupon && item.item_coupon.coupon.is_flat_discount != 7)">
																		<div class="d-flex justify-content-between">
																			<div class="d-flex">
																				<div>
																					<div v-if="item.item_type == '0'" class="position-static veg"><span></span></div>
																					<div v-if="item.item_type == '1'" class="position-static non-veg"><span></span></div>
																				</div>
																				<div>
																					<div class="d-flex">
																						<span class="font-size-13 font-weight-medium pl-1" :title="item.item_name">{{item.item_name | truncate(35, '...')}} </span>
																						<span v-b-tooltip.hover.v-light :title="item.item_description" >
																							<i class="bx bx-info-circle"></i>
																						</span>
																						<div>
																							<span v-if="item.customization.length != 0" class="badge badge-pill font-size-12 ml-2 badge-soft-primary">Customizable</span>
																						</div>
																					</div>
																					<span v-if="item.price != 0" class="font-size-11 pl-1 text-secondary">
																						Qty : {{item.quantity}} | <span v-html="currency"></span>
																						<span v-if="item.item_coupon && item.item_coupon.coupon && item.item_coupon.coupon.is_flat_discount == 6">
																							<strike> {{ item.price }} </strike>
																							{{ item.item_coupon.coupon.flat_price }}
																						</span>
																					    <span v-else>
																							{{ item.price }}
																						</span>
																					</span>
																					<span v-else class="font-size-11 pl-1 text-secondary">
																						Qty : {{item.quantity}} | 
																						<span v-if="item.item_coupon && item.item_coupon.coupon && item.item_coupon.coupon.is_flat_discount == 6">
																							<strike> {{item.customization && item.customization.length>0 ? (item.customization[0].menu_customization.find(m=>m.is_default == '1') ? item.customization[0].menu_customization.find(m=>m.is_default == '1').selection_price: item.customization[0].menu_customization[0].selection_price ) : item.price}} </strike>
																							{{ item.item_coupon.coupon.flat_price }}
																						</span>
																					    <span v-else>
																							<span v-html="currency"></span> {{item.customization && item.customization.length>0 ? (item.customization[0].menu_customization.find(m=>m.is_default == '1') ? item.customization[0].menu_customization.find(m=>m.is_default == '1').selection_price: item.customization[0].menu_customization[0].selection_price ) : item.price}}
																					</span>
																						</span>

																					<template v-if="item.tag && item.tag.length > 0">
																						<template v-for="tag in item.tag" >
																							<b-badge variant="success" :key="tag.id">
																								{{tag.name}}
																							</b-badge>&nbsp;
																						</template>
																					</template>

																					<template v-if="item.item_coupon && item.item_coupon.coupon && item.item_coupon.coupon.coupon_code">
																						<b-badge class="ml-1" variant="success">
																							{{item.item_coupon.coupon.coupon_code}}
																						</b-badge>
																					</template> 
																				</div>
																			</div>
																			<div>
																				<div class="cst-swtch-set" v-if="item.is_available === '1'">
																					<div class="list-available d-flex item-new-add" v-if="cartQty(item) == '0' && item.customization.length != 0" @click="show(item, isfreebie=false)">
																						<button class="qty-item-add">{{trans.get('__JSON__.Add')}}</button>
																					</div>
																					<div class="list-available d-flex item-new-add" v-else-if="cartQty(item) == '0'" @click="show(item, isfreebie=false)">
																						<button class="qty-item-add">{{trans.get('__JSON__.Add')}}</button>
																					</div>
																					<div class="list-available d-flex item-new-add" v-else>
																							<span class="minus d-flex justify-content-center align-items-center" 
																						v-show="item.count > 0" @click="lessCart(item.restaurant_menu_item_id)">-</span>
																						<span class="qty-item">{{cartQty(item)}}</span>
																						<span class="d-flex justify-content-center align-items-center" v-if="item.customization.length != 0"
																						@click="show(item, isfreebie=false)">+</span>
																						<span v-else class="d-flex justify-content-center align-items-center" 
																						@click="show(item, isfreebie=false)">+</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</b-list-group-item>
																</template>
															</template>
															<div v-else-if="loading" class="text-center text-danger my-2 pt-5">
																<b-spinner class="align-middle"></b-spinner>
																<strong>{{trans.get('__JSON__.Loading...')}}</strong>
															</div>
															<b-list-group-item v-else key="abc" class="align-items-center d-flex justify-content-between">
																No menu found
															</b-list-group-item>
														</b-list-group>
													
														<div class="row mt-2"  v-if="!loading">
															<div class="col">
																<div class="dataTables_paginate paging_simple_numbers float-right" v-if="items && items.data">
																	<ul class="pagination pagination-rounded mb-0">
																		<pagination :data="items" :limit="limit" @pagination-change-page="listitembyid"></pagination>
																	</ul>
																</div>
															</div>
														</div>
														<b-modal v-if="selected_item" id="popup_item" centered scrollable>
															<template #modal-header>
																<div>
																	<h5>{{ selected_item.item_name }}</h5>
																</div>
																<div>
																	<button type="button" aria-label="Close" class="close" @click="$bvModal.hide('popup_item')">×</button>
																</div>
															</template>
															<div class="product-content pt-3">
																<div class="row">
																	<div
																		class="col-12 pro-popup-content"
																		v-for="(ces) in selected_item.customization"
																		:key="ces.customize_type_id"
																	>
																		<div class="row">
																			<div class="col-sm-6">
																				<h5>
																					{{ ces.type_name }}
																					<!-- ({{ ces.option.map((i)=>i.selection_name).join(',') }}) -->
																				</h5>
																			</div>
																			<div class="col-sm-6">
																				<p v-if="ces.is_selection > 0" class="text-right">{{ trans.get('__JSON__.Maximum') }} {{ces.is_min_selection}} - {{ces.is_selection}} {{ trans.get('__JSON__.Items') }}</p>
																			</div>
																		</div>
																		<div class="w-100">
																			<label
																				class="opt-main d-flex align-items-center w-100"
																				v-for="(opt,indexData) in ces.menu_customization"
																				:key="opt.menu_item_selection_id"
																				:for="((ces.is_selection == '1'?'optionsRadios':'checkBox1')+opt.menu_item_selection_id)"
																			>
																			<!-- $customizationPrice = ($promocode->flat_price + ($customizationPrice - $getFirstCustoPrice)); -->
																			
					
																				<p class="opt_selection font-weight-normal">{{ opt.selection_name }}</p>
																				<p class="font-weight-normal"><span v-html="currency"></span> 
															
																					<!-- is_default -->
																			

																					<template v-if="selected_item.item_coupon && selected_item.item_coupon.coupon && selected_item.item_coupon.coupon.is_flat_discount == '6' && opt.type == 'variation'">
																						
																						<span v-if="ces.menu_customization[0].selection_price < opt.selection_price">
																							<strike> {{ opt.selection_price }} </strike>
															
																							<span> {{ selected_item.item_coupon.coupon.flat_price + (opt.selection_price - ces.menu_customization[0].selection_price) }}</span>
																						</span>
																						<span v-else>
																							<strike> {{ opt.selection_price }} </strike>
																							{{ selected_item.item_coupon.coupon.flat_price ? selected_item.item_coupon.coupon.flat_price : '' }}
																						</span>
																				
																					</template>
																					<template v-else>
																						{{ opt.selection_price }}
																					</template>
																				</p>
																				<p class="check_new_item" v-if="ces.is_selection == '1'">
																					<input type="radio"  :name="'optionsRadios'+ces.customize_type_id" :value="opt"
																					:id="'optionsRadios'+opt.menu_item_selection_id" v-model="ces.selectedCustomisation" 
																					/>
																					<label class="radio" :for="'optionsRadios'+opt.menu_item_selection_id"></label>
																				</p>
																				<p v-else class="check_new_item">
																					<input type="checkbox" :id="'checkBox1'+opt.menu_item_selection_id"  name="checkBox" :value="opt"
																						v-model="ces.selectedCustomisation"
																					/>
																					<label class="radio" :for="'checkBox1'+opt.menu_item_selection_id"></label>
																				</p>
																			</label>
																			<!-- <template v-if="ces.selectedCustomisation.$error">
																				<span class="text-danger" key="" >Please select one</span>
																			</template> -->
																		</div>
																	</div>
																</div>
															</div> 
															<template slot="modal-footer">
																<div class="w-100">
																	<b-button
																		variant="primary"
																		class="float-right"
																		@click="hide($event,'popup_item',selected_item)"
																	>{{ trans.get('__JSON__.Add') }}</b-button>
																	<div class="list-available d-flex item-new-add mt-2" v-if="!isfreebie">
																		<span class="minus d-flex justify-content-center align-items-center" @click="custQty('-')">-</span>
																		<span class="qty-item">{{selected_item.qty}}</span>
																		<span class="d-flex justify-content-center align-items-center" @click="custQty('+')">+</span>
																	</div>
																</div>
															</template>  
														</b-modal>
													</div>

													<div class="col-lg-4 col-sm-6">
														<div  id="carts">
															<div class="mt-2 mb-2">
																<label for="">Special Intruction</label>
																<textarea type="text" v-model="special_instruction" class="form-control" ></textarea>
															</div>
															<div class="table-responsive item-order-main">
																<table class="table table-centered table-bordered item-table-main">
																	<thead>
																		<tr>
																			<th colspan="1" scope="col" class="text-center">{{trans.get('__JSON__.Quantity')}}</th>
																			<th scope="col" class="item-name-main w-50 pr-2">{{trans.get('__JSON__.Item Name')}}</th>
																		</tr>
																		<!-- <tr>
																			<th colspan="3" scope="col" class="text-center">{{trans.get('__JSON__.Quantity')}}</th>
																			<th colspan="1" scope="col" class="text-center">{{trans.get('__JSON__.Original')}}</th>
																		</tr> -->
																	</thead>
																	<tbody>
																		<template  v-for="(carts,cindex) in cart">
												
																			<tr :key="carts.id" v-if="carts.qty > 0">
																				<td colspan="1">
																					<span class="pl-2">{{ carts.qty }}</span>
																				</td>
																				<td>
																					<div class="d-flex justify-content-between">
																						<div>
																							<strong>{{ carts.item_name }}</strong>
																							<!-- {{getSelections(carts.customisation)}} -->
																							<p v-for="(c_customisation,cindex) in carts.customisation">
																								{{ 'c_customisation'. c_customisation }}
																								<span v-for="(c_selected,c_i) in c_customisation.selected">
																									{{ c_selected.name }}
																								</span>
																							</p>
																						</div>
																						<div><b-link href="javascript:void(0)" @click="removeItem(cindex)"><b-icon icon="x-circle" font-scale="1.5" class=" text-danger"></b-icon></b-link></div>
																					</div>
																				</td>
																			</tr>
																			<!-- :key="carts.id" -->
																			<!-- <tr>
																				<td align="center" colspan="3">
																					<span>{{ carts.qty }}</span>
																					<span> x </span>
																					<span>
																						<span v-html="currency"></span> {{ carts.item_total }} 
																					</span>
																				</td>
																				<td align="center" colspan="1" class="text-primary">
																					<span><span v-html="currency"></span> {{ parseFloat(totalPrice(carts) || 0).toFixed(decimal) }} </span>
																				</td>
																			</tr> -->
																		</template>

																		<!-- <tr>
																			<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6></td>
																			<td align="center" class="bg-soft-primary"> <span><span v-html="currency"></span> {{ parseFloat(total() || 0).toFixed(decimal) }} </span> </td>
																		</tr>
																		<tr v-if="promoApplied > 0">
																			<td colspan="3" align="center">
																				<div class="d-flex justify-content-end">
																				<h6 class="text-right mb-0">{{trans.get('__JSON__.Promo Code')}} </h6>
																				<span class="h5"><b-badge variant="danger"> {{ promocodeDetail.coupon_code }}</b-badge></span>
																				</div>
																			</td>
																			<td align="center" class="text-danger"> - <span v-html="currency"></span> {{ parseFloat(promocodeDetail.discount).toFixed(2) }} </td>
																		</tr>

																		<tr v-if="$auth.setting.sgst > 0">
																			<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.SGST')}}</h6></td>
																			<td align="center"> <span v-html="currency"></span> {{ getSgst() }} </td>
																		</tr>
																		<tr v-if="$auth.setting.cgst > 0">
																			<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.CGST')}}</h6></td>
																			<td align="center"> <span v-html="currency"></span> {{ getCgst() }} </td>
																		</tr>
																		<tr v-if="$auth.setting.igst > 0">
																			<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.IGST')}}</h6></td>
																			<td align="center"> <span v-html="currency"></span> {{ getIgst() }} </td>
																		</tr>
																		<tr >
																			<td colspan="3" align="right">
																				<span class="text-right">{{trans.get('__JSON__.Packaging Charge')}}</span>
																			</td>
																			<td align="center"> <span v-html="currency"></span> {{ getPackagingCharge() }} </td>
																		</tr>
																		<tr>
																			<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
																			<td align="center" class="bg-success font-size-16 font-weight-bold text-white"><span v-html="currency"></span> {{ parseFloat(promoApplied ? discountAmount() : totalAmount() || 0) }} 
																			</td>
																		</tr> -->
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</tab-content>
								<!-- Menu Details Tab End -->

								<!-- Order Summary Tab Start  -->
								<tab-content icon="mdi mdi-checkbox-marked-circle-outline" :title="'Order Summary ('  + cart.length + ')'">
									<div class="text-center text-danger cart-loader" v-if="!isVerified">
										<b-spinner class="align-middle mr-2"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
									</div>
									<!-- View Detail Main Start -->
									<div class="row">
										<div class="col-sm-4">
											<b-list-group>
												<b-list-group-item>
													<h3>{{restaurant && restaurant.name}}</h3>
												</b-list-group-item>
												<b-list-group-item>
													<span class="font-size-14 font-weight-bold">Customer Name: </span> {{user && user.user_name}}
												</b-list-group-item>
												<b-list-group-item>
													<span class="font-size-14 font-weight-bold">Email: </span> {{user && user.email}}
												</b-list-group-item>
												<b-list-group-item>
													<span class="font-size-14 font-weight-bold">Phone: </span> {{user && user.mobile_number}}
												</b-list-group-item>
												<b-list-group-item>
													<span class="font-size-14 font-weight-bold">Address: </span> {{address && address.address || ''}}
												</b-list-group-item>
											</b-list-group>
										</div>
										<div class="col-sm-4">
											<div  id="carts">
												<div class="table-responsive item-order-main">
													<table class="table table-centered table-bordered item-table-main item-table-data" v-if="isVerified">
														<thead>
															<tr>
																<th colspan="4" scope="col" class="item-name-main w-50 pr-2">{{trans.get('__JSON__.Item Name')}}</th>
															</tr>

															<tr colspan="4" class="p-3">
																<td colspan="4" class="p-3" v-if="!isFreebieApplied && freebie_item.item_coupon && freebie_item.item_coupon.coupon && calculation.subtotal >= freebie_item.item_coupon.coupon.minimum_order_amount">
																	<!-- Freebie  -->
																	<template>
																		<b-list-group-item>
																			<div class="d-flex justify-content-between">
																				<div class="d-flex">
																					<div>
																						<div v-if="freebie_item.item_type == '0'" class="position-static veg"><span></span></div>
																						<div v-if="freebie_item.item_type == '1'" class="position-static non-veg"><span></span></div>
																					</div>
																					<div>
																						<div class="d-flex">
																							<span class="font-size-13 font-weight-medium pl-1" :title="freebie_item.item_name">{{freebie_item.item_name | truncate(35, '...')}} </span>
																							<span v-b-tooltip.hover.v-light :title="freebie_item.item_description" >
																								<i class="bx bx-info-circle"></i>
																							</span>
																							<div>
																								<span v-if="freebie_item.customization.length != 0" class="badge badge-pill font-size-12 ml-2 badge-soft-primary">Customizable</span>
																							</div>
																						</div>
																						<span v-if="freebie_item.price != 0" class="font-size-11 pl-1 text-secondary">
																							Qty : {{freebie_item.quantity}} |
																							<span v-html="currency"></span>
																							<span>
																								<strike> {{ freebie_item.price }} </strike>
																								FREE
																							</span>
																						</span>
																						<span v-else class="font-size-11 pl-1 text-secondary">
																							Qty : {{freebie_item.quantity}} | 
																							<span v-html="currency"></span> 
																							<strike> 
																								{{freebie_item.customization && freebie_item.customization.length>0 ? (freebie_item.customization[0].menu_customization.find(m=>m.is_default == '1') ? freebie_item.customization[0].menu_customization.find(m=>m.is_default == '1').selection_price: freebie_item.customization[0].menu_customization[0].selection_price ) : freebie_item.price}}  
																							</strike>
																							FREE
																						</span>
			
																						<template v-if="freebie_item.tag && freebie_item.tag.length > 0">
																							<template v-for="tag in freebie_item.tag" >
																								<b-badge variant="success" :key="tag.id">
																									{{tag.name}}
																								</b-badge>&nbsp;
																							</template>
																						</template>
																					</div>
																				</div>
																				<div>
																					<div class="cst-swtch-set" v-if="freebie_item.is_available === '1'">
																						<div class="list-available d-flex item-new-add" v-if="cartQty(freebie_item) == '0' && freebie_item.customization.length != 0" @click="show(freebie_item, isfreebie=true)">
																							<button class="qty-item-add">{{trans.get('__JSON__.Add')}}</button>
																						</div>
																						<div class="list-available d-flex item-new-add" v-else-if="cartQty(freebie_item) == '0'" @click="show(freebie_item, isfreebie=true)">
																							<button class="qty-item-add">{{trans.get('__JSON__.Add')}}</button>
																						</div>
																						<div class="list-available d-flex item-new-add" v-else>
																							<span class="minus d-flex justify-content-center align-items-center" 
																							v-show="freebie_item.count > 0" @click="lessCart(freebie_item.restaurant_menu_item_id)">-</span>
																							<span class="qty-item">{{cartQty(freebie_item)}}</span>
																							<span class="d-flex justify-content-center align-items-center" v-if="freebie_item.customization.length != 0"
																							@click="show(freebie_item, isfreebie=true)">+</span>
																							<span v-else class="d-flex justify-content-center align-items-center" 
																							@click="show(freebie_item, isfreebie=true)">+</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</b-list-group-item>
																	</template>
																</td>
																<!-- Free bie -->
															</tr>
															<tr>
																<th colspan="3" scope="col" class="text-center">{{trans.get('__JSON__.Quantity')}}</th>
																<th colspan="1" scope="col" class="text-center">{{trans.get('__JSON__.Original')}}</th>
															</tr>
														</thead>
														<tbody>
															<template  v-for="(carts,cindex) in cart_data">
																<tr :key="carts.id" v-if="carts.qty > 0">
																	<td colspan="4">
																		<div class="d-flex justify-content-between">
																			<div>
																				<strong>{{ carts.item_name }}</strong> 
																				<!-- {{getSelections(carts.customisation)}} -->
																				<p v-for="(c_customisation,cindex) in carts.customisation">
																					{{ 'c_customisation'. c_customisation }}
																					<span v-for="(c_selected,c_i) in c_customisation.selected">
																						{{ c_selected.name }}
																					</span>
																				</p>
																			</div>
																			<div>
																				<b-link href="javascript:void(0)" @click="removeItem(cindex)">
																					<b-icon icon="x-circle" font-scale="1.5" class=" text-danger">
																					</b-icon>
																				</b-link>
																			</div>
																		</div>
																	</td>
																</tr>
																<!-- :key="carts.id" -->
																<tr>
																	<td align="center" colspan="3">
																		<span>{{ carts.qty }}</span><span> x </span><span>
																			<span v-html="currency"></span> {{ parseFloat(carts.actual_price).toFixed(2) }} </span>
																	</td>
																	<td align="center" colspan="1" class="text-primary">
																		<span v-if="carts.total_price == '0'">
																			<span v-html="currency"></span>
																			<strike> {{ parseFloat(carts.actual_price * carts.qty || 0).toFixed(2) }} </strike>
																			<b-badge class="ml-2" variant="success">FREE</b-badge>
																		</span>
																		<span v-else-if="carts.actual_price > carts.unit_price"> 
																			<strike> {{ parseFloat(carts.actual_price * carts.qty ).toFixed(2) }} </strike>
																			{{ parseFloat(carts.unit_price * carts.qty).toFixed(2) }}
																		</span>
																		<span v-else>
																			<span v-html="currency"></span>
																			{{ parseFloat(carts.total_price || 0).toFixed(2) }}
																		</span>
																	</td>
																</tr>
															</template>
															<tr>
																<td colspan="3" align="center">
																	<h6 class="text-right mb-0">{{trans.get('__JSON__.Sub Total')}}</h6>
																</td>
																<td align="center" class="bg-soft-primary"> 
																	<span>
																		<span v-html="currency"></span> 
																		{{ calculation ? parseFloat(calculation.subtotal || 0) : 0}}
																	</span> 
																</td>
															</tr>
															<tr v-if="promoApplied > 0 && calculation.promocode_discount > 0">
																<td colspan="3" align="center">
																	<div class="d-flex justify-content-end">
																		<h6 class="text-right mb-0">{{trans.get('__JSON__.Promo Code')}} </h6>
																		<span class="h5"><b-badge variant="danger"> {{ promocodeDetail.coupon_code }}</b-badge></span>
																	</div>
																</td>
																<td align="center" class="text-danger"> 
																	- <span v-html="currency"></span> 
																	{{ parseFloat(calculation.promocode_discount).toFixed(2) }} 
																</td>
															</tr>
															<tr v-if="promoApplied > 0 && calculation.promocode_cashback > 0">
																<td colspan="3" align="center">
																	<div class="d-flex justify-content-end">
																		<h6 class="text-right mb-0">{{trans.get('__JSON__.Cashback')}} </h6>
																	</div>
																</td>
																<td align="center" class="text-success"> + <span v-html="currency"></span> 
																	{{ parseFloat(calculation.promocode_cashback).toFixed(2) }} 
																</td>
															</tr>
															<tr v-if="$auth.setting.sgst > 0">
																<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.SGST')}}</h6></td>
																<td align="center"> <span v-html="currency"></span> 
																	{{ calculation && calculation.SGST ? parseFloat(calculation.SGST || 0) : 0}}
																</td>
															</tr>
															<tr v-if="$auth.setting.cgst > 0">
															<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.CGST')}}</h6></td>
																<td align="center"> <span v-html="currency"></span> 
																	{{ calculation && calculation.CGST ? parseFloat(calculation.CGST || 0) : 0}}
																</td>
															</tr>
															<tr v-if="$auth.setting.igst > 0">
																<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.IGST')}}</h6></td>
																<td align="center"> <span v-html="currency"></span> 
																	{{ calculation && calculation.IGST ? parseFloat(calculation.IGST || 0) : 0}}
																</td>
															</tr>
															<tr >
																<td colspan="3" align="right">
																	<span class="text-right">{{trans.get('__JSON__.Packaging Charge')}}</span>
																</td>
																<td align="center"> <span v-html="currency"></span> 
																	{{ calculation ? parseFloat(calculation.packaging_charge || 0) : 0}}
																</td>
															</tr>
															<tr >
																<td colspan="3" align="right">
																	<span class="text-right">{{trans.get('__JSON__.Delivery Charge')}}</span>
																</td>
																<td align="center"> <span v-html="currency"></span> 
																	{{ calculation ? parseFloat(calculation.delivery_charge || 0) : 0}}
																</td>
															</tr>
															<tr>
																<td colspan="3" align="center"><h6 class="text-right mb-0">{{trans.get('__JSON__.Total Amount')}}</h6></td>
																<td align="center" class="bg-success font-size-16 font-weight-bold text-white"><span v-html="currency"></span> 
																	{{ calculation ? parseFloat(calculation.total || 0) : 0}}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
										<div class="col-sm-4">
											<div>
												<label for="Promo Code">Promo Code</label>
												<p v-if="itemCouponApplied && calculation.item_discount_excluding_freebie > 0" class="text-danger font-weight-bold">
													Saved amount 
													<span v-html="currency"></span>  
													{{ calculation ? parseFloat(calculation.item_discount_excluding_freebie || 0) : 0}}

												</p>
												<b-input-group prepend="Enter Code">
													<b-form-input ref="promocode" name="promocode" v-model="promocode" :disabled="promoApplied || itemCouponApplied"
													@keyup.enter="applyPromoCode" ></b-form-input>
													<b-input-group-append>
														<b-button :variant="promoApplied || itemCouponApplied ?'outline-danger':'outline-success'" @click="applyPromoCode(itemCouponApplied)" :disabled="promo_loading">
															{{ promoApplied || itemCouponApplied ? 'Remove' : 'Apply' }}
														</b-button>
													</b-input-group-append>
												</b-input-group>
											</div>
											<div class="mt-2">
											<label for="">Special Intruction</label>
											<textarea type="text" v-model="special_instruction" class="form-control" ></textarea>
											</div>
											<div class="mt-2">
											<input type="checkbox" v-model="scheduled" id="scheduled" @change="scheduledOrder">
											<label for="scheduled">Is Scheduled Order</label>
											</div>                
											<div v-if="scheduled" class="mt-2">
											<label for="future_delivery_date">Schedule Time</label>
											<date-picker
												:clearable="false"
												v-model="schedule_date"
												format="YYYY-MM-DD hh:mm A"
												type="datetime"
												class="date-time"                          
												:placeholder="'Select Schedule Time'"
												valueType="date"
												@change="formatDate"
												:disabled-date="(date)=>disabledDate(date)"
											></date-picker>
												<!-- :disabled-time="(date)=>disabledTime(date)" -->
											</div>
											<div class="mt-2">
											<b-button variant="success"  v-if="isPlaceOrder" class="font-size-20 w-100">{{ trans.get('__JSON__.Placing Order') }}</b-button>
											<b-button variant="success" @click="createOrder" v-else class="font-size-20 w-100">{{ trans.get('__JSON__.Place Order') }}</b-button>
											</div>
										</div>
									</div>
									<!-- View Detail Main End -->
								</tab-content>
								<!-- Order Summary Tab End -->

								<div slot="finish"></div>
								
								<!-- <button slot="finish" class="finish-order-btn" v-if="isPlaceOrder" disabled>{{ trans.get('__JSON__.Placing Order') }}</button>
								<button slot="finish" class="finish-order-btn" v-else  @click="createOrder">{{ trans.get('__JSON__.Place Order') }}</button> -->
							</vue-form-wizard>
						</div>
						<!-- end card-body -->
					</div>
					<!-- end card -->
				</div>
			</div>
		</div>
	</Layout>
</template>
<style>
.item-table-data tr td, .item-table-main tr td
{
	white-space: unset !important;
}

.cart-loader
{
	position: absolute;
    z-index: 10000;
    width: 100%;
    background: #ffffff4a;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>