import Vue from 'vue';
import Router from "vue-router";
import VueSocketIO from 'vue-socket.io';
import Login from "../views/account/login.vue";
import ForgotPassword from "../views/account/forgot-password.vue";
import Dashboard from "../views/dashboard.vue";
import OrderList from "../views/orders/list.vue";
import Order from "../views/orders/main.vue";
import Booking from "../views/bookings/main.vue";
import ChowmanPay from "../views/chowman-pay/list.vue";
import GiftVoucherPurchasedList from "../views/gift-voucher/purchased-list.vue";
import GiftVoucherList from "../views/gift-voucher/list.vue";
import GiftVoucherAdd from "../views/gift-voucher/add.vue";
import GiftVoucherEdit from "../views/gift-voucher/edit.vue";
import VendorList from "../views/restaurant/list.vue";
import VendorAdd from "../views/restaurant/add.vue";
import VendorDetails from "../views/restaurant/details.vue";
import CustomerList from "../views/customer/list.vue";
import CustomerDetails from "../views/customer/details.vue";
import DriverList from "../views/driver/list";
import DriverAdd from "../views/driver/add";
import DriverDetails from "../views/driver/details";
import CountryList from "../views/location/list";
import StateList from "../views/location/state-list";
import CityList from "../views/location/city-list";
import DeliveryList from "../views/delivery_zone/list";
import DeliveryAdd from "../views/delivery_zone/add";
import DeliveryEdit from "../views/delivery_zone/edit";
import DeliveryZoneDetails from "../views/delivery_zone/details";
import DeliveryAddArea from "../views/delivery_zone/add-area";
import DeliveryEditArea from "../views/delivery_zone/edit-area";
import CouponList from "../views/coupon/list";
import SpecialCouponList from "../views/special_coupon/list";
import CouponAdd from "../views/coupon/add";
import CouponEdit from "../views/coupon/edit";
import SpecialCouponEdit from "../views/special_coupon/edit";
import BannerList from "../views/banner/list";

import OrderStatusBannerList from "../views/order_status_banner/list.vue";
import TopPicks from "../views/top_picks/top_picks.vue";

import BannerAdd from "../views/banner/add";
import OrderStatusBannerAdd from "../views/order_status_banner/add";
import BannerEdit from "../views/banner/edit";
import OrderStatusBannerEdit from "../views/order_status_banner/edit";
import CategoryList from "../views/category/list";
import CategoryAdd from "../views/category/add";
import CategoryEdit from "../views/category/edit";
import CategoryImageList from "../views/category_image/list";
import FaqList from '../views/faq/list';
import FaqAdd from '../views/faq/add';
import FaqEdit from '../views/faq/edit';
import SystemList from '../views/system/list';
import CuisinesList from '../views/cuisines/list';
import CuisinesAdd from '../views/cuisines/add';
import CuisinesEdit from '../views/cuisines/edit';
import Configuration from '../views/settings/configurations';
import Themes from '../views/settings/themes';
import EagleView from '../views/eagle-view/list';
import ContentPage from '../views/content-page/list';
import Reports from '../views/reports/list';
import Terminology from '../views/settings/terminology';
// import AddTerminology from '../views/settings/add-terminology';
import Invoice from '../views/orders/invoice';
import VendorFinance from "../views/finance/vendor";
import VendorFinanceMonth from '../views/finance/vendor-finance-month';
import VendorFinanceList from '../views/finance/vendor-finance-list';
import RestaurantFinance from '../views/finance/restaurant/restaurant-finance';
import RestaurantFinanceTransaction from '../views/finance/restaurant/restaurant-transaction';
import DriverDashboard from '../views/driver/dashboard';
import DriverFinance from '../views/finance/driver';
import DriverFinanceDetail from '../views/finance/driver/driver-finance-detail';
import DriverFinanceMonth from '../views/finance/driver/driver-finance-month';
import DriverTransaction from '../views/finance/driver/driver-transaction';
import Profile from '../views/profile/subscription_info';
import UserAccess from '../views/settings/user-access/user-list';
import UserAccessAdd from '../views/settings/user-access/add-user';
import UserAccessEdit from '../views/settings/user-access/edit-user';
import UserAccessPermission from '../views/settings/user-access/user-permission';
import Catalog from '../views/restaurant/catalog.vue';
import ChangePassword from '../views/profile/change-password.vue';
import DiscountList from '../views/discount/discount-list.vue';
import DiscountAdd from '../views/discount/add.vue';
import DiscountEdit from '../views/discount/edit.vue';
import BusinessProfile from '../views/profile/business-profile.vue';
import SubVendorProfile from '../views/restaurant/profile';
import TimeSlot from '../views/timeslot/timeslots.vue';
import SellingTimeSlot from '../views/timeslot/selling-timeslot.vue';
import Reviews from '../views/reviews/list.vue';
import Category from '../views/restaurant/category.vue';
import SalesReport from '../views/reports/sales_report.vue';
import ChowmanPayReport from '../views/reports/chowmanpay_report.vue';
import NewSignupReport from '../views/reports/new_signup_report.vue';
import CashbackReport from '../views/reports/cashback.vue';
import DriverOrderReport from '../views/reports/driver_order_report.vue';
import ItemReport from '../views/reports/item_report.vue';
import CustomerReport from '../views/reports/customer_report.vue';
import TotalSaleEarning from '../views/reports/total_sales_earning.vue';
import TaxReport from '../views/reports/tax_report.vue';
import DriverReport from '../views/reports/driver_report';
import InactiveCustomerReport from '../views/reports/inactive_customer_report';
import CODOnlineOrderReport from '../views/reports/cod_online_order_report';
import AvgRatingReport from '../views/reports/avg_rating_report';
import PromoCodeReport from '../views/reports/promo_code_report';
import DriverReportDetailsReport from '../views/reports/driver_report_details';
import VendorRatingReport from '../views/reports/vendor_rating_report';
import VendorDineRatingReport from '../views/reports/vendor_dine_rating_report';
import AppLayoutSetting from '../views/settings/app-layout-setting';
import WebLayoutSetting from '../views/settings/web-layout-setting';
import AddCustomization from '../views/restaurant/add-customization';
import AddSubCustomization from '../views/restaurant/add-sub-customization';
import AddContentPage from '../views/content-page/add';
import EditContentPage from '../views/content-page/edit';
import ViewContentPage from '../views/content-page/view-page';
import config from '../config';
import CallCenterDashboard from '../views/call-center/dashboard';
import { orderService } from '../services';
import OperationTime from '../views/restaurant/operation-time';
import VendorCategory from '../views/restaurant/vendor-category';
import VendorDiscount from '../views/restaurant/vendor-discount';
import VendorProfile from '../views/restaurant/vendor-profile';
import VendorRating from '../views/restaurant/vendor-rating';
import ChatListig from '../views/chat/chat';
import MenuListing from '../views/menu/list';
import MenuDineIn from '../views/restaurant/dine-in-menu.vue';
import AddDineInCustomzation from '../views/restaurant/add-dine-in-customization';
import AddDineInSubCustomzation from '../views/restaurant/add-dine-in-sub-customization';
import DineInBannerList from '../views/restaurant/dine-in-banner-list';
import DineInBannerAdd from '../views/restaurant/add-dine-in-banner';
import DineInBannerEdit from '../views/restaurant/edit-dine-in-banner';
import Feedbacks from '../views/menu/feedback.vue';
import DineInFeedback from '../views/restaurant/dine-in-feedback-list';
import FeedbackReport from '../views/reports/feedback_report';
import FoodReadyMarkReport from '../views/reports/food_ready_mark';
import ReferAndEarnReport  from '../views/reports/refer_and_earn';
import TagList from '../views/tag/list';
import VendorTag from '../views/restaurant/vendor-tag';
import MailList from "../views/mail/list.vue";
import MailAdd from "../views/mail/add.vue";
import MailEdit from "../views/mail/edit.vue";
import MailView from '../views/mail/view-page.vue';
import ZomatoReport from '../views/reports/zomato_report.vue';
import ZomatoOrder from '../views/zomato_order/main.vue';
Vue.use(Router);

const base = config.base_folder;

const vendorRoutes = [
  {
    path: base+'login',
    name: 'login',
    component: Login
  },
  {
    path: base+'dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: base+'orders/list',
    name: 'orders-list',
    component: Order
  }, 
  {
    path: base+'bookings/list',
    name: 'bookings-list',
    component: Booking
  },  
  {
    path: base+'call-center/dashboard',
    name: 'call-center-dashboard',
    component: CallCenterDashboard
  },
  {
    path: base+'vendors',
    name: 'vendor-list',
    component: VendorList,
  },
  {
    path: base+'vendors/add',
    name: 'vendor-add',
    component: VendorAdd
  },
  {
    path: base+'vendors/details/:restaurant_id',
    name: 'vendor-detail',
    component: VendorDetails
  },
  {
    path: base+'vendors/catalog/:restaurant_id',
    name: 'vendor-catalog',
    component: Catalog
  },
  {
    path: base+'vendors/add-customization/:menu_item_id',
    name: 'vendor-add-customization',
    component: AddCustomization
  },
  {
    path: base+'vendors/add-sub-customization/:menu_item_selection_id',
    name: 'vendor-add-sub-customization',
    component: AddSubCustomization
  },
  {
    path: base+'customer',
    name: 'customer-list',
    component: CustomerList
  },
  {
    path: base+'customer/details/:user_id',
    name: 'customer-detail',
    component: CustomerDetails
  },  
  {
    path: base+'driver/dashboard',
    name: 'driver-dashboard',
    component: DriverDashboard
  },
  {
    path: base+'driver',
    name: 'driver-list',
    component: DriverList
  },
  {
    path: base+'chowman-pay/list',
    name: 'chowman-pay-list',
    component: ChowmanPay
  },
  {
    path: base+'reports/chowmanpay',
    name: 'reports.chowmanpay.report',
    component: ChowmanPayReport
  },
  {
    path: base+'gift-vouchers-purchased/list',
    name: 'gift-voucher-purchased-list',
    component: GiftVoucherPurchasedList
  },
  {
    path: base+'gift-vouchers/list',
    name: 'gift-voucher-list',
    component: GiftVoucherList
  },
  {
    path: base+'gift-vouchers/add',
    name: 'gift-voucher-add',
    component: GiftVoucherAdd
  },
  {
    path: base+'gift-vouchers/:voucher_id/edit',
    name: 'gift-voucher-edit',
    component: GiftVoucherEdit
  },
  {
    path: base+'driver/add',
    name: 'driver-add',
    component: DriverAdd
  },
  {
    path: base+'driver/details/:driver_id',
    name: 'driver-details',
    component: DriverDetails
  },
  {
    path: base+'location',
    name: 'country-list',
    component: CountryList
  },
  {
    path: base+'location/state-list/:country_id',
    name: 'state-list',
    component: StateList
  },
  {
    path: base+'location/city-list/:state_id',
    name: 'city-list',
    component: CityList
  },
  {
    path: base+'delivery_zone',
    name: 'list',
    component: DeliveryList
  },
  {
    path: base+'delivery_zone/add',
    name: 'delivery-zone-add',
    component: DeliveryAdd
  },
  {
    path: base+'delivery_zone/edit/:user_id',
    name: 'delivery-zone-edit',
    component: DeliveryEdit
  },
  {
    path: base+'delivery_zone/details/:user_id',
    name: 'delivery-zone-details',
    component: DeliveryZoneDetails
  },
  {
    path: base+'delivery_zone/add-area/:frenchiese_id',
    name: 'add-area',
    component: DeliveryAddArea
  },
  {
    path: base+'delivery_zone/edit-area/:area_id',
    name: 'edit-area',
    component: DeliveryEditArea
  },
  {
    path: base+'coupon',
    name: 'coupon-list',
    component: CouponList
  },
  {
    path: base+'coupon/special',
    name: 'special-coupon-list',
    component: SpecialCouponList,
    access: true
  },
  {
    path: base+'coupon/add',
    name: 'coupon-add',
    component: CouponAdd
  },
  {
    path: base+'coupon/edit/:id',
    name: 'coupon-edit',
    component: CouponEdit
  },
  {
    path: base+'coupon/special/edit/:id',
    name: 'special-coupon-edit',
    component: SpecialCouponEdit
  },
  {
    path: base+'banner',
    name: 'banner-list',
    component: BannerList
  },
  {
    path: base+'order-status-banner',
    name: 'order-status-banner-list',
    component: OrderStatusBannerList
  },
  {
    path: base+'top-picks',
    name: 'top-picks',
    component: TopPicks
  },
  {
    path: base+'banner/add',
    name: 'banner-add',
    component: BannerAdd
  },
  {
    path: base+'order-status-banner/add',
    name: 'order-status-banner-add',
    component: OrderStatusBannerAdd
  },
  {
    path: base+'banner/edit/:id',
    name: 'banner-edit',
    component: BannerEdit
  },
  {
    path: base+'order-status-banner/edit/:id',
    name: 'order-status-banner-edit',
    component: OrderStatusBannerEdit
  },
  {
    path: base+'finance/driver',
    name: 'driver',
    component: DriverFinance
  },
  {
    path: base+'finance/driver/month/:month',
    name: 'driver-finance-month',
    component: DriverFinanceMonth
  },
  {
    path: base+'finance/driver/month/:month/:driverid/dashboard',
    name: 'driver-finance',
    component: DriverFinanceDetail
  },
  {
    path: base+'finance/driver/:driverid/transactions',
    name: 'driver-finance-transactions',
    component: DriverTransaction
  },
  {
    path: base+'finance/vendor',
    name: 'vendor',
    component: VendorFinance
  },
  {
    path: base+'finance/vendor/month/:month',
    name: 'vendor-finance-month',
    component: VendorFinanceMonth
  },
  {
    path: base+'finance/vendor-finance-list',
    name: 'vendor-finance-list',
    component: VendorFinanceList
  },
  {
    path: base+'finance/vendor/month/:month/:id/dashboard',
    name: 'restaurant-finance-list',
    component: RestaurantFinance
  },
  {
    path: base+'finance/vendor/:id/transactions',
    name: 'restaurant-transaction-list',
    component: RestaurantFinanceTransaction
  },
  {
    path: base+'category',
    name: 'category-list',
    component: CategoryList
  },
  {
    path: base+'category/add',
    name: 'category-add',
    component: CategoryAdd
  },
  {
    path: base+'category/edit/:category_id',
    name: 'category-edit',
    component: CategoryEdit
  },
  {
    path: base+'category-image',
    name: 'category-image-list',
    component: CategoryImageList
  },
  {
    path: base+'faq',
    name: 'faq-list',
    component: FaqList
  },
  {
    path: base+'faq/add',
    name: 'faq-add',
    component: FaqAdd
  },
  {
    path: base+'faq/edit/:id',
    name: 'faq-edit',
    component: FaqEdit
  },
  {
    path: base+'system/list',
    name: 'system-list',
    component: SystemList
  },
  {
    path: base+'cuisines',
    name: 'cuisines-list',
    component: CuisinesList
  },
  {
    path: base+'cuisines/add',
    name: 'cuisines-add',
    component: CuisinesAdd
  },
  {
    path: base+'cuisines/edit/:cuisine_id',
    name: 'cuisines-edit',
    component: CuisinesEdit
  },
  {
    path: base+'settings/configurations',
    name: 'configurations',
    component: Configuration
  },
  {
    path: base+'settings/app-layout-setting',
    name: 'app-layout-setting',
    component: AppLayoutSetting
  },
  {
    path: base+'settings/web-layout-setting',
    name: 'web-layout-setting',
    component: WebLayoutSetting
  },
  {
    path: base+'settings/themes',
    name: 'themes',
    component: Themes
  },
  {
    path: base+'eagle-view/list',
    name: 'eagle-list',
    component: EagleView
  },
  {
    path: base+'content-page',
    name: 'content-list',
    component: ContentPage
  },
  {
    path: base+'reports',
    name: 'reports-list',
    component: Reports
  },
  {
    path: base+'reports/sales_report',
    name: 'reports.sale.report',
    component: SalesReport
  },
  {
    path: base+'reports/new_signups',
    name: 'reports.new_signup.report',
    component: NewSignupReport
  },  
  {
    path: base+'reports/cashback',
    name: 'reports.cashback.report',
    component: CashbackReport
  },  
  {
    path: base+'reports/driver_order_report',
    name: 'reports.driver.order.report',
    component: DriverOrderReport
  },
  {
    path: base+'reports/customer_report',
    name: 'reports.customer.report',
    component: CustomerReport
  },
  {
    path: base+'reports/total_sales_earning',
    name: 'reports.total.earning',
    component: TotalSaleEarning
  },
  {
    path: base+'reports/tax_report',
    name: 'reports.tax.report',
    component: TaxReport
  },
  {
    path: base+'reports/driver_report',
    name: 'reports.driver.report',
    component: DriverReport
  },
  {
    path: base+'reports/inactive_customer_report',
    name: 'reports.inactive.customer.report',
    component: InactiveCustomerReport
  },
  {
    path: base+'reports/cod_online_order_report',
    name: 'reports.cod.online.order.report',
    component: CODOnlineOrderReport
  },
  {
    path: base+'reports/avg_rating_report',
    name: 'reports.avg.rating.report',
    component: AvgRatingReport
  },
  {
    path: base+'reports/promo_code_report',
    name: 'reports.promo.code.report',
    component: PromoCodeReport
  },
  {
    path: base+'reports/driver_report_details',
    name: 'driver.reports.details',
    component: DriverReportDetailsReport
  },
  {
    path: base+'reports/vendor_rating_report',
    name: 'reports.vendor.feedback.report',
    component: VendorRatingReport
  },
  {
    path: base+'reports/vendor_rating_report/dine',
    name: 'reports.vendor.dine_feedback.report',
    component: VendorDineRatingReport
  },
  
  {
    path: base+'reports/feedback_report',
    name: 'feedback.report',
    component: FeedbackReport
  },
  {
    path: base+'settings/terminology',
    name: 'terminology-list',
    component: Terminology
  },
  {
    path: base+'profile/subscription_info',
    name: 'subscription-info',
    component: Profile
  },
  {
    path: base+'profile/change-password',
    name: 'change-password',
    component: ChangePassword
  },
  {
    path: base+'profile/business-profile',
    name: 'business-profile',
    component: BusinessProfile
  },
  {
    path: base+'settings/user-access',
    name: 'user-access',
    component: UserAccess
  },
  {
    path: base+'settings/user-access/add',
    name: 'user-access-add',
    component: UserAccessAdd
  },
  {
    path: base+'settings/user-access/:id/edit',
    name: 'user-access-edit',
    component: UserAccessEdit
  },
  {
    path: base+'settings/user-access/:id/permissions',
    name: 'user-access.permissions',
    component: UserAccessPermission
  },
  {
    path: base+'content-page/add',
    name: 'content-page-add',
    component: AddContentPage
  },
  {
    path: base+'content-page/edit/:id',
    name: 'content-page-edit',
    component: EditContentPage
  },
  {
    path: base+'content-page/view/:id',
    name: 'content-page-view',
    component: ViewContentPage,
  },
  {
    path : base+'chat/chat',
    name : 'chat-list',
    component : ChatListig
  },
  {
    path: base+'vendors/dine-in-menu/:restaurant_id',
    name: 'vendor-dine-in-menu',
    component: MenuDineIn
  },  
  {
    path: base+'vendors/add-dine-in-customization/:menu_item_id',
    name: 'vendor-dine-in-add-customization',
    component: AddDineInCustomzation
  },
  {
    path: base+'vendors/add-dine-in-sub-customization/:menu_item_selection_id',
    name: 'vendor-add-dine-in-sub-customization',
    component: AddDineInSubCustomzation
  },

  {
    path: base+'vendors/dine-in-banner-list/:restaurant_id',
    name: 'dine-in-banner',
    component: DineInBannerList
  },
  {
    path: base+'vendors/add-dine-in-banner/:restaurant_id',
    name: 'add-dine-in-banner',
    component: DineInBannerAdd
  },
  {
    path: base+'vendors/edit-dine-in-banner/:id/:restaurant_id',
    name: 'edit-dine-in-banner',
    component: DineInBannerEdit
  },
  {
    path :  base+'menu/:pet_pooja_restaurant_id',
    name : 'menu-list',
    component : MenuListing,
    access: true
  },
  {
    path: base+'feedback/:restaurant_id',
    name: 'feedbacks',
    component: Feedbacks,
    access: true
  },
  {
    path: base+'vendors/feedback/list/:restaurant_id',
    name: 'feedback-list',
    component: DineInFeedback
  },
  {
    path: base+'reports/food_ready_mark',
    name: 'reports.food_ready_mark.report',
    component: FoodReadyMarkReport
  }, 
  {
    path: base+'reports/refer_earn',
    name: 'reports.refer_earn.report',
    component: ReferAndEarnReport
  }, 
  {
    path : base+'tag',
    name : 'tag',
    component : TagList
  },
  {
    path: base+'mails',
    name: 'mail-list',
    component: MailList
  },
  {
    path: base+'mails/add',
    name: 'add-mail',
    component: MailAdd
  },
  {
    path: base+'mails/edit/:id',
    name: 'edit-mail',
    component: MailEdit
  },
  {
    path: base+'mails/view-page/:id',
    name: 'view-mail',
    component: MailView
  },
  {
    path: base+'reports/zomato_report',
    name: 'reports.zomato.report',
    component: ZomatoReport
  },
  {
    path: base+'thirdpartyorder/list',
    name: 'zomato-orders-list',
    component: ZomatoOrder
  }, 
];

// const resturantRoutes = [
//     ...vendorRoutes.reduce((a,r)=>{
//       r.path='restaurant/'+r.path;
//       r.name='restaurant.'+r.name;
//       return a.concat(r);
//     },[])
// ];

const router = new Router({
    mode: "history",
    // base,
    routes: [
      {
          path: '*',
          redirect: base+'login'
      },
      ...vendorRoutes,
      {
          path: base+'vendor/login',
          name: 'vendor.login',
          component: Login
      },
      {
          path: base+'vendor/dashboard',
          name: 'vendor.dashboard',
          component: Dashboard
        },
        {
            path: base+'vendor/orders/list',
            name: 'vendor.order.list',
            component: Order
        },
        {
          path: base+'vendor/thirdpartyorder/list',
          name: 'vendor.thirdpartyorder.list',
          component: ZomatoOrder
        },
        {
            path: base+'vendor/bookings/list',
            name: 'vendor.booking.list',
            component: Booking
        },
        {
          path: base+'vendor/vendors/catalog',
          name: 'vendor.vendor.catalog',
          component: Catalog
        },
        {
          path: base+'vendor/vendors/add-customization/:menu_item_id',
          name: 'vendor.vendor.add.customization',
          component: AddCustomization
        },
        {
          path: base+'vendor/vendors/add-sub-customization/:menu_item_selection_id',
          name: 'vendor.vendor.add.sub.customization',
          component: AddSubCustomization
        },
        {
          path: base+'vendor/delivery_zone',
          name: 'vendor-delivery-list',
          component: DeliveryList
        },
        {
          path: base+'vendor/delivery_zone/details/:user_id',
          name: 'vendor-delivery-zone-details',
          component: DeliveryZoneDetails
        },
        // {
        //   path: base+'vendor/coupon/list',
        //   name: 'vendor.coupon.list',
        //   component: CouponList
        // },
        {
          path: base+'vendor/driver/dashboard',
          name: 'vendor-driver-dashboard',
          component: DriverDashboard
        },
        {
          path: base+'vendor/driver/details/:driver_id',
          name: 'vendor-driver-details',
          component: DriverDetails
        },
        {
          path: base+'vendor/vendor-discount',
          name: 'vendor.discount',
          component: VendorDiscount
        },
        // {
        //   path: base+'vendor/finance-list',
        //   name: 'vendor.finance.list',
        //   component: RestaurantFinance
        // },
        {
          path: base+'vendor/reports',
          name: 'vendor.reports.list',
          component: Reports
        },
        {
          path: base+'vendor/reports/vendor_rating_report/dine',
          name: 'vendor.dine_feedback.report',
          component: VendorDineRatingReport
        },
        {
          path: base+'vendor/reports/sales_report',
          name: 'vendor.reports.sale.report',
          component: SalesReport
        },
        {
          path: base+'vendor/reports/item_report',
          name: 'vendor.reports.item.report',
          component: ItemReport
        },
        {
          path: base+'vendor/profile',
          name: 'vendor.profile',
          component: VendorProfile
        },
        // {
        //   path: base+'vendor/operation-time',
        //   name: 'vendor.timeslot',
        //   component: OperationTime
        // },
        // {
        //   path: base+'vendor/selling-timeslot',
        //   name: 'vendor.selling.timeslot',
        //   component: SellingTimeSlot
        // },
        {
          path: base+'vendor/vendor-rating',
          name: 'vendor.review',
          component: VendorRating
        },
        {
          path: base+'vendor/vendor-category',
          name: 'vendor.restaurant.category',
          component: VendorCategory
        },
        // {
        //   path: base+'vendor/finance/month',
        //   name: 'vendor.restaurant.finance.list',
        //   component: RestaurantFinance
        // },
        // {
        //   path: base+'vendor/finance',
        //   name: 'vendor.finance',
        //   component: VendorFinance
        // },
        {
          path: base+'vendor/forgot-password',
          name: 'forgot-password',
          component: ForgotPassword
        },
        {
          path: base+'vendor/vendor-tag',
          name: 'vendor-tag',
          component: VendorTag
        },
        {
          path: base+'vendor/chowman-pay',
          name: 'vendor.chowman-pay-list',
          component: ChowmanPay
        },
        {
          path: base+'vendor/reports/chowman-pay-report',
          name: 'vendor.chowman-pay-report',
          component: ChowmanPayReport
        }
        
        
        
      // ...resturantRoutes
      
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
  });
  router.beforeEach((to, from, next) => {
    $('body').addClass('sidebar-disable').removeClass('sidebar-enable');
    var noauth = ['login','forgot-password','vendor.login','menu-list','feedbacks','special-coupon-list'];
    if (!noauth.includes(to.name) ){
      if(!Vue.prototype.$auth || !Vue.prototype.$auth.setting){
        Vue.prototype.$auth.setUser((type)=> {
          if(noauth.includes(type)) next({name: type})
          else {
            next()
          }
        });
      }
      else {
        next()
      }
    } 
    else {
      next()
    }
  });

export default router;