<script>
import { required } from "vuelidate/lib/validators";
import { settingService } from '../../../services';
import { success_message, error_message } from '../../../Helper/helper';
import config from '../../../config';
/**
 * Starter component
 */
export default {
  data() {
    return {
      config: config,
      setting: [],
      setting: {
        tax_name_primary_lang: '',
        tax_name_secondary_lang: '',
        restaurant_name: '',
        restaurant_name_sec_lang: '',
        driver_name: '',
        driver_name_sec_lang: '',
        recommended_menu_name: '',
        recommended_menu_name_sec_lang: '',
        cuisine_name: '',
        cuisine_name_sec_lang: '',
        terms_and_conditions_key:''
      }
    };
  },
  validations: {
    setting: {
      tax_name_primary_lang: {
        required,
      },
      restaurant_name: {
        required
      },
      driver_name: {
        required
      },
      recommended_menu_name: {
        required
      },
      cuisine_name: {
        required
      },
      terms_and_conditions_key: {
        required
      }
    },
  },
  mounted() {
      this.getSetting();
  },
  methods: {
      getSetting(){
      settingService.getSetting()
      .then(response => {
       this.setting = response.data.result;
      });
    },
    generalIt(){
      this.$v.setting.$touch();
      if (this.$v.setting.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("tax_name_primary_lang", this.setting.tax_name_primary_lang);
        fd.append("tax_name_secondary_lang", this.setting.tax_name_secondary_lang);
        fd.append("restaurant_name", this.setting.restaurant_name);
        fd.append("restaurant_name_sec_lang", this.setting.restaurant_name_sec_lang);
        fd.append("driver_name", this.setting.driver_name);
        fd.append("driver_name_sec_lang", this.setting.driver_name_sec_lang);
        fd.append("recommended_menu_name", this.setting.recommended_menu_name);
        fd.append("recommended_menu_name_sec_lang", this.setting.recommended_menu_name_sec_lang);
        fd.append("cuisine_name", this.setting.cuisine_name);
        fd.append("cuisine_name_sec_lang", this.setting.cuisine_name_sec_lang);
        fd.append("terms_and_conditions_key", this.setting.terms_and_conditions_key);
        
        settingService.updateGeneralTerminology(fd).then((response) => {
          if (response.data.code === 200) {
              success_message(response.data.message);
            } else {
              error_message(response.data.message);
            }
        });
      }
    }
  }
};
</script>
<template>
  <b-form @submit.prevent="generalIt">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Tax Type Language', { lang: $auth.langauges[0].name }) }}</label>
                <input
                  v-model="setting.tax_name_primary_lang"
                  id="tax_name_primary_lang"
                  name="tax_name_primary_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Tax Type Language', { lang: $auth.langauges[0].name })"
                  class="form-control"
                  :class="{ 'is-invalid': $v.setting.tax_name_primary_lang.$error }"
                />
                 <div v-if="$v.setting.tax_name_primary_lang.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.tax_name_primary_lang.required">{{ trans.get('__JSON__.Please enter tax type')}}</span>
                  </div>
               </div>
              </div>
              <div class="col-md-6 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
              </div>
               <div class="col-md-6" v-else>
                <div class="form-group">
                <label>{{ trans.trans('__JSON__.Tax Type Language', { lang: $auth.langauges[1].name }) }}</label>
                <input
                  v-model="setting.tax_name_secondary_lang"
                  id="tax_name_secondary_lang"
                  name="tax_name_secondary_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Tax Type Language', { lang: $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
              </div>

              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Vendor Name', {vendor: $auth.getRestaurantName()  , lang: $auth.langauges[0].name }) }}</label>
                <input
                  v-model="setting.restaurant_name"
                  id="restaurant_name"
                  name="restaurant_name"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Vendor Name langauge', { vendor: $auth.setting.restaurant_name , lang: $auth.langauges[0].name })"
                  class="form-control"
                  :class="{ 'is-invalid': $v.setting.restaurant_name.$error }"
                />
                <div v-if="$v.setting.restaurant_name.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.restaurant_name.required">{{ trans.get('__JSON__.Please enter vendor name',{vendor: $auth.setting.restaurant_name})}}</span>
                  </div>
               </div>
              </div>
              <div class="col-md-6 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
              </div>
               <div class="col-md-6" v-else>
                <div class="form-group">
                <label>{{ trans.trans('__JSON__.Vendor Name', { vendor: $auth.setting.restaurant_name_sec_lang, lang: $auth.langauges[1].name }) }}</label>
                <input
                  v-model="setting.restaurant_name_sec_lang"
                  id="restaurant_name_sec_lang"
                  name="restaurant_name_sec_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Vendor Name langauge', { vendor: $auth.setting.restaurant_name_sec_lang, lang: $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Driver Name Language', {driver: $auth.getDriverName(),  lang: $auth.langauges[0].name }) }}</label>
                <input
                  v-model="setting.driver_name"
                  id="driver_name"
                  name="driver_name"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Driver Name Language', { driver: $auth.setting.driver_name, lang: $auth.langauges[0].name })"
                  class="form-control"
                  :class="{ 'is-invalid': $v.setting.driver_name.$error }"
                />
                 <div v-if="$v.setting.driver_name.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.driver_name.required">{{ trans.get('__JSON__.Please enter driver name',{ driver: $auth.setting.driver_name })}}</span>
                  </div>
               </div>
              </div>
              <div class="col-md-6 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
              </div>
               <div class="col-md-6" v-else>
                <div class="form-group">
                <label>{{ trans.trans('__JSON__.Driver Name Language', { driver: $auth.setting.driver_name_sec_lang, lang: $auth.langauges[1].name }) }}</label>
                <input
                  v-model="setting.driver_name_sec_lang"
                  id="driver_name_sec_lang"
                  name="driver_name_sec_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Driver Name Language', { driver: $auth.setting.driver_name_sec_lang, lang: $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Recommended Menu Name Language', { lang: $auth.langauges[0].name }) }}</label>
                <input
                  id="recommended_menu_name"
                  name="recommended_menu_name"
                  v-model="setting.recommended_menu_name"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Recommended Menu Name Language', { lang: $auth.langauges[0].name })"
                  class="form-control"
                   :class="{ 'is-invalid': $v.setting.recommended_menu_name.$error }"
                />
                 <div v-if="$v.setting.recommended_menu_name.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.recommended_menu_name.required">{{ trans.get('__JSON__.Please enter menu name',{ lang: $auth.langauges[0].name})}}</span>
                  </div>
               </div>
              </div>
              <div class="col-md-6 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
              </div>
               <div class="col-md-6" v-else>
                <div class="form-group">
                <label>{{ trans.trans('__JSON__.Recommended Menu Name Language', { lang: $auth.langauges[1].name }) }}</label>
                <input
                  id="recommended_menu_name_sec_lang"
                  name="recommended_menu_name_sec_lang"
                  v-model="setting.recommended_menu_name_sec_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Recommended Menu Name Language', { lang: $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
              </div>
               <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Cuisine Name Language', { lang: $auth.langauges[0].name }) }}</label>
                <input
                  id="cuisine_name"
                  name="cuisine_name"
                  v-model="setting.cuisine_name"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Cuisine Name Placeholder', { lang: $auth.langauges[0].name })"
                  class="form-control"
                   :class="{ 'is-invalid': $v.setting.cuisine_name.$error }"
                />
                 <div v-if="$v.setting.cuisine_name.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.cuisine_name.required">{{ trans.get('__JSON__.Please Enter Cuisine Name Error')}}</span>
                  </div>
               </div>
              </div>
              <div class="col-md-6 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
              </div>
               <div class="col-md-6" v-else>
                <div class="form-group">
                <label>{{ trans.trans('__JSON__.Cuisine Name Other Language', { lang: $auth.langauges[1].name }) }}</label>
                <input
                  id="cuisine_name_sec_lang"
                  name="cuisine_name_sec_lang"
                  v-model="setting.cuisine_name_sec_lang"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Cuisine Name Placeholder', { lang: $auth.langauges[1].name })"
                  class="form-control"
                />
               </div>
              </div>

              <div class="col-md-6">
                <div class="form-group" v-if="$auth.setting">
                <label>{{ trans.trans('__JSON__.Booking T & C') }}</label>
                <textarea
                  id="terms_and_conditions_key"
                  name="terms_and_conditions_key"
                  v-model="setting.terms_and_conditions_key"
                  type="text"
                  :placeholder="trans.trans('__JSON__.Enter Booking T & C')"
                  class="form-control"
                   :class="{ 'is-invalid': $v.setting.terms_and_conditions_key.$error }"
                >
                </textarea>
                 <div v-if="$v.setting.terms_and_conditions_key.$error" class="invalid-feedback">
                    <span v-if="!$v.setting.terms_and_conditions_key.required">{{ trans.get('__JSON__.Please Enter Terms And Conditions')}}</span>
                  </div>
               </div>
              </div>

            </div>
            
            <div class="btn-update">
              <div class="col-sm-6">
                <button
                v-if="$auth.hasPermission(config.permissions.terminology_write)"
                  type="submit"
                  class="btn btn-primary mr-1 mt-3"
                >{{ trans.get('__JSON__.Update') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>