<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Multiselect from "vue-multiselect";
import config from "../../config";
import { error_message, success_message, get_user } from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { required, helpers } from "vuelidate/lib/validators";
import { vendorService, settingService, driverService } from "../../services";

/**
 * update-profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DropzonePopup,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      title: this.trans.get("__JSON__.Profile"),
      config: config,
      restaurant_id: "",
      search: "",
      isLoading1: false,
      selectedDrivers: [],
      restaurant: {
        selectedDrivers: "",
      },
    };
  },
  validations: {
    restaurant: {
      selectedDrivers: {
        required,
      },
    },
  },
  mounted() {
    this.getSetting();
    this.getRestaurantById();
    this.asyncFindDriver();
    this.vendor_id = get_user().vendor_id || 40818;
  },
  methods: {
    asyncFindDriver(query) {
      this.isLoading1 = true;
      driverService.getDriverList({ search: query }).then((response) => {
        this.selectedDrivers = response.data.result;
        this.isLoading1 = false;
      });
    },
    clearAllDriver() {
      this.selectedDrivers = [];
    },
    getSetting() {
      settingService.getSetting().then((response) => {
        this.vendor_currency = response.data.result.currency;
      });
    },
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          this.restaurant_id = response.data.result.restaurant_id;
          this.restaurant.selectedDrivers =
            response.data.result.restaurant_drivers ?? [];
        });
    },
    syncDriver() {
      this.$v.restaurant.$touch();
      if (this.$v.restaurant.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("restaurant_id", this.restaurant_id);
        this.restaurant.selectedDrivers
          .map((item) => item.id)
          .map((item, index) => {
            fd.append("selectedDrivers[" + index + "]", item);
          });
        driverService.syncRestaurantDriver(fd).then((response) => {
          if (response.data.code === 200) {
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="syncDriver">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{
                  trans.trans("__JSON__.Driver", {
                    driver: $auth.setting.driver_name,
                  })
                }}
              </h4>
              <div class="row">
                <div class="col-lg-12">
                  <div class="search-box mr-2 d-inline-block w-100">
                    <div class="position-relative">
                      <multiselect
                        v-model="$v.restaurant.selectedDrivers.$model"
                        id="ajax_multiselect_option"
                        label="name"
                        track-by="id"
                        :placeholder="trans.trans('__JSON__.Search')"
                        open-direction="bottom"
                        :options="selectedDrivers"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading1"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :options-limit="1500"
                        :limit="100"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                        @search-change="asyncFindDriver"
                        :class="{
                          'is-invalid': $v.restaurant.selectedDrivers.$error,
                        }"
                      >
                        <template slot="tag" slot-scope="{ option, remove }">
                          <span class="custom__tag">
                            <span>{{ option.name }}</span>
                            <span class="custom__remove" @click="remove(option)"
                              >❌</span
                            >
                          </span>
                        </template>
                        <template slot="clear" slot-scope="props">
                          <div
                            class="multiselect__clear"
                            v-if="selectedDrivers.length"
                            @mousedown.prevent.stop="
                              clearAllDriver(props.search)
                            "
                          ></div>
                        </template>
                        <template slot="noOptions">
                          <span>{{ trans.get("__JSON__.Search...") }}.</span>
                        </template>
                        <template slot="noResult">
                          <span
                            >{{
                              trans.get(
                                "__JSON__.Oops! No elements found. Consider changing the search query"
                              )
                            }}.</span
                          >
                        </template>
                      </multiselect>
                      <div
                        v-if="$v.restaurant.selectedDrivers.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.restaurant.selectedDrivers.required"
                          >{{ trans.get("__JSON__.Select Driver") }}.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <button
                    type="submit"
                    v-if="
                      $auth.hasPermission(config.permissions.merchant_write) &&
                      !$auth.partner_login
                    "
                    class="btn btn-primary mr-1 mt-3"
                    :disabled="disable"
                  >
                    {{ trans.trans("__JSON__.Update") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
