<script>
import pagination from "laravel-vue-pagination";

import config from "../../../config";
import Multiselect from "vue-multiselect";
import { success_message, error_message } from "../../../Helper/helper";

import {
  required,requiredIf
} from "vuelidate/lib/validators";
import { apiMessageTextService,bannerService } from "../../../services";
/**
 * Starter component
 */
export default {
  components: { pagination,Multiselect,bannerService },
  data() {
    return {
      addModal: false,
      sortBy: "id",
      limit: 1,
      config: config,
      apitextmessage: [],
      paginations: {},
      resturant: [],
      selectedRestaurant: [],
      weather_message : "",
      typeform: {
        selectedRestaurant : "",
        weather_message : "",
      },
      
    };
  },
  validations: {
    typeform: {
      selectedRestaurant: {
        required
      },
      weather_message:{
        required
      },
    }
  },
  mounted() {
  
  },
  methods: {
   
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant(query).then((response) => {
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selectedRestaurant = [];
    },
    updateWeatherMessage()
    {
      this.$v.$touch();
      // Validate both selectedRestaurant and weather_message
      if (this.$v.typeform.selectedRestaurant.$invalid || this.$v.typeform.weather_message.$invalid) {
        return;
      } 

      const fd = new FormData();
      this.typeform.selectedRestaurant
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("selectedRestaurant[" + index + "]", item);
      });
        
      fd.append("weather_message", this.typeform.weather_message);
      apiMessageTextService.updateWeatherMessage(fd).then((response) => {
        if (response.data.code === 200) {
          success_message(response.data.message);
          location.reload();
          
        } else {
          error_message(response.data.message);
        }
      }).catch((error) => {
          console.error('Update error:', error);
          error_message('Something went wrong');
      });
    },
    clearWeatherMessage()
    {
      if (this.$v.typeform.selectedRestaurant.$invalid) {
		this.$v.typeform.weather_message.$reset();
        return;
      } 
      this.$v.typeform.weather_message.$reset();

      const fd = new FormData();
      this.typeform.selectedRestaurant
          .map((item) => item.restaurant_id)
          .map((item, index) => {
            fd.append("selectedRestaurant[" + index + "]", item);
      });

      apiMessageTextService.clearWeatherMessage(fd).then((response) => {
        if (response.data.code === 200) {
          success_message(response.data.message);
        } else {
          error_message(response.data.message);
        }
      });
    }
   
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body settings-main">
			<b-form @submit.prevent="updateWeatherMessage">
				<div class="row">
					<div class="col-lg-12">
						<div class="row">
							<div class="col-lg-4">
								<div class="search-box mr-2 d-inline-block w-100">
									<div class="position-relative">
										<multiselect
										v-model="$v.typeform.selectedRestaurant.$model"
										id="ajax"
										v-bind:disabled="typeform.checkbox"
										label="name"
										track-by="name"
										:placeholder="trans.trans('__JSON__.Vendor Search',{ vendor: $auth.setting.restaurant_name })"
										open-direction="bottom"
										:options="resturant"
										:multiple="true"
										:searchable="true"
										:internal-search="true"
										:clear-on-select="false"
										:close-on-select="false"
										:options-limit="30"
										:limit="100"
										:max-height="600"
										:show-no-results="false"
										:hide-selected="true"
										@search-change="asyncFind"
										:class="{ 'is-invalid': $v.typeform.selectedRestaurant.$error }"
										
										>
										<template slot="tag" slot-scope="{ option, remove }">
											<span class="custom__tag">
											<span>{{ option.name }}</span>
											<span class="custom__remove" @click="remove(option)">❌</span>
											</span>
										</template>
										<template slot="clear" slot-scope="props">
											<div
											class="multiselect__clear"
											v-if="selectedRestaurant.length"
											@mousedown.prevent.stop="clearAll(props.search)"
											></div>
										</template>
										<template slot="noOptions">
											<span                              
											>{{ trans.get('__JSON__.Search...') }}.</span>
										</template>
										<template slot="noResult">
											<span
											>{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
										</template>
										</multiselect>
										<div
										v-if=" $v.typeform.selectedRestaurant.$error"
										class="invalid-feedback"
										>
										<span
											v-if="!$v.typeform.selectedRestaurant.required"
										>{{ trans.get('__JSON__.Vendor Select Error',{ vendor: $auth.setting.restaurant_name }) }}.</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<b-button
								variant="danger"
								v-if="$auth.hasPermission(config.permissions.terminology_write)"
								@click="clearWeatherMessage"
								:title="trans.get('__JSON__.Click to clear weather message for the selected restaurant')"
								>{{ trans.get('__JSON__.Clear Message') }}</b-button>
							</div>
						</div>
					</div>
				
					<div class="col-md-6">
					<label>{{ trans.trans('__JSON__.Weather Message') }}</label>
					<textarea
						v-model="$v.typeform.weather_message.$model"
						id="weather_message"
						name="weather_message"
						type="text"
						:placeholder="trans.trans('__JSON__.Weather Message')"
						class="form-control"
						:class="{ 'is-invalid': $v.typeform.weather_message.$error }"
					></textarea>
					<div
							v-if="$v.typeform.weather_message.$error"
							class="invalid-feedback cuisine-img"
						>
							<span
							v-if="!$v.typeform.weather_message.required"
							>{{ trans.get('__JSON__.Please enter Weather Message') }}.</span>
						</div>
					</div>

					<div class="col-md-12">
						<div class="btn-update">
							<div class="col-sm-6">
								<b-button
								variant="primary"
								v-if="$auth.hasPermission(config.permissions.terminology_write)"
								@click="updateWeatherMessage()"
								class="float-left mr-1 mt-3"
								>{{ trans.get('__JSON__.Update') }}</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-form>
        </div>
      </div>
    </div>

    <!-- Add Api Text Modal Start -->

    <!-- Add Add Api Text Modal End -->
  </div>
</template>