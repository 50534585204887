<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import General from "./terminology/general.vue";
import ApplicationMessage from "./terminology/application-message.vue";
import OrderNotificationText from "./terminology/order-notification-text.vue";
import SmsNotificationText from "./terminology/sms-notification-text.vue";
import Referral from "./terminology/referral.vue";
import PromoText from "./terminology/promo-text.vue";
import WeatherMessage from "./terminology/weather-message.vue";
import DineInMessage from "./terminology/dinein-message.vue";
import OrderStatus from "./terminology/order-status.vue";
/**
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, General, ApplicationMessage, OrderNotificationText,SmsNotificationText, Referral,PromoText,WeatherMessage ,DineInMessage,OrderStatus},
  data() {
    return {
    };
  },
};
</script>

<template>
  <Layout>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-text"></i>
              </span>
            </div>
             <h4 class="mb-0 font-size-18">{{ trans.trans('__JSON__.TERMINOLOGY') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
             <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#all-order" role="tab">{{ trans.get('__JSON__.General') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#processing" role="tab">{{ trans.get('__JSON__.Application Messages') }}</a>
              </li>
               <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#order-text" role="tab">{{ trans.get('__JSON__.Order Notification Text') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#sms-text" role="tab">{{ trans.get('__JSON__.SMS Notification Text') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#referral-text" role="tab">{{ trans.get('__JSON__.Referral Text') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#promo-text" role="tab">{{ trans.get('__JSON__.Promocode Text') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#weather-message" role="tab">{{ trans.get('__JSON__.Weather Message') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#dinein-message" role="tab">{{ trans.get('__JSON__.DineIn Message') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#order-status" role="tab">{{ trans.get('__JSON__.Order Status Promotion Text') }}</a>
              </li>
            </ul>

             <!-- Tab panes -->
            <div class="tab-content p-3">
              <div class="tab-pane active" id="all-order" role="tabpanel">
                <General />
              </div>
              <div class="tab-pane" id="processing" role="tabpanel">
                <ApplicationMessage />
              </div>
              <div class="tab-pane" id="order-text" role="tabpanel">
                <OrderNotificationText/>
              </div>
              <div class="tab-pane" id="sms-text" role="tabpanel">
                <SmsNotificationText/>
              </div>
              <div class="tab-pane" id="referral-text" role="tabpanel">
                <Referral/>
              </div>
              <div class="tab-pane" id="promo-text" role="tabpanel">
                <PromoText/>
              </div>
              <div class="tab-pane" id="weather-message" role="tabpanel">
                <WeatherMessage/>
              </div>
              <div class="tab-pane" id="dinein-message" role="tabpanel">
                <DineInMessage/>
              </div>
              <div class="tab-pane" id="order-status" role="tabpanel">
                <OrderStatus/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>