<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { menuService } from "../../services";
import draggable from "vuedraggable";
import { error_message, success_message } from "../../Helper/helper";
import { routes } from "../../config/api_routes";
import DropzonePopup from '../../components/widgets/dropzone-popup';

export default {
    components: { pagination, menuService, draggable, DropzonePopup },
    data() {
        return {
            config: config,
            categories: [],
            category_media_path: '',
            loading: true,
            imageModal: false,
            image_data: {},
            typeform: {
                name: '',
                image: ''
            },
            image_delete_url: routes.commonCategoryImageDeleteUrl,
        };
    },
    mounted() {
        this.getCategory();
    },
    methods: {
        getCategory() {
            this.loading = true;
            menuService.getCommonCategory()
                .then((response) => {
                    this.loading = false;
                    if (response && response.data && response.data.code == 200) {
                        this.categories = response.data.result.data;
                        this.category_media_path = response.data.result.category_media_path;
                    }
                }).catch((error) => {
                    this.loading = false;
                });
        },
        updateCategoryOrder() {
            menuService.arrangeCategory({
                categories: this.categories
            }).then((response) => {
                if (response && response.data && response.data.code != 200) {
                    error_message(response.data.message);
                }
            });
        },
        addItemImage(index) {
            this.imageModal = true;
            this.image_data = (this.categories[index]) ? this.categories[index] : {};
            this.typeform.name = this.image_data.name ?? "";
        },
        onImagedeleted() {
            this.image_data.image = "";
        },
        addMenuImage() {
            menuService.addUpdateCommonCategory(this.typeform).then((response) => {
                if (response && response.data && response.data.code == 200) {
                    this.imageModal = false;
                    success_message(response.data.message);
                    this.typeform.image = "";
                    this.getCategory()
                } else {
                    this.typeform.image = "";
                    this.imageModal = false;
                    error_message(response.data.message);
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between icon-main-page">
                    <div class="page-title-left">
                        <div class="avatar-xs mr-3">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i class="bx bx-briefcase"></i>
                            </span>
                        </div>
                        <h4 class="mb-0 font-size-18">{{ trans.get("__JSON__.Category Image") }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <div class="row list-table-main">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 table-main-list table-responsive">
                                <table class="table table-centered table-nowrap table-hover">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" style="width: 120px;">#</th>
                                            <th scope="col">Name</th>
                                        </tr>
                                    </thead>
                                    <template v-if="loading">
                                        <tbody>
                                            <tr>
                                                <td colspan="2">
                                                    <div class="text-center text-danger my-2">
                                                        <b-spinner class="align-middle"></b-spinner>
                                                        <strong>{{ trans.get("__JSON__.Loading...") }}</strong>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                    <template v-else-if="!loading && categories.length > 0">
                                        <draggable group="categories" :list="categories" @end="updateCategoryOrder"
                                            v-model="categories" tag="tbody">
                                            <tr v-for="(category, index) in categories" :key="index">
                                                <td>
                                                    <span class="text-danger font-size-16" v-bind:id="category.name">
                                                        <img class="vendor-img avatar-xs" v-if="category.image"
                                                            :src="category_media_path + '/original/' + category.image"
                                                            alt height="30"
                                                            @error="$event.target.src = config.basepath + 'no-img.png'"
                                                            @click="addItemImage(index)" />
                                                        <img class="vendor-img avatar-xs" v-else
                                                            :src="config.basepath + 'no-img.png'" alt
                                                            @click="addItemImage(index)" />
                                                    </span>
                                                </td>
                                                <td>
                                                    {{ category.name }}
                                                </td>
                                            </tr>
                                        </draggable>
                                    </template>
                                    <template v-else-if="!loading && categories.length == 0">
                                        <tbody>
                                            <tr>
                                                <td colspan="2" class="text-center">No Data Found !</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

        <b-modal v-if="$auth.hasPermission(config.permissions.category_image_write)" v-model="imageModal"
            :key="imageModal" scrollable :title="trans.get('__JSON__.Upload Category Images')" title-class="font-18"
            centered>
            <form @submit.prevent="addMenuImage">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>{{ trans.get('__JSON__.Item Image') }}</label>
                            <dropzone-popup v-if="!image_data.image" v-model="typeform.image" type="file" id="image"
                                name="image" acceptedFiles=".jpeg,.jpg,.png" />
                            <dropzone-popup v-else v-model="typeform.image" id="image" acceptedFiles=".jpeg,.jpg,.png"
                                :editable-images="image_data.image" :media-path="category_media_path + '/original'"
                                :delete-path="image_delete_url" @image-deleted="this.onImagedeleted" />
                        </div>
                    </div>
                </div>
                <input type="hidden" name="restaurant_menu_item_id" v-model="image_data.name">
            </form>
            <template slot="modal-footer">
                <div class="w-100">
                    <b-button variant="primary" class="float-right" @click="addMenuImage">{{
                        trans.get('__JSON__.Upload') }}</b-button>
                    <b-button class="float-left" @click="imageModal = false">{{ trans.get('__JSON__.Close')
                        }}</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>