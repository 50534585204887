<script>
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import { settingService } from "../../../services";
import { required,numeric,requiredIf,helpers } from "vuelidate/lib/validators";
import { success_message } from '../../../Helper/helper';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Swal from "sweetalert2";
import config from '../../../config';
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,2}|\d+)$/)

const admin_default_msg = JSON.stringify([
          {
            heading: '',
            messages: [
              '',
            ]
          }
        ]);
/**
 *  component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, DatePicker },
  data() {
    return {
      config: config,
      checked: true,
      checked1: true,
      checked2: true,
      value1: null,
      system_start_time: null,
      showTimePanel: false,
      showTimePanelend: false,
      setting:[],
      payment: [
        'Cash On Delivery',
        'Both',
        'PAYMENT ONLINE',
        'RESTAURANT SELECT PAYMENT OPTION'
      ],
       errors: '',
       app_signin_page: [{ facebook: "1", google: "1", phone: "1", apple: "1" }],
      setting: {
        item_counts: "",
        min_order_value: "",
        restaurant_kilometer:"",
        customer_default_msg:[],
        admin_default_msg:JSON.parse(admin_default_msg),
        custom_item: [{name:''}],
        chowman_pay:'inactive',
        gift_voucher:'inactive',
        minimum_voucher_amount:2000,
       }, 
    };
  }, validations: {
    setting: {
      cgst:{
        required,
        floating,
      },
      sgst:{
        required,
        floating,
      },
      igst:{
        required,
        floating,
      },      
      restaurant_kilometer: {
        required,
        numeric,
      },
      min_order_value: {
        required,
        numeric,
      },
      item_counts: {
        required,
        numeric,
      },
      minimum_voucher_amount: {
        required,
        numeric,
      },
      is_delay_mail_minutes:{
        required,
        numeric,
      },
      not_available_reason: {
        required: requiredIf(function(form){
        	return !this.setting.is_system_available;
        })
      //  requiredIf:() => !this.setting.is_system_available,
      },
 
    },
  },
   mounted() {
    this.getSetting();
  },
  methods:{
    getSetting(){
      settingService.getSetting()
      .then(response => {
       this.setting = response.data.result;
         if (
          this.setting.app_signin_page &&
          this.setting.app_signin_page !== ""
          && this.setting.app_signin_page.length > 0
        ) {
          this.app_signin_page = this.setting.app_signin_page;
        }
       this.setting.custom_item = this.setting.custom_item ? response.data.result.custom_item : [{name:''}];
       this.setting.customer_default_msg = response.data.result.customer_default_msg? response.data.result.customer_default_msg :[];
       this.setting.admin_default_msg = response.data.result.admin_default_msg ? response.data.result.admin_default_msg : JSON.parse(admin_default_msg);
       this.setting.chowman_pay = response.data.result.chowman_pay ? response.data.result.chowman_pay : 'inactive'
       this.setting.gift_voucher = response.data.result.gift_voucher ? response.data.result.gift_voucher : 'inactive'
       this.system_start_time = response.data.result.system_start_time;
      });
    },
    open_system(){
      this.setting.is_system_available = 'Yes';
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "success",
      //   showCancelButton: true,
      //   confirmButtonColor: "#34c38f",
      //   cancelButtonColor: "#f46a6a",
      //   confirmButtonText: "Ok"
      // }).then(result => {
      //   if (result.value) {
      //     Swal.fire("Ok!", "success");
      //   }
      // });
    },
    close_system(){
      this.setting.is_system_available = 'No';
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#34c38f",
      //   cancelButtonColor: "#f46a6a",
      //   confirmButtonText: "Ok"
      // }).then(result => {
      //   if (result.value) {
      //     Swal.fire("Deleted!", "Your file has been deleted.", "success");
      //   }
      // });
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    toggleTimePanelend() {
      this.showTimePanelend = !this.showTimePanelend;
    },
    handleOpenChangeend() {
      this.showTimePanelend = false;
    },
    generalIt(){
      this.$v.$touch();
      
      this.setting.admin_default_msg = this.setting.admin_default_msg.reduce(
        (acc, item) => {
          if(item.heading.trim() !== ''){
            item.messages = item.messages.filter((m => m && m.trim() !== ''));
            if(item.messages.length > 0){
              return acc.concat(item)
            }
          }
          return acc;
        },
        []
      )
      
      console.log(this.$v.setting.$invalid);
      if (this.$v.setting.$invalid) {
           console.log("if");
        return;
      } else {
        const fd = new FormData();
        fd.append("custom_item", JSON.stringify(this.setting.custom_item));
        fd.append("system_start_time", this.setting.system_start_time);
        fd.append("system_end_time", this.setting.system_end_time);
        fd.append("restaurant_kilometer", this.setting.restaurant_kilometer);
        fd.append("service_tax",this.setting.service_tax);
        fd.append('is_system_available',this.setting.is_system_available);
        fd.append("not_available_reason",this.setting.not_available_reason);
        fd.append("not_available_reason_secondary_lang",this.setting.not_available_reason_secondary_lang);
        fd.append("included_tax", this.setting.included_tax);
        fd.append("is_pickup_enable", this.setting.is_pickup_enable);
        fd.append('is_delay_mail_enable',this.setting.is_delay_mail_enable);
        fd.append('is_delay_mail_minutes',this.setting.is_delay_mail_minutes);
        fd.append("is_same_day_delivery", this.setting.is_same_day_delivery);
        fd.append("min_order_value", this.setting.min_order_value);
        fd.append("restaurant_payment_option",this.setting.restaurant_payment_option);
        fd.append("restaurant_service_tax",this.setting.restaurant_service_tax);
        fd.append("customer_default_msg",JSON.stringify(this.setting.customer_default_msg.filter(m => m && m.trim() !== '')));
        fd.append("admin_default_msg",JSON.stringify(this.setting.admin_default_msg));
        fd.append("payment",this.setting.payment);
        fd.append("chowman_pay",this.setting.chowman_pay);
        fd.append("gift_voucher",this.setting.gift_voucher);
        fd.append("minimum_voucher_amount",this.setting.minimum_voucher_amount);
        fd.append("item_counts",this.setting.item_counts);
        fd.append("cgst",this.setting.cgst);
        fd.append("sgst",this.setting.sgst);
        fd.append("igst",this.setting.igst);
        fd.append("app_signin_page", JSON.stringify(this.app_signin_page));
        fd.append("type",1);
       settingService.updateSetting(fd).then((response) => {
          if (response.data.code === 200) {
            if(response.data.result){
              this.$auth.updateSetting(response.data.result.settings)
            }
            success_message(response.data.message);
          } else {
              error_message(response.data.message);
          }
        });
      }
    },
    removeCustomeField(index){
      if (this.setting.custom_item[index] && confirm('Are you sure...!! It will effect to items')) {
        this.setting.custom_item.splice(index,1);
      }
    },
    addChatMessageField(){
      const msg = this.setting.customer_default_msg;
      msg.push('')
      this.setting = {
        ...this.setting,
        customer_default_msg: msg,
      }
    },
    addAdminChatHeadingField(){
      const msg = this.setting.admin_default_msg;
      msg.push(JSON.parse(admin_default_msg)[0])
      this.setting = {
        ...this.setting,
        admin_default_msg: msg,
      }
    },
    addAdminChatMessageField(index){
      const msg = this.setting.admin_default_msg;
      msg[index].messages.push('')
      this.setting = {
        ...this.setting,
        admin_default_msg: msg,
      }
    },
    
  },
};
</script>
<template>
<b-form @submit.prevent="generalIt">
    <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body setting-main">
                <div class="row section-main">
                    <div class="col-md-4">
                        <div class="setting-heading">  
                            <h4>Business Operation Availability</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor.</p> -->
                        </div>
                    </div>
                        <div class="col-md-8">
                            <div class="setting-data">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6>Start Time</h6>
                                        <!-- <b-form-input id="date-time" value="10:00:00" type="time"></b-form-input> -->
                                        <!-- <b-form-input id="date-time" value="2019-08-19T13:45:00" format = "YYYY-MM-DD HH:mm:ss" type="datetime-local"></b-form-input> -->
                                        <!-- <b-form-input id="date-time" v-model="setting.system_start_time" type="datetime-local"></b-form-input> -->
                                      <date-picker
                                        name="system_start_time"
                                        id="system_start_time"
                                        placeholder="Select datetime"
                                        v-model="setting.system_start_time"
                                        type="datetime"
                                        value-type="format" 
                                        format="YYYY-MM-DD HH:mm:ss"
                                        :show-time-panel="showTimePanel"
                                        @close="handleOpenChange">
                                        <template v-slot:footer>
                                          <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                                            {{ showTimePanel ? 'select date' : 'select time' }}
                                          </button>
                                        </template>
                                      </date-picker>
                                    </div>
                                    <div class="col-md-6">
                                        <h6>End Time</h6>
                                        <!-- <b-form-input id="time1" value="18:00:00" type="time"></b-form-input> -->
                                        <!-- <b-form-input id="date-time1" value="2019-08-19T13:45:00" type="datetime-local"></b-form-input> -->
                                        <date-picker
                                        name="system_end_time"
                                        id="system_end_time"
                                        placeholder="Select datetime"
                                        v-model="setting.system_end_time"
                                          value-type="format"
                                          format="YYYY-MM-DD HH:mm:ss"
                                        type="datetime"
                                        :show-time-panel="showTimePanelend"
                                        @close="handleOpenChangeend">
                                        <template v-slot:footer>
                                          <button class="mx-btn mx-btn-text" @click="toggleTimePanelend">
                                            {{ showTimePanelend ? 'select date' : 'select time' }}
                                          </button>
                                        </template>
                                      </date-picker>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <h6>Business Operation : <span class="business-status" v-if="setting.is_system_available == 'Yes'" @click="close_system"> Running </span>
                                        <span class="business-closed-status" v-else> Closed </span></h6>
                                        <p>If you Pause Business Operation you will not able to take Any Orders</p>
                                        <button type="button" class="btn btn-danger" v-if="setting.is_system_available == 'Yes'" @click="close_system">Pause Operation</button>
                                        <button type="button" class="btn btn-success" v-else @click="open_system">Resume Operation</button>
                                    </div>
                                </div>
                                  <div class="tax-main" v-if="setting.is_system_available == 'No' || setting.is_system_available == false ">
                             <div class="row mt-4">
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6>Message</h6>
                                        </div>
                                        <div class="col-md-12 order-value">
                                             <!-- <input id="not_available_reason" name="not_available_reason" type="text" class="form-control" v-model="setting.not_available_reason"/> -->
                                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.Pause operation Message')" label-for="name">
                                          <b-form-input
                                            id="not_available_reason"
                                            name="not_available_reason"
                                            v-model="$v.setting.not_available_reason.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Pause operation Message')"
                                            :class="{ 'is-invalid':  $v.setting.not_available_reason.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.not_available_reason.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.not_available_reason.required"
                                            >{{ trans.get('__JSON__.Pause operation Message') }}.</span>
                                              <span
                                              v-if="!$v.setting.not_available_reason.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                 <div class="row mt-4">
                                    <div class="col-md-12 d-none" v-if="$auth.setting && $auth.langauges[1].name == 'No Secondary Language'">
                                    </div>
                                    <div class="col-md-12" v-else>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6>{{ trans.trans('__JSON__.Message In Secondary Language', { lang: $auth.langauges[1].name }) }}</h6>
                                        </div>
                                        <div class="col-md-12 order-value">
                                            <input id="not_available_reason_secondary_lang" name="not_available_reason_secondary_lang" type="number" class="form-control" v-model="setting.not_available_reason_secondary_lang"/>
                                        </div>
                                    </div>
                                </div>
                                
                             </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Tax Details</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6>All Items Price Included Tax</h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="swtich-data">
                                        <!-- <label class="switch"><input type="checkbox" id="togBtn" class="switch-on" name="included_tax" v-model="setting.included_tax"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label> -->
                                           <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="included_tax"
                                                name="included_tax"
                                                v-model="setting.included_tax"
                                                class="switch-on"
                                                 true-value="Yes"
                                                false-value="No"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>
                                    </div>
                                </div>
                             </div>
                            <div class="tax-main" >
                             <div class="row mt-4" v-if="setting.included_tax == 'No'">
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <div class="col-md-12" >
                                            <h6>Enter Tax Value (In Percentage)</h6>
                                        </div>
                                        <div class="col-md-6 order-value">
                                            <input id="restaurant_service_tax" name="restaurant_service_tax" type="number" class="form-control" v-model="setting.restaurant_service_tax"/>
                                        </div>
                                    </div>
                                </div>
                             </div>
                             <br>
                             <div class="row">
                                <div class="col-md-4">
                                    <b-form-group
                                      label="CGST"
                                      label-for="input-formatter"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="cgst"
                                        v-model="$v.setting.cgst.$model"
                                        placeholder="Enter CGST (%)"
                                        :class="{ 'is-invalid':  $v.setting.cgst.$error }"
                                      ></b-form-input>
                                      <div v-if=" $v.setting.cgst.$error" class="invalid-feedback">
                                        <span
                                          v-if="!$v.setting.cgst.required"
                                        >{{ trans.get('__JSON__.required',{attribute: 'CGST'}) }}.</span>
                                          <span
                                          v-if="!$v.setting.cgst.floating"
                                        >{{ trans.get('__JSON__.Enter valid value') }}.</span>
                                      </div>
                                    </b-form-group>
                                    
                                </div>
                                <div class="col-md-4">
                                    <b-form-group
                                      label="SGST"
                                      label-for="input-formatter"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="sgst"
                                        v-model="$v.setting.sgst.$model"
                                        placeholder="Enter SGST (%)"
                                        :class="{ 'is-invalid':  $v.setting.sgst.$error }"
                                      ></b-form-input>
                                      <div v-if=" $v.setting.sgst.$error" class="invalid-feedback">
                                        <span
                                          v-if="!$v.setting.sgst.required"
                                        >{{ trans.get('__JSON__.required',{attribute: 'SGST'}) }}.</span>
                                          <span
                                          v-if="!$v.setting.sgst.floating"
                                        >{{ trans.get('__JSON__.Enter valid value') }}.</span>
                                      </div>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4">
                                    <b-form-group
                                      label="IGST"
                                      label-for="input-formatter"
                                      class="mb-0"
                                    >
                                      <b-form-input
                                        id="sgst"
                                        v-model="$v.setting.igst.$model"
                                        placeholder="Enter IGST (%)"
                                        :class="{ 'is-invalid':  $v.setting.igst.$error }"
                                      ></b-form-input>
                                      <div v-if=" $v.setting.igst.$error" class="invalid-feedback">
                                        <span
                                          v-if="!$v.setting.igst.required"
                                        >{{ trans.get('__JSON__.required',{attribute: 'IGST'}) }}.</span>
                                          <span
                                          v-if="!$v.setting.igst.floating"
                                        >{{ trans.get('__JSON__.Enter valid value') }}.</span>
                                      </div>
                                    </b-form-group>
                                </div>
                             </div>

                            </div>
                          </div>
                        </div>
                    </div>


                    <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Mail Details</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6>Delay Auto mail sending</h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="swtich-data">
                                        <!-- <label class="switch"><input type="checkbox" name="is_pickup_enable" id="togBtn1" class="switch-on" v-model="setting.is_pickup_enable"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label> -->

                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_delay_mail_enable"
                                                name="is_delay_mail_enable"
                                                v-model="setting.is_delay_mail_enable"
                                                class="switch-on"
                                                true-value="true"
                                                false-value="false"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>
                                    </div>
                                 
                                </div>
                                <div class="col-md-6">
                                <b-form-group id="input-group-1" :label="trans.get('__JSON__.Delay Mail Minutes')" label-for="name">
                                </b-form-group>
                                </div>
                                  <div class="col-md-6">
                                          <b-form-input
                                            id="is_delay_mail_minutes"
                                            name="is_delay_mail_minutes"
                                            v-model="$v.setting.is_delay_mail_minutes.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Delay Mail Minutes')"
                                            :class="{ 'is-invalid':  $v.setting.is_delay_mail_minutes.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.is_delay_mail_minutes.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.is_delay_mail_minutes.required"
                                            >{{ trans.get('__JSON__.Delay Mail Minutes') }}.</span>
                                              <span
                                              v-if="!$v.setting.is_delay_mail_minutes.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                      </div>
                                       
                             </div>

                            </div>
                          </div>
                    </div>


                     <div class="row section-main">
                        <div class="col-md-4">
                            <div class="setting-heading">  
                                <h4>Application Settings</h4>
                                 <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor.</p> -->
                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6>Take Away Feature</h6>
                                    <p>If you Turn OFF, On Application it will not show Take away Option.</p>
                                </div>
                                <div class="col-md-6">
                                    <div class="swtich-data">
                                        <!-- <label class="switch"><input type="checkbox" name="is_pickup_enable" id="togBtn1" class="switch-on" v-model="setting.is_pickup_enable"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label> -->

                                         <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_pickup_enable"
                                                name="is_pickup_enable"
                                                v-model="setting.is_pickup_enable"
                                                class="switch-on"
                                                true-value="true"
                                                false-value="false"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>
                                    </div>
                                </div>
                             </div>
                             <div class="row mt-3">
                                <div class="col-md-6">
                                    <!-- <h6>Same Day Delivery Feature {{setting.is_same_day_delivery}}</h6> -->
                                    <h6>{{ trans.get('__JSON__.Same Day Delivery Feature') }}</h6>
                                    <p>{{ trans.get('__JSON__.If you Enable this option, User can place schedule order for same day')}}.</p>
                                </div>
                                <div class="col-md-6">
                                    <div class="swtich-data">
                                     <!--    <label class="switch">
                                            <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label> -->
                                            <b-form-group>
                                            <label class="switch">
                                                <input
                                                type="checkbox"
                                                id="is_same_day_delivery"
                                                name="is_same_day_delivery"
                                                v-model="setting.is_same_day_delivery"
                                                class="switch-on"
                                                true-value="1"
                                                false-value="0"
                                                />
                                                <div class="slider round">
                                                <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                                <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                                </div>
                                            </label>
                                            </b-form-group>

                                    </div>
                                </div>
                             </div>
                            <div class="tax-main">
                             <div class="row mt-4">
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <!-- <div class="col-md-12">
                                            <h6>Nearby Vendors Radius (In Kilometers)</h6>
                                        </div> -->
                                    <div class="col-md-6 order-value">
                                    
                                          <b-form-group id="input-group-1" :label="trans.get('__JSON__.Near By Restaurants Radius',{lang: $auth.setting && $auth.setting.restaurant_name})" label-for="name">
                                          <b-form-input
                                            id="restaurant_kilometer"
                                            name="restaurant_kilometer"
                                            v-model="$v.setting.restaurant_kilometer.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Near By Restaurants Radius',{lang: $auth.setting && $auth.setting.restaurant_name})"
                                            :class="{ 'is-invalid':  $v.setting.restaurant_kilometer.$error }"
                                          ></b-form-input>

                                          <div v-if=" $v.setting.restaurant_kilometer.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.restaurant_kilometer.required"
                                            >{{ trans.get('__JSON__.Near By Restaurants Radius',{lang: $auth.setting && $auth.setting.restaurant_name}) }}.</span>
                                              <span
                                              v-if="!$v.setting.restaurant_kilometer.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{ trans.get('__JSON__.Ordering Application will show vendors within this radius') }}.</p>
                                     </div>
                                    </div>
                                </div>
                                
                             </div>
                             <div class="row mt-4">
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <!-- <div class="col-md-12">
                                            <h6>Minimum Order Value</h6>
                                        </div> -->
                                    <div class="col-md-6 order-value">
                                            <b-form-group id="input-group-1" :label="trans.get('__JSON__.Min Order Value')" label-for="name">
                                          <b-form-input
                                            id="min_order_value"
                                            name="min_order_value"
                                            v-model="$v.setting.min_order_value.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Min Order Value')"
                                            :class="{ 'is-invalid':  $v.setting.min_order_value.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.min_order_value.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.min_order_value.required"
                                            >{{ trans.get('__JSON__.Min Order Value') }}.</span>
                                              <span
                                              v-if="!$v.setting.min_order_value.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{ trans.get('__JSON__.Ordering Application will not allow placing an order less than the Minimum order value') }}.</p>
                                     </div>
                                    </div>
                                </div>
                                
                             </div>
                             <div class="row mt-4">
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6>{{trans.get('__JSON__.Payment Method')}}</h6>
                                        </div>
                                    <div class="col-md-6 order-value">
                                       <select
                                          class="custom-select"
                                          v-model="setting.restaurant_payment_option"
                                          id="restaurant_payment_option"
                                          name="restaurant_payment_option"
                                        >
                                          <option value="Both">{{ trans.get('__JSON__.Both') }}</option>
                                          <option value="Cash On Delivery">{{ trans.get('__JSON__.Cash On Delivery') }}</option>
                                          <option value="PAY ONLINE">{{ trans.get('__JSON__.PAY ONLINE') }}</option>
                                          <!-- <option value="RESTAURANT SELECT PAYMENT OPTION">{{ trans.get('__JSON__.RESTAURANT SELECT PAYMENT OPTION', { vendor: $auth.setting.restaurant_name }) }}</option> -->
                                        </select>
                                        <!-- <multiselect v-model="setting.restaurant_payment_option" :options="payment"  name="payment" id="payment"></multiselect> -->
                                    </div>
                                    <div class="col-md-12">
                                        <p>{{trans.get('__JSON__.Ordering Application will allow selected Payment methods')}}.</p>
                                     </div>
                                    </div>
                                </div>
                                
                             </div>
                             <div class="row mt-4">
                                <div class="col-md-12">
                                    
                                    <div class="row">
                                        <!-- <div class="col-md-12">
                                            <h6>Items Loading Count</h6>
                                        </div> -->
                                    <div class="col-md-6 order-value">
                                     
                                       <b-form-group id="input-group-1" :label="trans.get('__JSON__.Item Counts')" label-for="name">
                                          <b-form-input
                                            id="item_counts"
                                            name="item_counts"
                                            v-model="$v.setting.item_counts.$model"
                                            type="text"
                                            :placeholder="trans.get('__JSON__.Item Counts')"
                                            :class="{ 'is-invalid':  $v.setting.item_counts.$error }"
                                          ></b-form-input>
                                          <div v-if=" $v.setting.item_counts.$error" class="invalid-feedback">
                                            <span
                                              v-if="!$v.setting.item_counts.required"
                                            >{{ trans.get('__JSON__.Item Counts') }}.</span>
                                              <span
                                              v-if="!$v.setting.item_counts.numeric"
                                            >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                          </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <p>Number of Item Load Same time on {{ $auth.setting.restaurant_name }} Details Page.</p>
                                     </div>
                                    </div>
                                </div>
                                
                             </div>
                              <div class="row mt-4">
                                <div class="col-md-12">
                                    <h6>{{trans.get('__JSON__.Chowman Pay')}}</h6>  
                                </div>
                                <div class="col-md-6 vendor-status">
                                  <div class="swtich-data">
                                      <b-form-group>
                                        <label class="switch">
                                            <input
                                            type="checkbox"
                                            id="chowman_pay"
                                            name="chowman_pay"
                                            v-model="setting.chowman_pay"
                                            class="switch-on"
                                            true-value="active"
                                            false-value="inactive"
                                            />
                                            <div class="slider round">
                                            <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                            <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                            </div>
                                        </label>
                                      </b-form-group>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <p>{{ trans.get('__JSON__.This will enable feature',{feature: trans.get('__JSON__.Chowman Pay')}) }}.</p>
                                  </div>
                              </div>
                              <div class="row mt-4">
                                <div class="col-md-12">
                                    <h6>{{trans.get('__JSON__.Gift Voucher')}}</h6>  
                                </div>
                                <div class="col-md-6 vendor-status">
                                  <div class="swtich-data">
                                      <b-form-group>
                                        <label class="switch">
                                            <input
                                            type="checkbox"
                                            id="gift_voucher"
                                            name="gift_voucher"
                                            v-model="setting.gift_voucher"
                                            class="switch-on"
                                            true-value="active"
                                            false-value="inactive"
                                            />
                                            <div class="slider round">
                                            <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                                            <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                                            </div>
                                        </label>
                                      </b-form-group>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <p>{{ trans.get('__JSON__.This will enable feature',{feature: trans.get('__JSON__.Gift Voucher')}) }}.</p>
                                </div>
                              <div class="col-md-6">
                                  <b-form-group id="input-group-1" :label="trans.get('Minimum Gift Voucher Amount')" label-for="minimum_voucher_amount">
                                    <b-form-input
                                      id="minimum_voucher_amount"
                                      name="minimum_voucher_amount"
                                      v-model="$v.setting.minimum_voucher_amount.$model"
                                      type="text"
                                      :placeholder="trans.get('Enter Maximum Referral')"
                                      :class="{ 'is-invalid':  $v.setting.minimum_voucher_amount.$error }"
                                    ></b-form-input>
                                    <div v-if=" $v.setting.minimum_voucher_amount.$error" class="invalid-feedback">
                                      <span
                                        v-if="!$v.setting.minimum_voucher_amount.required"
                                      >{{ trans.get('__JSON__.required',{ attribute: 'Gift Voucher' }) }}.</span>
                                        <span
                                        v-else-if="!$v.setting.minimum_voucher_amount.numeric"
                                      >{{ trans.get('__JSON__.Enter Numeric Value') }}.</span>
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                  <div class="row section-main">
                    <div class="col-md-4">
                        <div class="setting-heading">  
                            <h4>Chat Default Suggestions</h4>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor.</p> -->
                        </div>
                    </div>
                      <div class="col-md-8">
                          <div class="setting-data">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-6 order-value">
                                    <label id="input-group-1__BV_label_" for="name" class="d-block">User Default Messages</label>
                                  </div>
                                </div>
                                <div class="row" v-for="(msg, index) in setting.customer_default_msg" :key="index">
                                  <div class="col-md-6 order-value" >
                                    <div id="input-group-1" role="group" class="form-group">
                                      <div class="bv-no-focus-ring">
                                        <input id="restaurant_kilometer" v-model="setting.customer_default_msg[index]" type="text" placeholder="Add message" class="form-control">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <button type="button" class="btn btn-danger btn-sm btn-rounded" @click.prevent="setting.customer_default_msg.splice(index,1)">-</button>
                                  </div>
                                </div>
                                </div>
                              </div>
                          </div>
                      </div>
                        <div class="col-md-4 mt-2">
                          &nbsp;
                        </div>
                        <div class="col-md-8 mt-2">
                          <button type="button" class="btn btn-primary btn-sm" @click.prevent="addChatMessageField">Add Item</button>
                        </div>
                        <div class="col-md-8 offset-4 mt-4">
                          <div class="setting-data">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-6 order-value">
                                    <label id="input-group-1__BV_label_" for="name" class="d-block">Admin Default Messages</label>
                                  </div>
                                </div>
                                <template v-if="setting.admin_default_msg.length>0">
                                  <template v-for="(msgs, index) in setting.admin_default_msg">
                                  <div class="row" :key="index">
                                    <div class="col-md-6 order-value" :key="index">
                                      <div id="input-group-1" role="group" class="form-group">
                                        <div class="bv-no-focus-ring">
                                          <input v-model="setting.admin_default_msg[index].heading" type="text" placeholder="Add Heading" class="form-control">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <button type="button" class="btn btn-danger btn-sm btn-rounded" @click.prevent="setting.admin_default_msg.splice(index,1)">-</button>
                                    </div>
                                  </div>
                                  <div class="row" :key="'p'+index">
                                    <template v-for="(msg, ind) in msgs.messages">
                                      <div class="col-md-5 offset-md-1 order-value" :key="'t'+ind">
                                        <div id="input-group-1" role="group" class="form-group">
                                          <div class="bv-no-focus-ring">
                                            <input v-model="setting.admin_default_msg[index].messages[ind]" type="text" placeholder="Add message" class="form-control">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3" :key="'r'+ind">
                                        <button type="button" class="btn btn-danger btn-sm btn-rounded" @click.prevent="setting.admin_default_msg[index].messages.splice(ind,1)">-</button>
                                      </div>
                                    </template>
                                  </div>
                                  <div class="row" :key="'w'+index">
                                    <div class="col-md-8 offset-md-1 my-2">
                                      <button type="button" class="btn btn-primary btn-sm" @click.prevent="addAdminChatMessageField(index)">Add Message</button>
                                    </div>
                                  </div>
                                  </template>
                                </template>
                                </div>
                              </div>
                          </div>
                      </div>
                        <div class="col-md-4 mt-2">
                          &nbsp;
                        </div>
                        <div class="col-md-8 mt-2">
                          <button type="button" class="btn btn-primary btn-sm" @click.prevent="addAdminChatHeadingField">Add Heading</button>
                        </div>
                    </div>
                  
                  <!-- <div class="row section-main">
                    <div class="col-md-4">
                      <div class="setting-heading">
                        <h4>{{ trans.get('__JSON__.Login Methods For Customers') }}</h4>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="setting-data" v-for="(v,index) in app_signin_page" :key="index">
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <h6>{{ trans.get('__JSON__.Facebook') }}</h6>
                          </div>
                          <div class="col-md-6">
                            <div class="swtich-data">
                              <b-form-group>
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="facebook"
                                    name="facebook"
                                    v-model="v.facebook"
                                    class="switch-on"
                                    true-value="1"
                                    false-value="0"
                                  />
                                  <div class="slider round">
                                    <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                    <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                  </div>
                                </label>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <h6>{{ trans.get('__JSON__.Google') }}</h6>
                          </div>
                          <div class="col-md-6">
                            <div class="swtich-data"> -->
                              <!--    <label class="switch">
                              <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label>-->
                              <!-- <b-form-group>
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="google"
                                    name="google"
                                    v-model="v.google"
                                    class="switch-on"
                                    true-value="1"
                                    false-value="0"
                                  />
                                  <div class="slider round">
                                    <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                    <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                  </div>
                                </label>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <h6>{{ trans.get('__JSON__.Phone') }}</h6>
                          </div>
                          <div class="col-md-6">
                            <div class="swtich-data"> -->
                              <!--    <label class="switch">
                              <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label>-->
                              <!-- <b-form-group>
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="phone"
                                    name="phone"
                                    v-model="v.phone"
                                    class="switch-on"
                                    true-value="1"
                                    false-value="0"
                                  />
                                  <div class="slider round">
                                    <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                    <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                  </div>
                                </label>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <h6>{{ trans.get('__JSON__.Apple') }}</h6>
                          </div>
                          <div class="col-md-6">
                            <div class="swtich-data"> -->
                              <!--    <label class="switch">
                              <input type="checkbox" id="is_same_day_delivery" name = "is_same_day_delivery" class="switch-on" v-model="setting.is_same_day_delivery"><div class="slider round"><span class="on">ON</span><span class="off">OFF</span></div></label>-->
                              <!-- <b-form-group>
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="apple"
                                    name="apple"
                                    v-model="v.apple"
                                    class="switch-on"
                                    true-value="1"
                                    false-value="0"
                                  />
                                  <div class="slider round">
                                    <span class="on">{{ trans.get('__JSON__.On') }}</span>
                                    <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                                  </div>
                                </label>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="row section-main">
                    <div class="col-md-4">
                      <div class="setting-heading">
                        <h4>{{ trans.get('__JSON__.Custom Items') }}</h4>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="setting-data" v-for="(v,index) in setting.custom_item" :key="index">
                        <div class="row" :class="{ 'mt-2': (index > 0) }">
                          <div class="col-md-4">
                            <h6>{{ trans.get('__JSON__.Custom Field Name') }}</h6>
                            <input
                              :id="'customefields_'+index"
                              :name="'customefields_'+index"
                              type="text"
                              class="form-control"
                              v-model="v.name"
                            />
                          </div>
                          <div class="col-md-3">
                          <h6>&nbsp;</h6>
                          <button class="btn btn-danger btn-sm btn-rounded" @click.prevent="removeCustomeField(index)">-</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mt-2">
                    &nbsp;
                  </div>
                  <div class="col-md-8 mt-2">
                    <button class="btn btn-primary btn-sm" @click.prevent="addCustomeField">Add Item</button>
                  </div>
                </div> -->
                <div class="btn-update">
                    <div class="col-sm-6">
                    <button type="submit" v-if="$auth.hasPermission(config.permissions.configuration_write)"  class="btn btn-primary mr-1 mt-3">Update</button>
                    </div>
                </div>

            </div>
        </div>
      </div>
    </div>
</b-form>
</template>