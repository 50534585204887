import { routes } from '../config/api_routes'
import config from '../config/index'

export const callCenterDashboardService = {

    getAllRestaurantName: (formData) => window.axios.get(routes.getCallCenterRestaurant,{params: formData}),

    checkMobileNumber: (formData) => window.axios.post(routes.checkCustomerNumberApi,formData),

    getCustomerAddress: (formData) => window.axios.post(routes.getCustomerAddressApi,formData),

    addCustomerAddress: (formData) => window.axios.post(routes.addCustomerAddressApi,formData),

    placeOrderApi: (formData) => window.axios.post(routes.placeOrderApi,formData),

    checkEmail: (data)   =>  window.axios.post(routes.checkEmailApi,data),

    userCreate: (data)   =>  window.axios.post(routes.userCreateApi,data) ,
    
    getCustomerLastOrders: (data)   =>  window.axios.post(routes.getCustomerLastOrdersApi,data) ,

    VerifyOrderApi: (formData)   =>  window.axios.post(routes.VerifyOrderApi,formData)
}