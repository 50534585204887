<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import { customerService } from "../../services";
import { set_axios_defaults, success_message } from "../../Helper/helper";
import { error_message,debounce} from "../../Helper/helper";

export default {
  components: { pagination },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      sortBy: sortBy || "created_at",
      config:config,
      sortDesc: orderBy && orderBy != '' ? orderBy : true,
      customers: [],
      customerData: [],
      customerCount: '',
      paginations: {},
      limit: 2,
      search: "",
      status: status == undefined ? "0" :status,
      loading: true,
      searchData:debounce(()=>{
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'customer-list', query: {status,orderBy,sortBy}})
          this.getCustomerList();
          // this.customerExportCsv();
      },500),
      fields: [
        {
          key: "unique",
          label: this.trans.get("__JSON__.Customer ID"),
          sortable: false,
        },
        {
          key: "user_profile",
          label: this.trans.get("__JSON__.Profile"),
          sortable: false,
        },
        {
          key: "user_name",
          label: this.trans.get("__JSON__.Name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.trans.get("__JSON__.Email"),
          sortable: true,
        },
        // {
        //   key: "mobile_number",
        //   label: this.trans.get("__JSON__.Phone Number"),
        //   sortable: true,
        // },
        {
          key: "customer_categories",
          label: this.trans.get("__JSON__.Customer Categories"),
          sortable: false,
        },
        {
          key: "order_count",
          label: this.trans.get("__JSON__.Orders"),
          sortable: true,
        },
        {
          key: "status",
          label: this.trans.get("__JSON__.Status"),
          sortable: false,
        },
        {
          key: "is_cod_enable",
          label: this.trans.get("__JSON__.COD"),
          sortable: false,
        },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },

      ],
    };
  },
  mounted() {
    this.getCustomerList();
    // this.customerExportCsv();
  },
  methods: {
    onRowClicked(item) {
        this.$router.push({name: 'customer-detail',params: { user_id: this.base64(item.user_id)}})
    },
    downloadsCSV: function () {
      this.customerExportCsv()
      .then(()=>{
        let customerData = "\ufeff" + "Customer Id,Name,Email,Phone Number,Order,Status\n";
        this.customerData.forEach((el) => {
          var line =
            el["unique"] +
            "," +
            el["user_name"] +" " + el["last_name"] +
            "," +
            el["email"] +
            "," +
            el["mobile_number"] +
            "," +
            el["order_count"] +
            "," +
            (el["status"] == 0 ? "Active" : "Inactive") +
            "\n";
          customerData += line;
        });
        var blob = new Blob([customerData], { type: "csv/plain" });
        var currentDate = new Date();
        var date = new Date().toLocaleString();
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Customers" + date + ".csv";
        link.click();
      })
    },
    getCustomerList(page) {
      this.loading = true;
      var filters = {
          search: this.search,
          status: this.status,
          sortBy: this.sortBy,
          orderBy: this.sortDesc ? "desc" : "asc",
          page: page && page > 0 ? page : 1,
      }
      customerService
        .getCustomer(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result.data;
          this.customers = response.data.result.data.data;
          this.customerCount = response.data.result.customerCount;
        });
    },
    customerExportCsv() {
      return customerService
        .customerExportCsv({ status: this.status, sortBy: this.sortBy, orderBy: this.sortDesc ? "desc" : "asc" })
        .then((response) => {
          this.customerData = response.data.result;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getCustomerList();
      // this.customerExportCsv();
    },
    changeCodStatus($event, user_id){
      console.log($event.target.checked);
      customerService.codStatusUpdate({
        is_cod_enable: $event.target.checked?'1':'0',
        user_id:  user_id
      }).then(res => {
        console.log(res);
        success_message('COD status updated');
      })
    }
    
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-user-circle"></i>
              </span>
            </div>
            <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Customers') }}({{customerCount}})</h4>
          </div>
          <div class="page-title-right">
            <button
              @click="downloadsCSV"
              v-if="$auth.hasSubAdminPermission(config.permissions.customer_export)"
              class="btn btn-primary btn-rounded"
            >{{ trans.get('__JSON__.Export To CSV') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="0" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="1">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list customer-table table-responsive">
                <b-table
                  :items="customers"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  @row-clicked="onRowClicked"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                    </div>
                  </template>
                  <template v-slot:cell(unique)="data">
                      <h5 class="font-size-14">{{ data.item.unique }}</h5>
                  </template>

                  <template v-slot:cell(user_profile)="data">
                    <div v-if="data.item.user_profile">
                          <img
                            alt
                            title="product-img"
                            class="avatar-md rounded-circle-banner"
                            v-lazy="data.item.user_profile"
                          />
                        </div>
                        <div v-else>
                          <img
                            class="avatar-md rounded-circle-banner"
                            :src="config.basepath + 'no-img.png'"
                            alt
                          />
                        </div>
                  </template>

                  <template v-slot:cell(user_name)="data">
                    <h5 class="font-size-14">{{ data.item.user_name }} {{ data.item.last_name }}</h5>
                  </template>
                  <template v-slot:cell(email)="data">
                    <h5 class="font-size-14">{{ data.item.email}} </h5>
                    <p class="font-size-10">{{ data.item.mobile_number}}</p>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status === '1'"
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                  </template>

                  <template v-slot:cell(customer_categories)="data">
                   <template v-for="(category_settings,index) in data.item.category_settings">
                      <div :key="category_settings.type" v-if="(index+1) != data.value.length" class="badge badge-pill badge-soft-primary font-size-12 mr-1"> {{ category_settings.category_name }} </div>
                    </template>
                  </template>

                  <template v-slot:cell(is_cod_enable)="data">
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        v-model="data.value"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                        @change="changeCodStatus($event, data.item.user_id)"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.On') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                      </div>
                    </label>
                  </template>
                  
                  <template v-slot:cell(action)="data">
                    <router-link
                      :to="{name: 'customer-detail',params: { user_id:base64(data.item.user_id)}}"
                      class="btn btn-primary btn-sm btn-rounded"
                    >View</router-link>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Customer') }}</p>
                  </template>
                </b-table>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getCustomerList"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

