<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
              >
                <i class="bx bx-store"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ restaurant.name }}</h4>
              <p>
                <router-link :to="{ name: 'vendor-list' }">{{
                  trans.trans("__JSON__.Vendor", {
                    vendor: $auth.setting.restaurant_name,
                  })
                }}</router-link>
                > {{ restaurant.name }}
              </p>
            </div>
          </div>
          <div class="page-title-left avatar-main-icon">
            <div class="row">
              <div class="col-md-5 ml-auto">
                <label class="control-label font-weight-bold">{{
                  trans.get("__JSON__.Profile Status")
                }}</label>
                <select
                  class="custom-select"
                  v-bind:value="is_confirm"
                  v-model="is_confirm"
                  id="is_confirm"
                  name="is_confirm"
                  @change="changeConfirm"
                >
                  <option value="0">
                    {{ trans.get("__JSON__.Inactive") }}
                  </option>
                  <option value="1">{{ trans.get("__JSON__.Active") }}</option>
                  <option value="2">
                    {{ trans.get("__JSON__.Waiting For Approval") }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="control-label font-weight-bold">{{
                  trans.get("__JSON__.Accepting Order")
                }}</label>
                <div class="swtich-data">
                  <b-form-group>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="status"
                        name="status"
                        v-model="restaurant_on_off"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                        @change="onStatusChange"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get("__JSON__.Yes") }}</span>
                        <span class="off">{{ trans.get("__JSON__.No") }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="nav-tabs-main">
        <div class="card">
          <div class="card-body">
            <!-- Tab panel start-->
            <b-tabs
              justified
              nav-class="nav-tabs-custom mt-3 order-tabs"
              content-class="p-3 text-muted"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Profile') }}</span>
                </template>
                <vendor-profile />
              </b-tab>
              <b-tab lazy>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-clock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Operation Time') }}</span>
                </template>
                <operation-time v-if="!!restaurant" :restaurant="restaurant"/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-star"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Rating & Reviews') }}</span>
                </template>
                <vendor-rating />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-times-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Selling Time slot') }}</span>
                </template>
                <selling-timeslot/>
              </b-tab>
              <!-- <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Category') }}</span>
                </template>
                <vendor-category />
              </b-tab> -->
              <b-tab  v-if="$auth.hasPermission(config.permissions.merchant_write)">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-lock"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Change Password') }}</span>
                </template>
              <change-password />
              </b-tab>
              <b-tab>
                <template v-slot:title  v-if="$auth.hasPermission(config.permissions.merchant_write)">
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-percentage"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    {{
                      trans.trans("__JSON__.Driver", {
                        driver: $auth.setting.driver_name,
                      })
                    }}</span
                  >
                </template>
                <driver />
              </b-tab>
              
              <!-- <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-percentage"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{ trans.get('__JSON__.Banner Ads') }}</span>
                </template>
                <banner-ads/>  -->
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import vendorRating from "./vendor-rating";
import vendorCategory from "./vendor-category";
import driver from "./drivers.vue";
import vendorProfile from "./vendor-profile";
import { restaurantService, vendorService } from "../../services";
import vendorDiscount from "./vendor-discount";
import bannerAds from "./banner-ads";
import changePassword from "./change-password";
import SellingTimeslot from "./selling-timeslot";
import OperationTime from "./operation-time";
import config from '../../config';
import { success_message } from '../../Helper/helper';
import BannerAds from './banner-ads.vue';


/**
 * Tabs & accordions component
 */
export default {
  components: { Layout, PageHeader, vendorRating, vendorCategory,vendorProfile, vendorDiscount, changePassword ,SellingTimeslot, OperationTime,bannerAds},
  components: {
    Layout,
    PageHeader,
    vendorRating,
    vendorCategory,
    vendorProfile,
    vendorDiscount,
    changePassword,
    SellingTimeslot,
    OperationTime,
    driver,
  },
  data() {
    return {
      restaurant_id: "",
      restaurant: [],
      config: config,
      restaurant_on_off: "0",
      is_confirm: "",
    };
  },
  mounted() {
    this.getRestaurantById();
  },
  methods: {
    onStatusChange($event) {
      this.restaurant_on_off = $event.target.checked ? "1" : "0";
      restaurantService
        .restaurantOnOff({
          restaurant_id: this.restaurant_id,
          restaurant_on_off: $event.target.checked ? "1" : "0",
        })
        .then((response) => {
          if (response.data.code == 200) {
            success_message(response.data.message);
          }
        });
    },
    changeConfirm() {
      restaurantService
        .updateStatus({
          restaurant_id: this.restaurant_id,
          is_confirm: this.is_confirm,
        })
        .then((response) => {
          if (response.data.code == 200) {
            success_message(response.data.message);
          }
        });
    },
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            restaurant_on_off,
          } = response.data.result;
          this.restaurant_id = restaurant_id;
          this.restaurant_on_off = restaurant_on_off;
          this.is_confirm = is_confirm;
          this.restaurant = {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
          };
        });
    },
  },
};
</script>