
<script>
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import LightViewTree from '../../components/custom_modules/light-vue-tree';
import '../../components/custom_modules/light-vue-tree/lib/style/index.css';
import {
  promoCodeService,
  bannerService,
  deliveryZoneService,
  settingService,
  restaurantService,
} from "../../services";
import {
  error_message,
  success_message,
  replaceByDefault,
  get_currency,
} from "../../Helper/helper";
import DropzonePopup from "../../components/widgets/dropzone-popup";

import { required, requiredIf, helpers, numeric } from "vuelidate/lib/validators";
import { routes } from "../../config/api_routes";
const floating = helpers.regex('floating', /^(?:\d*\.\d{1,2}|\d+)$/)
const notEqualTo = (value) => (value !== "-")

export default {
	components: {
		vueDropzone: vue2Dropzone,
		Multiselect,
		PageHeader,
		DatePicker,
		promoCodeService,
		bannerService,
		DropzonePopup,
    LightViewTree
  },
  data() {
    return {
      treeData: [
        // {
        //   name: "Category 1",
        //   id: "1",
        //   children: [
        //     {
        //       name: "Level 1",
        //       id: "2",
        //       children: [
        //         {
        //           name: "Level 2",
        //           id: "3",
        //           children: [
        //             {
        //               name: "Level 3",
        //               id: "4",
        //               children: [],
        //             },
        //             {
        //               name: "Level 3",
        //               id: "6",
        //               children: [],
        //               // checked: false,
        //               // disabled: true
        //             },
        //             {
        //               name: "Level 3",
        //               id: "5",
        //               children: [],
        //               checked: false
        //             }
        //           ]
        //         }
        //       ]
        //     },
        //     {
        //       name: "二级 1-2",
        //       id: "8",
        //       children: [
        //         {
        //           name: "三级 1-2-1",
        //           id: "9",
        //           children: [
        //             {
        //               name: "四级 1-2-1-1",
        //               id: "10",
        //               children: []
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   name: "Category 2",
        //   id: "11",
        //   children: [
        //     {
        //       name: "Level 1",
        //       id: "12",
        //       children: []
        //     },
        //     {
        //       name: "Level 2",
        //       id: "15",
        //       children: []
        //     }
        //   ]
        // }
      ],
		currency: get_currency(),
		tmpCouponImage: "",
		search: "",
		search1: "",
		isLoading: false,
		showTimeRangePanel: false,
		isLoading1: false,
		customer: [],
		checkOpen: true,
		discount_type: "0",
		rangeSeparator: "-",
		datetime: null,
		format: "YYYY/MM/DD HH:s",
		coupon_customer: [],
		resturant: [],
    	search_items_array:[],
		coupon_restaurants: [],
		free_bie_items: [],
		updateValues: "",
		countries: [],
		states: [],
		cities: [],
		get_restaurant: [],
		get_customer: [],
		country_count: 0,
		state_count: 0,
    	diasableItemData:[],
		city_count: 0,
    	selected_data_catelog:[],
    	customer_categories: [],
		typeform: {
			coupon_name: "",
			coupon_code: "",
			coupon_name_other_lang: "",
			description_other_lang: "",
			minimum_order_amount: "",
			country: "",
			orders_valid_for_first: "",
			state: "",
			city: "",
			checkbox: false,
			customercheckbox: false,
			coupon_type: "",
			image: "",
			coupon_restaurants: "",
			free_bie_items:"",
			coupon_customer: "",
			is_show_customer_app: "",
			discount_percentage: "",
			maximum_discount_amount: "",
			is_flat_discount: "",
			per_user_usage: "",
			minimum_order_amount: "",
			description: "",
			max_user_count: "",
			flat_discount: "",
			cashback: 0,
			datetime: "",
			get_restaurant: "",
			get_customer: "",
			status: "",
			duration_type: '',
			coupon_type: '',
			coupon_time_durations: "",
			per_hour_coupon_limit: 0,
			customer_categories: '',
			tag_line:'',
		},
		coupon_image: "",
		coupon_image_path: "",
		coupon_image_delete_url: routes.promoCodeImageDeleteUrl,
		id: "",
    selected_catelog:[],
    restaurants:[],
    search_items:'',
		time_duration: [{coupon_time_duration_id:'', start_time: '12:00 AM', end_time: '11:59 PM'}], // default values
    customer_category_options: [],
    };
  },
  validations: {
    typeform: {
      coupon_name: {
        required,
      },
    //   tag_line: {
    //     // required,
    //   },
      coupon_code: {
        required,
      },
      minimum_order_amount: {
        required: requiredIf(function(form){
        	return (this.typeform.is_flat_discount == 0 || this.typeform.is_flat_discount == 1 || this.typeform.is_flat_discount == 2 || this.typeform.is_flat_discount == 3 || this.typeform.is_flat_discount == 4 || this.typeform.is_flat_discount == 7);
        }),
        floating,
      },
      image: {
        required: requiredIf(function (nestedModel) {
          return !(!!this.coupon_image || !!this.typeform.image);
        }),
      },
      /* country: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      }, */
      datetime: {
        required,
      },
      max_user_count: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '1' || this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3' || this.typeform.is_flat_discount == '4';
        }),
        floating,
      },
      coupon_type: {
        // required: requiredIf(function(form){
        //   if(this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '1' || this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3' || this.typeform.is_flat_discount == '4'){
        //     return notEqualTo;
        //   };
        // })
        // required
        notEqualTo
      },
      discount_percentage: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '3';
        }),
        floating,
      },
      cashback:{
        required:requiredIf(function(){
          return this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3'
            || this.typeform.is_flat_discount == '4';
        }),
        numeric
      },
      maximum_discount_amount: {
        required : requiredIf(function(form){
        	return this.typeform.is_flat_discount == '0' || this.typeform.is_flat_discount == '2' || this.typeform.is_flat_discount == '3';
        }),
        floating,
      },
      maximum_cashback_amount: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '3' || this.typeform.is_flat_discount == '4';
        }),
      },
      flat_discount: {
        required : requiredIf(function(form){
        	return this.typeform.is_flat_discount == '1' || this.typeform.is_flat_discount == '4';
        }),
        floating,
      },
      bogo_item_required: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '5';
        }),
        numeric
      },
      flat_price: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '6';
        }),
      },
      bogo_get_item: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '5';
        }),
        numeric
      },
      free_bie_items: {
        required: requiredIf(function(form){
        	return this.typeform.is_flat_discount == '7';
        }),
      },
      // key:{
      //   requiredIf
      // },
      description: {
        required,
      },
      coupon_restaurants: {
        required: requiredIf(function (nestedModel) {
          return this.typeform.get_restaurant == 0;
        }),
      },
      coupon_customer: {
        required: requiredIf(function (nestedModel) {
          return this.typeform.get_customer == 0;
        }),
      },
      per_hour_coupon_limit: {
        numeric
      }
    },
  },
  watch:{
    // 'typeform.is_flat_discount':function(){
    //     this.restaurants.length = 0;
    //     this.search_items = '';
    //     if((this.typeform.is_flat_discount == 5 || this.typeform.is_flat_discount == 6) && (this.typeform.coupon_restaurants.length > 0 || this.typeform.get_restaurant == 1)){
    //       this.selected_catelog.length = 0;
    //       this.getCategoryData();
    //     }

    // },
    // 'typeform.coupon_restaurants':function(){
    //   this.restaurants.length = 0;
    //   this.search_items = '';
      
    //   if(!this.typeform.checkbox &&  this.typeform?.coupon_restaurants && (this.typeform.get_restaurant == 0 || this.typeform.get_restaurant == false)){
    //     this.typeform.coupon_restaurants.forEach((el)=>{  
    //       this.restaurants.push(el.restaurant_id);
    //     });
    //     if(this.typeform.is_flat_discount == 5 || this.typeform.is_flat_discount == 6){
    //       this.selected_catelog.length = 0;
    //       this.getCategoryData();
    //     }
    //   }
    //   },
    //   'typeform.get_restaurant':function(){
    //     this.restaurants.length = 0;
    //     this.search_items = '';
    //     if((this.typeform.is_flat_discount == 5 || this.typeform.is_flat_discount == 6) && this.typeform.get_restaurant == 1){
    //       this.selected_catelog.length = 0;
    //       this.getCategoryData();
    //     }
    // }
  },
  mounted() {
    // this.getVendorCountry();
    // this.getAllState();
    // this.getAllCity();
  
    this.getCustomerCategories();
    
    this.getPromoCodeById();
  },
  methods: {
    dropNode({ parentNode, targetNode, callback }) {
      // console.log("dropNode", parentNode, targetNode);
      callback(targetNode);
    },
    checkedItem({ node, vNode }) {
      // console.log("checkedItem", node, vNode);
    },
    clickNode(node) {
      console.log("clickNode", node);
    },
    clickCheckbox({ node, selectedData }) {
      this.selected_data_catelog.length = 0;
      this.selected_catelog = selectedData;
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    onPromoCodeImagedeleted(value) {
      this.coupon_image = "";
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    Onclick: function () {
      
      if (this.typeform.get_restaurant == 1) {
        this.typeform.get_restaurant = !this.typeform.get_restaurant;
      }
        this.typeform.coupon_restaurants = '';
    },
    customerOnclick: function () {
      if (this.typeform.get_customer == 1) {
        this.typeform.get_customer = !this.typeform.get_customer;
      }
        this.typeform.coupon_customer = '';
    },
    dateFormat(classes, date) {},
    asyncFind(query) {
      this.isLoading = true;
      bannerService.getRestaurant({search: query}).then((response) => {
        // console.log(response.data.result);
        
        this.resturant = response.data.result;
        this.isLoading = false;
      });
    },
    asyncFindItems(query) {
      this.isLoading = true;
      restaurantService.getAllItems({search: query}).then((response) => {        
        this.search_items_array = response.data.result;
        this.isLoading = false;
      });
    },
    onItemSelected()
    {
      this.search_items_array = [];
    },
    clearAll() {
      this.coupon_restaurants = [];
    },
    clearAllSelectedItems(){
      this.free_bie_items = [];
    },

    asyncFindCustomer(query) {
      this.isLoading = true;
      promoCodeService.getCustomer({search: query}).then((response) => {
        this.customer = response.data.result;
        this.isLoading1 = false;
      });
    },
    clearAllCustomer() {
      this.coupon_customer = [];
    },
    getPromoCodeById() {
      promoCodeService
        .getPromoCodeById({ id: this.$route.params.id })
        .then((response) => {
          const {
            id,
            coupon_name,
            coupon_code,
            coupon_name_other_lang,
            description,
            description_other_lang,
            minimum_order_amount,
            orders_valid_for_first,
            // country,
            // state,
            // city,
            coupon_type,
            coupon_restaurants,
            coupon_customer,
            is_show_customer_app,
            discount_percentage,
            maximum_discount_amount,
            maximum_cashback_amount,
            is_flat_discount,
            flat_discount,
            max_user_count,
            per_user_usage,
            get_restaurant,
            get_customer,
            status,
            duration_type,
            cashback,
			      get_coupon_duration,
            per_hour_coupon_limit,
            customer_categories,
            bogo_item_required,
            bogo_get_item,
            flat_price,tag_line
          } = response.data.result.data;
          this.coupon_image_path = response.data.result.data.coupon_image_path;
          this.coupon_image = response.data.result.data.coupon_image;
          this.id = response.data.result.data.id;
          this.typeform = {
            id,
            coupon_name,
            coupon_code,
            coupon_name_other_lang,
            description,
            description_other_lang,
            minimum_order_amount,
            // country,
            orders_valid_for_first,
            // state,
            // city,
            coupon_type,
            coupon_restaurants,
            coupon_customer,
            is_show_customer_app,
            discount_percentage,
            maximum_discount_amount,
            maximum_cashback_amount,
            is_flat_discount,
            flat_discount,
            max_user_count,
            per_user_usage,
            get_restaurant,
            get_customer,
            status,
            duration_type,
            cashback,
			      get_coupon_duration,
            per_hour_coupon_limit,
            customer_categories,
            bogo_item_required,
            bogo_get_item,
            flat_price,tag_line
          };

          this.typeform.free_bie_items = response?.data?.result?.free_bie_items ?? [];
          this.selected_data_catelog = response?.data?.result?.catelog_items ?? [];
          this.typeform.datetime = response.data.result.data.datetime.split(" - ");
          this.typeform.get_restaurant = response.data.result.data.get_restaurant.length;
          this.typeform.get_customer = response.data.result.data.get_customer.length;
          
          if(response.data.result.data.get_coupon_duration.length > 0) {
            this.time_duration = response.data.result.data.get_coupon_duration; 
          }
          this.getSelectedItem();
          this.getCategoryData();
        });
    },
    searchItem(){
      // if(this.search_items.length > 3){
          this.getCategoryData();
      // }
    },
    getSelectedItem(){
      promoCodeService.getSelectedItemList({coupon_id:this.typeform.id}).then((response)=>{
          this.diasableItemData = response?.data?.result ?? [];
      });
    },

     getCategoryData(){
      var obj ={
        selectedRestaurant: this.restaurants,
        search:this.search_items
      }
      if (this.typeform.checkbox == true) {
        obj = {
            search:this.search_items
          };
      } 
      // this.restaurants.length = 0;
      this.treeData.length = 0;
       promoCodeService.getCategoryTree(obj).then((response)=>{
        if(response.data.code == 200 && response?.data?.result?.categories){
          let array= []
          let selectedArray = [];

          response.data.result.items.forEach((el)=>{
            let aray = el.item_ids.split(",");
            var ispush = true;

            let disabled = false;
            el.item_ids.split(",").forEach((y)=>{
                y = parseInt(y);
                if(this.diasableItemData.includes(y)){
                  disabled = true;
                  return;
                }
            });
            aray.forEach((d)=>{
              if(ispush){
                if(this.selected_data_catelog.includes(parseInt(d))){
                  selectedArray.push({id:el.item_ids, name:el.name ,children:[],key:'item',parent:el.menu_ids,checked:this.selected_data_catelog.includes(parseInt(d))});
                }
                array.push({id:el.item_ids, name:el.name ,children:[],key:'item',parent:el.menu_ids,checked:this.selected_data_catelog.includes(parseInt(d)),disabled:disabled});
                ispush = false;
              }
            })
            // selected_data_catelog
          })
          this.selected_catelog = selectedArray;
          let menus = [];
          var ispush = false;
          response.data.result.menus.forEach((e,index)=>{
            let temp = e.menu_ids.split(",");
            menus[index] = {id:e.menu_ids, name:e.name ,children:[],key:'menu',parent:e.category_ids,checked:false};
            array.forEach((i)=>{
              ispush = true;
                temp.forEach((t)=>{
                  let tdm = i.parent.split(",");
                    if(tdm.includes(t) && ispush) {
                      menus[index].children.push(i);
                      ispush = false;
                      // return ;
                    }
                });
            }); 
          });

          let categories = [];
          var is_psuh = false;
          var uncategorizedArray = [];
          var ctgry ={id:0, name:'Uncategorized' ,children:[],key:'category',parent:'Self',checked:false};
          response.data.result.categories.forEach((c,index)=>{
            let temp = c.category_ids.split(",");
            categories[index] = {id:c.category_ids, name:c.name ,children:[],key:'category',parent:'Self',checked:false};
            menus.forEach((i,idx)=>{
              is_psuh = true;
              let isValid = false

              let tdm = i.parent.split(",");
              temp.forEach((t)=>{
                if(tdm.includes(t) && is_psuh){
                    categories[index].children.push(i);
                    is_psuh = false;
                    isValid = true;
                    return;
                  }
                }
              );
              
              if(tdm.includes('0')){
                if(!uncategorizedArray[idx]){
                  uncategorizedArray[idx] = i
                  ctgry.children.push(i);
                }
              }
                // if(!isValid){
                //   ctgry.children.push(i);
                // }
            }); 
          });
          categories.push(ctgry);
          this.treeData = categories;
        } 
      }).catch((err)=>{
        console.log(err);
      });
    },
    getVendorCountry() {
      deliveryZoneService.getVendorCountry().then((response) => {
        this.country_count = response.data.result.length;
        this.countries = response.data.result;
        if (this.country_count == 1) {
          this.typeform.country = this.countries[0].id;
          this.getVendorState();
        }
      });
    },
    getVendorState() {
      deliveryZoneService
        .getVendorState({
          country_id: this.typeform.country,
        })
        .then((response) => {
          this.state_count = response.data.result.length;
          this.states = response.data.result;
          if (this.state_count == 1) {
            this.typeform.state = this.states[0].id;
            this.getVendorCity();
          } else {
            if (this.typeform.country == "") {
              this.getAllState();
              this.getAllCity();
            }
          }
        });
    },
    getVendorCity() {
      deliveryZoneService
        .getVendorCity({
          state_id: this.typeform.state,
        })
        .then((response) => {
          this.city_count = response.data.result.length;
          this.cities = response.data.result;
          if (this.city_count == 1) {
            this.typeform.city = this.cities[0].cityid;
          } else {
            if (this.typeform.state == "") {
              this.getAllCity();
            }
          }
        });
    },
    getAllState() {
      deliveryZoneService.getAllState().then((response) => {
        this.states = response.data.result;
        this.state_count = response.data.result.length;
      });
    },
    getAllCity() {
      deliveryZoneService.getAllCity().then((response) => {
        this.cities = response.data.result;
        this.city_count = response.data.result.length;
      });
    },
    couponInIt() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {

        if(this.typeform.is_flat_discount == 0 && this.typeform.discount_percentage > 100){
            error_message('Discount Percentage Should Be Less Than 100!');
            return;
        }else if(this.typeform.is_flat_discount == 2 && this.typeform.cashback > 100){
            error_message('Cashback Percentage Should Be Less Than 100!');
            return;
        }else if(this.typeform.is_flat_discount == 3 && (Number(this.typeform.cashback) + Number(this.typeform.discount_percentage)) > 100){
            error_message('Invalid Discount Percentage!');
            return;
        }else if(this.typeform.is_flat_discount == 4 && this.typeform.cashback > 100){
            error_message('Cashback Percentage Should Be Less Than 100!');
            return ;
        }


      console.log(this.typeform.is_flat_discount,'this.typeform.is_flat_discount');
      console.log(this.typeform.cashback,'this.typeform.cashback');
      console.log(this.typeform.discount_percentage,'this.typeform.discount_percentage');

        const fd = new FormData();
        fd.append("id", this.typeform.id);
        fd.append("coupon_name", this.typeform.coupon_name);
		
		if (this.typeform.tag_line === null) {
			this.typeform.tag_line = "";
		}

		fd.append("tag_line", this.typeform.tag_line);
        
        fd.append(
          "coupon_name_other_lang",
          this.typeform.coupon_name_other_lang
        );
        fd.append("description", this.typeform.description);
        fd.append(
          "description_other_lang",
          this.typeform.description_other_lang
        );
        fd.append("coupon_code", this.typeform.coupon_code);
        if (!!this.typeform.image) {
          fd.append("coupon_image", this.typeform.image);
        }
        fd.append("coupon_type", this.typeform.coupon_type);
        fd.append("is_show_customer_app", this.typeform.is_show_customer_app);
        fd.append("is_flat_discount", this.typeform.is_flat_discount);
        fd.append("flat_discount", this.typeform.flat_discount);
        fd.append("cashback", this.typeform.cashback);
        fd.append("discount_percentage", this.typeform.discount_percentage);
        fd.append("duration_type", this.typeform.duration_type);
        fd.append(
          "maximum_discount_amount",
          this.typeform.maximum_discount_amount
        );
        fd.append(
          "maximum_cashback_amount",
          this.typeform.maximum_cashback_amount
        )
        fd.append("minimum_order_amount", this.typeform.minimum_order_amount);
        fd.append("per_user_usage", this.typeform.per_user_usage);
        fd.append("max_user_count", this.typeform.max_user_count);
        fd.append(
          "orders_valid_for_first",
          this.typeform.orders_valid_for_first
        );

                
        if(this.typeform.is_flat_discount == 5 || this.typeform.is_flat_discount == 6 || this.typeform.is_flat_discount == 7){
          if(this.typeform.is_flat_discount == 7){
            let array = [];
            array.push(this.typeform.free_bie_items)
            fd.append("selected_catelog",JSON.stringify(array));
          }else{
            fd.append("selected_catelog",JSON.stringify(this.selected_catelog));
          }

          fd.append("bogo_item_required",this.typeform.bogo_item_required);
          fd.append("bogo_get_item",this.typeform.bogo_get_item);
          fd.append("flat_price",this.typeform.flat_price);
        }
        // fd.append("country", this.typeform.country);
        // fd.append("state", this.typeform.state);
        // fd.append("city", this.typeform.city);
        fd.append("datetime", this.typeform.datetime);
        fd.append("status", this.typeform.status);
        if (this.typeform.get_restaurant == 1) {
          fd.append("get_restaurant", 0);
        } else {
          this.typeform.coupon_restaurants
            .map((item) => item.restaurant_id)
            .map((item, index) => {
              fd.append("coupon_restaurants[" + index + "]", item);
            });
        }
        if (this.typeform.get_customer == 1) {
          fd.append("get_customer", 0);
        } else {
          this.typeform.coupon_customer
            .map((item) => item.user_id)
            .map((item, index) => {
              fd.append("coupon_customer[" + index + "]", item);
            });
        }
        // Pass value fro craete and update
        fd.append("time_duration", JSON.stringify(this.time_duration));
        fd.append("per_hour_coupon_limit", this.typeform.per_hour_coupon_limit);
        
        this.typeform.customer_categories
            .map((item, index) => {              
              fd.append("customer_categories[" + index + "]", item.id);
            });
        
        promoCodeService.updatePromoCodeById(fd).then((response) => {
          if (response.data.code === 200) 
          {
            this.time_duration = response.data.result.coupon_time_duration.length > 0 ? response.data.result.coupon_time_duration : [{coupon_time_duration_id: '', start_time: '',end_time:''}];
            success_message(response.data.message);
          } else {
            error_message(response.data.message);
          }
        });
      }
    },
    resetField(){
        this.typeform.discount_percentage = 0;
        this.typeform.flat_discount = 0;
        this.typeform.maximum_discount_amount = 0;
    },
    AddDuration() {
        // Add a new item to the array
        if(this.time_duration.filter(t => (t.start_time == '' || t.end_time == '')).length == 0){
          this.time_duration.push({coupon_time_duration_id: '', start_time: '',end_time:''}); // add new
          return true;
        }
      alert('Please select available slots first.')
    },
    removeCouponDuration(id, index) {
      if(id)
      {
          promoCodeService.removeCouponDuration(id).then((response) => {
          // console.log(response.data);
          if(response.data.code === 200) 
          {
            this.time_duration = this.time_duration.filter((time) => id != time.id );
            if(this.time_duration.length == 0)
            {
              this.time_duration.push({coupon_time_duration_id:'', start_time: '',end_time:''});
            }
            this.getPromoCodeById();
            success_message(response.data.message);
          } 
          else {
              error_message(response.data.message);
          }
        });
      }
      else
      {
        this.time_duration.splice(index,1);
      }
    },
    getDisabledEndTime(date,index){

      var times = this.time_duration.filter((time,ind) => ind !== index && (time.start_time != '' && time.end_time != '') );
      var rs = false
      times.forEach(time => {
        if(this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A'))
        && this.moment(date).isSameOrBefore(this.moment(time.end_time,'hh:mm A'))){
          rs = true;
        }
        if(this.time_duration[index].start_time !== '' && !rs){
          if(!rs && this.moment(this.time_duration[index].start_time,'hh:mm A').isSameOrBefore(this.moment(time.start_time,'hh:mm A')) && this.moment(date).isAfter(this.moment(time.start_time,'hh:mm A'))){
          rs = true;
          }
        }
      });
      if(this.time_duration[index].start_time !== '' && !rs){
        if(this.moment(date).isSameOrBefore(this.moment(this.time_duration[index].start_time,'hh:mm A'))){
          rs = true;
        }
      }
      return rs;
    },
    getDisabledStartTime(date,index){

      var times = this.time_duration.filter((time,ind) => ind !== index && (time.start_time != '' && time.end_time != '') );
      var rs = false
      times.forEach(time => {
        if(this.moment(date).isSameOrAfter(this.moment(time.start_time,'hh:mm A'))
        && this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A'))){
          rs = true;
        }
        if(this.time_duration[index].end_time !== '' && !rs){
          if(!rs && this.moment(this.time_duration[index].end_time,'hh:mm A').isSameOrAfter(this.moment(time.end_time,'hh:mm A')) && this.moment(date).isBefore(this.moment(time.end_time,'hh:mm A'))){
            rs = true;
          }
        }
      });
      if(this.time_duration[index].end_time !== '' && !rs){
        if(this.moment(date).isSameOrAfter(this.moment(this.time_duration[index].end_time,'hh:mm A'))){
          rs = true;
        }
      }
      return rs;
    },
    getCustomerCategories() {
      settingService
        .getCustomerCategories()
        .then((response) => {
          if (response.data.code === 200) {
            this.customer_category_options = response.data.result.data;
          } else {
            error_message(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <div>
     <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
        >
          <div class="page-title-left avatar-main-icon">
            <div class="avatar-xs mr-3">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                <i class="bx bx-purchase-tag"></i>
              </span>
            </div>
            <div class="report-tag">
              <h4 class="mb-0 font-size-18">{{ this.typeform.coupon_name }}</h4>
              <p><router-link :to="{ name: 'coupon-list'}">{{ trans.get('__JSON__.PROMO CODES') }}</router-link> > {{ this.typeform.coupon_name }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form action="#" @submit.prevent="couponInIt">
          <input type="hidden" name="id" v-model="typeform.id" />
          <!-- Promo Code Detail Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Promo Code Detail') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Name Language',{ lang: $auth.langauges[0].name }) }}</label>
                    <input
                      id="coupon_name"
                      v-model="$v.typeform.coupon_name.$model"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Name Language',{ lang: $auth.langauges[0].name })"
                      name="coupon_name"
                      :class="{ 'is-invalid': $v.typeform.coupon_name.$error }"
                    />
                    <div v-if="$v.typeform.coupon_name.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.coupon_name.required"
                      >{{ trans.get('__JSON__.Please enter promo code name') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Tagline')}}</label>
                    <input
                      id="tag_line"
                      v-model="typeform.tag_line"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Tagline')"
                      name="tag_line"
                     
                    />
                    <!-- <div v-if="$v.typeform.tag_line.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.tag_line.required"
                      >{{ trans.get('__JSON__.Please enter promo code tagline') }}.</span>
                    </div> -->
                  </div>
                </div>

                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div>
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Name Secondary Language(Optional)',{ lang: $auth.langauges[1].name }) }}</label>
                    <input
                      maxlength="100"
                      id="coupon_name_other_lang"
                      name="coupon_name_other_lang"
                      v-model="typeform.coupon_name_other_lang"
                      type="text"
                      :placeholder="trans.trans('__JSON__.Promo Code Name Secondary Language(Optional)',{ lang: $auth.langauges[1].name })"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Description Primary Language', { lang: $auth.langauges[0].name }) }}</label>
                    <textarea
                      rows="5"
                      id="description"
                      v-model="$v.typeform.description.$model"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Description Primary Language', { lang: $auth.langauges[0].name })"
                      name="description"
                      :class="{ 'is-invalid': $v.typeform.description.$error }"
                    ></textarea>
                    <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.description.required"
                      >{{ trans.get('__JSON__.Please enter promo code description') }}.</span>
                    </div>
                  </div>
                </div>


                <div class="col-sm-6 d-none" v-if="$auth.langauges[1].name === 'No Secondary Language'">
                </div> 
                <div class="col-sm-6" v-else>
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.trans('__JSON__.Promo Code Description Secondary Language(Optional)', { lang: $auth.langauges[1].name }) }}</label>
                    <textarea
                      rows="5"
                      id="description_other_lang"
                      v-model="typeform.description_other_lang"
                      type="text"
                      class="form-control"
                      :placeholder="trans.trans('__JSON__.Promo Code Description Secondary Language(Optional)', { lang: $auth.langauges[1].name })"
                      name="description_other_lang"
                    ></textarea>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Promo Code') }}</label>
                    <input
                      id="coupon_code"
                      disabled
                      v-model="$v.typeform.coupon_code.$model"
                      type="text"
                      class="form-control"
                      :placeholder="this.trans.get('__JSON__.Enter Promo Code Name')"
                      name="coupon_code"
                      :class="{ 'is-invalid': $v.typeform.coupon_code.$error }"
                    />
                    <div v-if="$v.typeform.coupon_code.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.coupon_code.required"
                      >{{ trans.get('__JSON__.Please enter promo code') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label
                      class="control-label"
                    >{{ trans.get('__JSON__.Promo Code Redeem start/end Period') }}</label>
                    <br />
                    <!-- <date-range-picker
                      ref="picker"
                      :opens="opens"
                      :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      :singleDatePicker="singleDatePicker"
                      :timePicker="timePicker"
                      :timePicker24Hour="timePicker24Hour"
                      :showWeekNumbers="showWeekNumbers"
                      :showDropdowns="showDropdowns"
                      :autoApply="autoApply"
                      v-model="dateRange"
                      @update="updateValues"
                      @toggle="checkOpen"
                      :dateFormat="dateFormat"
                    ></date-range-picker>-->
                    <date-picker
                      name="datetime"
                      id="datetime"
                      v-model="$v.typeform.datetime.$model"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                      type="datetime"
                      value-type="format"
                      range
                      :show-time-panel="showTimeRangePanel"
                      @close="handleRangeClose"
                      :class="{ 'is-invalid': $v.typeform.datetime.$error }"
                    >
                      <template v-slot:footer>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimeRangePanel"
                        >{{ showTimeRangePanel ? 'select date' : 'select time' }}</button>
                      </template>
                    </date-picker>
                    <div v-if="$v.typeform.datetime.$error" class="invalid-feedback">
                      <span
                        v-if="!$v.typeform.datetime.required"
                      >{{ trans.get('__JSON__.Please select date') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ trans.get('__JSON__.Promo Code Image') }}</label>
                    <dropzone-popup
                      v-model="typeform.image"
                      type="file"
                      id="coupon_image"
                      name="coupon_image"
                      acceptedFiles=".jpeg,.jpg,.png"
                      :editable-images="coupon_image"
                      :media-path="coupon_image_path"
                      :delete-path="coupon_image_delete_url"
                      @image-deleted="this.onPromoCodeImagedeleted"
                      :class="{ 'is-invalid': $v.typeform.image.$error }"
                    />
                    <div v-if="$v.typeform.image.$error" class="invalid-feedback cuisine-img">
                      <span
                        v-if="!$v.typeform.image.required"
                      >{{ trans.get('__JSON__.Please select coupon image') }}.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 vendor-status">
                  <b-form-group
                    id="input-group-1"
                    :label="trans.get('__JSON__.Status')"
                    label-for="status"
                  >
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        name="status"
                        v-model="typeform.status"
                        class="switch-on"
                        true-value="1"
                        false-value="0"
                      />
                      <div class="slider round">
                        <span class="on">{{ trans.get('__JSON__.Active') }}</span>
                        <span class="off">{{ trans.get('__JSON__.Inactive') }}</span>
                      </div>
                    </label>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <!-- Promo Code Detail Section End -->

          <!-- Discount Details Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Discount Details') }}</h4>
              <p class="card-title-desc"></p>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Coupon Valid For') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.coupon_type.$model"
                        id="coupon_type"
                        name="coupon_type"
                      >
                        <option value="-">{{ trans.get('__JSON__.Select Coupon Type') }}</option>
                        <option value="Delivery">{{ trans.get('__JSON__.Delivery') }}</option>
                        <option value="Pickup">{{ trans.get('__JSON__.Pickup') }}</option>
                        <option :value="null">{{ trans.get('__JSON__.Delivery') + ' + ' + trans.get('__JSON__.Pickup') }}</option>
                        <option value="ChowmanPay">{{ trans.get('__JSON__.Chowman Pay') }}</option>
                      </select>
                      <div
                        v-if="$v.typeform.coupon_type.$error"
                        class="invalid-feedback cuisine-img"
                      >
                        <span
                          v-if="!$v.typeform.coupon_type.required"
                        >{{ trans.get('__JSON__.Please select field', {name: trans.get('__JSON__.Coupon Valid For')}) }}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4 || typeform.is_flat_discount == 0">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Promo Code Redeem Count') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.per_user_usage"
                        v-model="typeform.per_user_usage"
                        id="per_user_usage"
                        name="per_user_usage"
                      >
                        <!-- <option value="">{{ trans.get('__JSON__.Select Redeem Count') }}</option> -->
                        <option v-bind:value="1">1</option>
                        <option v-bind:value="2">2</option>
                        <option v-bind:value="3">3</option>
                        <option v-bind:value="4">4</option>
                        <option v-bind:value="5">5</option>
                        <option v-bind:value="6">6</option>
                        <option v-bind:value="7">7</option>
                        <option v-bind:value="8">8</option>
                        <option v-bind:value="9">9</option>
                        <option v-bind:value="10">10</option>
                        <option value="Unlimited">Unlimited</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4 || typeform.is_flat_discount == 0">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Coupon Valid For First X Order') }}</label>
                      <select
                        class="custom-select"
                        v-bind:value="typeform.orders_valid_for_first"
                        v-model="typeform.orders_valid_for_first"
                        id="orders_valid_for_first"
                        name="orders_valid_for_first"
                      >
                        <option :value="-1">{{ trans.get('__JSON__.Any Orders') }}</option>
                        <option v-bind:value="1">1</option>
                        <option v-bind:value="2">2</option>
                        <option v-bind:value="3">3</option>
                        <option v-bind:value="4">4</option>
                        <option v-bind:value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Discount Type') }}</label>
                      <select
                        class="custom-select"
                        v-model="typeform.is_flat_discount"
                        @change="resetField"
                        id="discount_type"
                        name="discount_type"
                        disabled
                      >
                        <!-- <option value>{{ trans.get('__JSON__.Select Discount Type') }}</option> -->
                        <option :value="0">{{ trans.get('__JSON__.Percentage') }}</option>
                        <option :value="1">{{ trans.get('__JSON__.Flat') }}</option>
                        <option :value="2">{{ trans.get('__JSON__.Cashback') }}</option>
                        <option :value="3">{{ trans.get('__JSON__.Cashback With Percentage') }}</option>
                        <option :value="4">{{ trans.get('__JSON__.Cashback With Flat') }}</option>
                        <option :value="5">{{ trans.get('__JSON__.BOGO') }}</option>
                        <option :value="6">{{ trans.get('__JSON__.Flat Price') }}</option>
                        <option :value="7">{{ trans.get('__JSON__.Free Bie') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Cashback') }} (%)</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.cashback.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter Cashback in Percentage')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.cashback.$error }"
                      />
                      <div v-if="$v.typeform.cashback.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.cashback.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'cashback'})}}.</span>
                          <span
                            v-else-if="$v.typeform.cashback.floating"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'cashback'})}}.</span>
                          <span
                            v-else-if="!$v.typeform.cashback.numeric"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'cashback'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 6">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Flat Price') }}</label>
                      <input
                        id="flat_price"
                        name="flat_price"
                        v-model="$v.typeform.flat_price.$model"
                        type="number"
                        :placeholder="this.trans.get('__JSON__.Enter Flat Price')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.flat_price.$error }"
                      />
                      <div v-if="$v.typeform.flat_price.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.flat_price.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'flat_price'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 5">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Item Required') }}</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.bogo_item_required.$model"
                        type="number"
                        :placeholder="this.trans.get('__JSON__.Enter Bogo Item Required')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.bogo_item_required.$error }"
                      />
                      <div v-if="$v.typeform.bogo_item_required.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.bogo_item_required.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'bogo_item_required'})}}.</span>
                          <span
                            v-else-if="!$v.typeform.bogo_item_required.numeric"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'bogo_item_required'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 5">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Free Item') }}</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.bogo_get_item.$model"
                        type="number"
                        :placeholder="this.trans.get('__JSON__.Enter Bogo Item Get')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.bogo_get_item.$error }"
                      />
                      <div v-if="$v.typeform.bogo_get_item.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.bogo_get_item.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'bogo_get_item'})}}.</span>
                          <span
                            v-else-if="!$v.typeform.bogo_get_item.numeric"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'bogo_get_item'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 0 || typeform.is_flat_discount == 3">
                    <div class="form-group">
                      <label class="control-label required">{{ trans.get('__JSON__.Discount') }} (%)</label>
                      <input
                        id="cashback"
                        name="cashback"
                        v-model="$v.typeform.discount_percentage.$model"
                        type="text"
                        :placeholder="this.trans.get('__JSON__.Enter field',{name: 'Discount'})"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.discount_percentage.$error }"
                      />
                      <div v-if="$v.typeform.discount_percentage.$error" class="invalid-feedback">
                          <span
                            v-if="!$v.typeform.discount_percentage.required"
                          >{{ trans.get('__JSON__.Please enter field',{name: 'percentage'})}}.</span>
                          <span
                            v-else-if="$v.typeform.discount_percentage.floating"
                          >{{ trans.get('__JSON__.Please enter valid field', {name: 'percentage'})}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Flat Discount') }} (
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="flat_discount"
                        name="flat_discount"
                        v-model="$v.typeform.flat_discount.$model"
                        type="number"
                        :placeholder="this.trans.get('__JSON__.Enter field',{name: 'Flat Discount'})"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.flat_discount.$error }"
                      />
                      <div v-if="$v.typeform.flat_discount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.flat_discount.required"
                        >{{ trans.get('__JSON__.Please enter flat discount')}}.</span>
                        <span
                          v-if="!$v.typeform.flat_discount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount != 1 && this.typeform.is_flat_discount != 4 && typeform.is_flat_discount !=5 && typeform.is_flat_discount !=6 && typeform.is_flat_discount != 7">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Maximum Discount Amount') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="maximum_discount_amount"
                        name="maximum_discount_amount"
                        type="text"
                        v-model="$v.typeform.maximum_discount_amount.$model"
                        :placeholder="this.trans.get('__JSON__.Enter Maximum Discount Amount')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.maximum_discount_amount.$error }"
                      />
                       <div v-if="$v.typeform.maximum_discount_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.required"
                        >{{ trans.get('__JSON__.Please enter maximum discount')}}.</span>
                        <span
                          v-if="!$v.typeform.maximum_discount_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4">
                    <div class="form-group">
                      <label class="control-label required">
                        {{ trans.get('__JSON__.Maximum Cashback Amount') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="maximum_cashback_amount"
                        name="maximum_cashback_amount"
                        type="text"
                        v-model="$v.typeform.maximum_cashback_amount.$model"
                        :placeholder="this.trans.get('__JSON__.Enter Maximum Cashback Amount')"
                        class="form-control"
                         :class="{ 'is-invalid': $v.typeform.maximum_cashback_amount.$error }"
                      />
                       <div v-if="$v.typeform.maximum_cashback_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.maximum_cashback_amount.required"
                        >{{ trans.get('__JSON__.Please enter maximum cashback')}}.</span>
                        <span
                          v-if="!$v.typeform.maximum_cashback_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4 || typeform.is_flat_discount == 0 || typeform.is_flat_discount == 7">
                    <div class="form-group">
                      <label class="control-label">
                        {{ trans.get('__JSON__.Minimum Order Values') }}(
                        <span v-html="currency"></span>)
                      </label>
                      <input
                        id="minimum_order_amount"
                        name="minimum_order_amount"
                        v-model="$v.typeform.minimum_order_amount.$model"
                        type="text"
                        :placeholder="this.trans.get('Enter Minimum Order Amount')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.minimum_order_amount.$error }"
                      />
                      <div v-if="$v.typeform.minimum_order_amount.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.minimum_order_amount.required"
                        >{{ trans.get('__JSON__.Please enter minimum order amount')}}.</span>
                        <span
                          v-if="!$v.typeform.minimum_order_amount.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4 || typeform.is_flat_discount == 0">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Coupon Valid For First X User') }}</label>
                      <input
                        id="max_user_count"
                        name="max_user_count"
                        v-model="$v.typeform.max_user_count.$model"
                        type="text"
                        :placeholder="this.trans.get('Enter Max Count (FIrst X User)')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.max_user_count.$error }"
                      />
                       <div v-if="$v.typeform.max_user_count.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.max_user_count.required"
                        >{{ trans.get('__JSON__.Please enter max count number')}}.</span>
                        <span
                          v-if="!$v.typeform.max_user_count.floating"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4 || typeform.is_flat_discount == 0">
                    <div class="form-group">
                      <label
                        class="control-label required"
                      >{{ trans.get('__JSON__.Duration Type') }}</label>
                      <select
                        class="custom-select"
                        v-model="typeform.duration_type"
                        id="duration_type"
                        name="duration_type"
                        
                      >
                        <!-- <option value>{{ trans.get('__JSON__.Select Discount Type') }}</option> -->
                        <option value="">{{ trans.get('__JSON__.Select One') }}</option>
                        <option value="daily">{{ trans.get('__JSON__.Daily') }}</option>
                        <option value="weekly">{{ trans.get('__JSON__.Weekly') }}</option>
                        <option value="monthly">{{ trans.get('__JSON__.Monthly') }}</option>
                      </select>
                      <!-- <div v-if="$v.typeform.duration_type.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.duration_type.required"
                        >{{ trans.get('__JSON__.Please select duration type')}}.</span>
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Coupon valid duration') }}</label>
                      <div class="row pt-2" v-for="(item, index) in time_duration" :key="index">
                        <div class="col-sm-5">
                          <date-picker
                            v-model="item.start_time"
                            :time-picker-options="{
                              start: '00:00',
                              step: '00:01',
                              end: '23:59',
                            }"
                            format="hh:mm A"
                            type="time"
                            class="date-time"
                            placeholder="hh:mm A"
                            name="start_time[]"
                            value-type="format"
                            :disabled-time="function(date){return getDisabledStartTime(date, index)}"
                          ></date-picker>
                        </div>
                        <div class="col-sm-5">	
                          <date-picker
                            v-model="item.end_time"
                            :time-picker-options="{
                              start: '00:00',
                              step: '00:01',
                              end: '23:59',
                            }"
                            format="hh:mm A"
                            type="time"
                            class="date-time"
                            placeholder="hh:mm A"
                            name="end_time[]"
                            value-type="format"
                            :disabled-time="function(date){return getDisabledEndTime(date, index)}"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2">
                          <button class="btn btn-primary btn-sm btn-rounded" v-if="index == 0" @click.prevent="AddDuration"><i class="fas fa-plus"></i></button>
                          <button class="btn btn-danger btn-sm btn-rounded" v-if="index > 0" @click.prevent="removeCouponDuration(item.coupon_time_duration_id, index)"><i class="fas fa-minus"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4" v-if="typeform.is_flat_discount == 1 || typeform.is_flat_discount == 2 || typeform.is_flat_discount == 3 || typeform.is_flat_discount == 4 || typeform.is_flat_discount == 0">
                    <div class="form-group">
                      <label
                        class="control-label"
                      >{{ trans.get('__JSON__.Per hours limit for user') }}</label>
                      <input
                        id="per_hour_coupon_limit"
                        name="per_hour_coupon_limit"
                        v-model.number="$v.typeform.per_hour_coupon_limit.$model"
                        type="number"
                        :placeholder="this.trans.get('Per hours limit for user)')"
                        class="form-control"
                        :class="{ 'is-invalid': $v.typeform.per_hour_coupon_limit.$error }"
                      />
                       <div v-if="$v.typeform.per_hour_coupon_limit.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.per_hour_coupon_limit.numeric"
                        >{{ trans.get('__JSON__.This value should be digits')}}.</span>
                      </div>
                    </div>
                  </div>

                  <!-- Temororily disable -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Coupon for Customer Category') }}</label>
                      <div class="position-relative">
                          <multiselect
                            v-model="typeform.customer_categories"
                            id="category_name"
                            label="category_name"
                            track-by="category_name"
                            :placeholder="trans.trans('__JSON__.Select Customer Category')"
                            open-direction="bottom"
                            :options="customer_category_options"
                            :multiple="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="5"
                            :limit="100"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.category_name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="customer_category_options.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>
                            <template slot="noResult">
                              <span                              
                              >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                            </template>
                          </multiselect>
                        </div>
                    </div>
                  </div>

                  <div class="col-md-4 mt-2">
                    <div class="form-group">
                      <label>{{ trans.get('__JSON__.Show in Customer App') }}?</label>
                      <div class>
                        <div class="swtich-data">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              class="switch-on"
                              true-value="1"
                              false-value="0"
                              v-model="typeform.is_show_customer_app"
                            />
                            <div class="slider round">
                              <!--ADDED HTML -->
                              <span class="on">{{ trans.get('__JSON__.On') }}</span>
                              <span class="off">{{ trans.get('__JSON__.Off') }}</span>
                              <!--END-->
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Discount Details Section End -->

          <!-- Vendors Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.trans('__JSON__.Vendors',{ vendor: $auth.setting.restaurant_name }) }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-on:click="Onclick()"
                          v-model="typeform.get_restaurant"
                          class="custom-control-input"
                          id="all_rest"
                          true-value="1"
                          false-value="0"
                        />
                        <label
                          class="custom-control-label"
                          for="all_rest"
                        >{{ trans.trans('__JSON__.Promo code valid for all Vendors',{ vendor: $auth.setting.restaurant_name }) }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <p>{{ trans.get('__JSON__.OR') }}</p>
                    </div>
                    <div class="col-sm-4 offset-sm-2">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <multiselect
                            v-model="$v.typeform.coupon_restaurants.$model"
                            id="ajax"
                            v-bind:disabled="typeform.get_restaurant == 1"
                            label="name"
                            track-by="name"
                            :placeholder="trans.trans('__JSON__.Vendor Search',{ vendor: $auth.setting.restaurant_name })"
                            open-direction="bottom"
                            :options="resturant"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="5"
                            :limit="100"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFind"
                            :class="{ 'is-invalid': $v.typeform.coupon_restaurants.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="coupon_restaurants.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>
                            <template slot="noResult">
                              <span                              
                              >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                            </template>
                            <template slot="noOptions">
                              <span                              
                              >{{ trans.get('__JSON__.Search...') }}.</span>
                            </template>
                          </multiselect>
                          <div
                            v-if=" $v.typeform.coupon_restaurants.$error"
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.typeform.coupon_restaurants.required"
                            >{{ trans.get('__JSON__.Vendor Select Error') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Vendors Section End -->

          <!-- Customer Section Start -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Customers') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-on:click="customerOnclick()"
                          v-model="typeform.get_customer"
                          class="custom-control-input"
                          id="all_cust"
                          true-value="1"
                          false-value="0"
                        />
                        <label
                          class="custom-control-label"
                          for="all_cust"
                        >{{ trans.get('__JSON__.Promo code valid for all Customers') }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <p>{{ trans.get('__JSON__.OR') }}</p>
                    </div>
                    <div class="col-sm-4 offset-sm-2">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <multiselect
                            v-model="$v.typeform.coupon_customer.$model"
                            id="ajax"
                            label="user_name"
                            v-bind:disabled="typeform.get_customer == 1"
                            track-by="user_name"
                            :placeholder="trans.get('__JSON__.Customer Search')"
                            open-direction="bottom"
                            :options="customer"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading1"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="5"
                            :limit="100"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFindCustomer"
                            :class="{ 'is-invalid': $v.typeform.coupon_customer.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.user_name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="coupon_customer.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div>
                            </template>

                            <template slot="noOptions">
                              <span                              
                              >{{ trans.get('__JSON__.Search...') }}.</span>
                            </template>
                            <template slot="noResult">
                              <span
                              >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                            </template>
                          </multiselect>
                          <div v-if=" $v.typeform.coupon_customer.$error" class="invalid-feedback">
                            <span
                              v-if="!$v.typeform.coupon_customer.required"
                            >{{ trans.get('__JSON__.Please choose at least one customer') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Customer Section End -->

          <!-- Customer Section Start -->
          <div class="card" v-if="(this.typeform.is_flat_discount == 5 || this.typeform.is_flat_discount == 6 )">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Catelog') }}</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-sm-2 ml-4 pt-3">
                  <div class="form-group">

                    <input
                      id="search_items"
                      name="search_items"
                      v-model="search_items"
                      type="text"
                      @input="searchItem"
                      :placeholder="trans.trans('__JSON__.Search Item')"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <LightViewTree
      ref="tree1"
      showCheckbox
      :treeData="treeData"
      @on-drop="dropNode"
      @on-checked-item="checkedItem"
      @on-selected-change="clickNode"
      @on-checked-change="clickCheckbox"
    />
                </div>
              </div>
            </div>
          </div>
          <!-- Customer Section End -->

          <div class="card" v-if="this.typeform.is_flat_discount == 7">
            <div class="card-body">
              <h4 class="card-title">Items</h4>
              <p class="card-title-desc"></p>
              <div class="row">
                <div class="col-md-12">
                  <div class="vendor-main">
                    <div class="col-sm-4">
                      <div class="search-box mr-2 d-inline-block">
                        <div class="position-relative">
                          <multiselect
                            v-model="$v.typeform.free_bie_items.$model"
                            id="ajax"
                            label="name"
                            track-by="name"
                            :placeholder="'Search Item'"
                            open-direction="bottom"
                            :options="search_items_array"
                            :multiple="false"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="5"
                            :limit="100"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFindItems"
                            @select="onItemSelected"
                            :class="{ 'is-invalid': $v.typeform.free_bie_items.$error }"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                              </span>
                            </template>
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="free_bie_items.length"
                                @mousedown.prevent.stop="clearAllSelectedItems(props.search)"
                              ></div>
                            </template>
                            <template slot="noResult">
                              <span                              
                              >{{ trans.get('__JSON__.Oops! No elements found. Consider changing the search query') }}.</span>
                            </template>
                            <template slot="noOptions">
                              <span                              
                              >{{ trans.get('__JSON__.Search...') }}.</span>
                            </template>
                          </multiselect>
                          <div
                            v-if=" $v.typeform.free_bie_items.$error"
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.typeform.free_bie_items.required"
                            >{{ trans.get('__JSON__.Vendor Select Error') }}.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- location Section Start -->
          <div class="card" v-if="country_count >1">
            <div class="card-body">
              <h4 class="card-title">{{ trans.get('__JSON__.Select Location') }}</h4>
              <p class="card-title-desc"></p>
              <div class="col-md-12">
                <div class="row">
                  <div v-if="country_count >1" class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.Country') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.country.$model"
                        @change="getVendorState"
                        id="country"
                        name="country"
                        :class="{ 'is-invalid': $v.typeform.country.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select Country') }}</option>
                        <option
                          v-for="con in countries"
                          :value="con.id"
                          :key="con.id"
                        >{{con.country_name}}</option>
                      </select>
                      <div v-if="$v.typeform.country.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.country.required"
                        >{{ trans.get('__JSON__.Please select country') }}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="country_count >=1 && state_count > 1" class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.State') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.state.$model"
                        @change="getVendorCity"
                        id="state"
                        name="state"
                        :class="{ 'is-invalid': $v.typeform.state.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select State') }}</option>
                        <option
                          v-for="sat in states"
                          :value="sat.id"
                          :key="sat.id"
                        >{{sat.state_name}}</option>
                      </select>
                      <div v-if="$v.typeform.state.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.state.required"
                        >{{ trans.get('__JSON__.Please select state')}}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="state_count >= 1 && city_count > 1" class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">{{ trans.get('__JSON__.City') }}</label>
                      <select
                        class="custom-select"
                        v-model="$v.typeform.city.$model"
                        id="city"
                        name="city"
                        :class="{ 'is-invalid': $v.typeform.city.$error }"
                      >
                        <option value selected>{{ trans.get('__JSON__.Select City') }}</option>
                        <option
                          v-for="cit in cities"
                          :value="cit.cityid"
                          :key="cit.cityid"
                        >{{cit.cityname}}</option>
                      </select>
                      <div v-if="$v.typeform.city.$error" class="invalid-feedback">
                        <span
                          v-if="!$v.typeform.city.required"
                        >{{ trans.get('__JSON__.Please select city') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
              <div class="row">
                <div class="col-sm-6">
                  <button
                    class="btn btn-primary mr-1 mt-3"
                  >{{ trans.get('__JSON__.Update Promo Code') }}</button>
                  <b-button @click="$router.back()" type="button" variant="secondary" class="mt-3">{{ trans.get('__JSON__.Cancel') }}</b-button>
                </div>
              </div>
          <!-- location Section End -->
        </form>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
</style>